import { Stack, Typography } from "@mui/material";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useDispatch, useSelector } from "react-redux";
import * as analyticsStore from "store/analytics";
import * as programPricingStore from "store/programPricing";

const formatNumber = (value) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const ProgramTiv = () => {
  const dispatch = useDispatch();
  const calculatedValues = useSelector(analyticsStore.select)?.programPricing
    ?.values;
  const resolvedProgramPricing = useSelector(programPricingStore.select) || {};

  const updateProgramPricing = (values) => {
    dispatch(programPricingStore.update(values));
  };

  const autoValueTIV = calculatedValues?.meta?.sum_tiv || 0;
  const autoValueBI = calculatedValues?.meta?.sum_bi || 0;
  const autoTIV = formatNumber(autoValueTIV);
  const autoBI = formatNumber(autoValueBI);

  const handleChange = (key, value) => {
    updateProgramPricing({ [key]: value });
  };

  const onChangeNumber = (key) => (value) => {
    handleChange(key, value);
  };

  return (
    <Component title={"TIV"} lg_width={6}>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <PrettyNumberTextField
          InputLabelProps={{ shrink: true }}
          label={"PD"}
          value={resolvedProgramPricing.PD || ""}
          onChangeNumber={onChangeNumber("PD")}
          variant={"filled"}
          helperText={"Actual: "}
          disabled={resolvedProgramPricing.calculated === "PD"}
        />
        <Typography variant={"strong"}>{"+"}</Typography>
        <PrettyNumberTextField
          InputLabelProps={{ shrink: true }}
          label={"BI"}
          value={resolvedProgramPricing.BI || ""}
          onChangeNumber={onChangeNumber("BI")}
          variant={"filled"}
          helperText={autoBI || ""}
          disabled={resolvedProgramPricing.calculated === "BI"}
        />
        <Typography variant={"strong"}>{"="}</Typography>
        <PrettyNumberTextField
          InputLabelProps={{ shrink: true }}
          label={"TIV"}
          value={resolvedProgramPricing.TIV || ""}
          onChangeNumber={onChangeNumber("TIV")}
          variant={"filled"}
          helperText={autoTIV || ""}
          disabled={resolvedProgramPricing.calculated === "TIV"}
        />
      </Stack>
    </Component>
  );
};

export default ProgramTiv;
