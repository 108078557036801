import * as adminActions from "../../store/actions/temp/admin/adminActions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Grid, Typography, Checkbox, Stack, Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import Component from "components/Component";
import AdminDashboard from "components/admin/AdminDashboard";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import TextField from "components/common/TextField";
import DashboardContent from "components/layout/DashboardContent";
import * as config from "config";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useCreateUserMutation,
  useConfigureUserMutation,
  useLazyListUsersQuery,
  useResetUserPasswordMutation,
} from "services/adminService";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";
import * as yup from "yup";

const userSort = (a, b) => {
  if (a.enabled && !b.enabled) {
    return -1;
  }
  if (!a.enabled && b.enabled) {
    return 1;
  }
  return a.email.localeCompare(b.email);
};

const EDITABLE_PERMISSIONS = ["web:read", "web:write"];

const User = ({ user, onChange, disabled, resettingPassword, onReset }) => {
  const perms = user.perms ?? [];
  return (
    <Card sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Grid container alignItems={"center"} spacing={0}>
        <Grid item xs={9}>
          <Typography>{user.email}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Switch
              checked={user.enabled}
              onChange={(e) => {
                onChange({
                  user,
                  enabled: e.target.checked,
                });
              }}
              disabled={disabled}
            />
            {resettingPassword === user.email ? (
              <CircularProgress size={24} style={{ margin: 8 }} />
            ) : (
              <IconButton
                icon={
                  user?.hasPasswordReset === "failure"
                    ? () => (
                        <Tooltip
                          title={
                            "An error has occured when resetting the password, please contact support at: support@cactus.bm"
                          }
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      )
                    : user?.hasPasswordReset === "success"
                    ? CheckCircleOutlineIcon
                    : LockResetIcon
                }
                onClick={onReset}
                isDisabled={
                  disabled ||
                  user?.hasPasswordReset === "success" ||
                  resettingPassword != null
                }
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"}>
            {EDITABLE_PERMISSIONS.map((perm, index) => (
              <React.Fragment key={`edit_perm_${index}`}>
                <div>{`${perm} `}</div>
                <Checkbox
                  color={"primary"}
                  checked={perms.includes(perm)}
                  onClick={(e) => {
                    onChange({
                      user,
                      perms: {
                        [perm]: e.target.checked,
                      },
                    });
                  }}
                  disabled={disabled}
                />
              </React.Fragment>
            ))}
            {perms
              .filter((perm) => !EDITABLE_PERMISSIONS.includes(perm))
              .map((perm, index) => (
                <React.Fragment key={`no_edit_perm_${index}`}>
                  <div key={`permName_${index}`}>{`${perm} `}</div>
                  <Checkbox
                    color={"primary"}
                    checked={perms.includes(perm)}
                    disabled={true}
                  />
                </React.Fragment>
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

const prettyDate = (timestamp) => {
  if (timestamp == null) {
    return timestamp;
  }
  const date = new Date(timestamp);
  return (
    <Typography>{`updated on ${date.toLocaleString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} at ${date.toLocaleString("en-us", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })}`}</Typography>
  );
};

const NewUser = ({ saveUserLogMessage, userLogMessage, hasUserLogMessage }) => {
  const { companyId } = useParams();

  const { enqueueSnackbar: queueMessage } = useSnackbar();
  const addMessage = (variant, message) => {
    queueMessage(message, {
      variant,
      autoHideDuration: 5_000,
      preventDuplicate: true,
    });
  };
  const [resettingPassword, setResettingPassword] = useState(null);
  const [resetPasswords, setResetPasswords] = useState({});
  const [resetUserPassword] = useResetUserPasswordMutation();
  const [newUser, setNewUser] = useState(null);
  const [usersAdded, setUsersAdded] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [createUser, { isLoading, isError }] = useCreateUserMutation();
  const [
    configureUser,
    { isLoading: configureLoading },
  ] = useConfigureUserMutation();
  const [
    triggerListUsers,
    { data: users, isFetching: usersLoading, startedTimeStamp: usersTimestamp },
  ] = useLazyListUsersQuery();
  useEffect(() => {
    if (companyId) {
      triggerListUsers({ companyId });
    }
  }, [companyId, triggerListUsers]);
  const onChange = ({ user, enabled, perms }) => {
    configureUser({
      username: user.username,
      enabled,
      perms,
      userLogMessage,
    }).then((response) => {
      if (!response.data) {
        addMessage("error", `Failed to update ${user.email}`);
      } else if (response.data.changes) {
        addMessage("success", `${user.email} updated successfully`);
      } else {
        addMessage("warning", `No changes made to ${user.email}`);
      }
      triggerListUsers({ companyId });
    });
  };
  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={false}>
        <Grid item xs={12}>
          <Component
            title={"Users"}
            loading={!users && usersLoading}
            options={prettyDate(usersTimestamp)}
          >
            <Grid container spacing={2}>
              {[...(users || [])].sort(userSort).map((user) => (
                <Grid key={user.username} item xs={12}>
                  <User
                    user={{
                      ...user,
                      hasPasswordReset: resetPasswords[user.email],
                    }}
                    resettingPassword={resettingPassword}
                    disabled={
                      configureLoading || usersLoading || !hasUserLogMessage
                    }
                    onChange={onChange}
                    onReset={() => {
                      setResettingPassword(user.email);
                      resetUserPassword({
                        username: user.email,
                        userLogMessage,
                        companyId,
                      })
                        .then((response) => {
                          if (response?.data) {
                            setResetPasswords({
                              ...resetPasswords,
                              [user.email]: "success",
                            });
                          } else {
                            setResetPasswords({
                              ...resetPasswords,
                              [user.email]: "failure",
                            });
                          }
                          setResettingPassword(null);
                        })
                        .catch(() => {
                          setResetPasswords({
                            ...resetPasswords,
                            [user.email]: "failure",
                          });
                          setResettingPassword(null);
                        });
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                {users && (
                  <TextField
                    variant={"filled"}
                    label={"Reason for updating users"}
                    fullWidth
                    onChange={(e) => saveUserLogMessage(e.target.value)}
                    value={userLogMessage}
                  />
                )}
              </Grid>
            </Grid>
          </Component>
        </Grid>
        {companyId && (
          <Grid item xs={12}>
            <Formik
              initialValues={{
                email: "",
                enabled: true,
                read: true,
                write: true,
                sendEmail: true,
                setPassword: false,
                password: "MarmaldeRocks123!",
              }}
              validationSchema={yup.object({
                email: yup
                  .string("The email address of the new user.")
                  .email(({ value }) => `${value} is not a valid email`)
                  .required("Email is required"),
                setPassword: yup.boolean(),
                password: yup.string().when("setPassword", {
                  is: true,
                  then: yup
                    .string()
                    .min(16, "Password must be at least 16 characters long")
                    .matches(
                      /[a-z]/,
                      "Password must have at least one lowercase letter"
                    )
                    .matches(
                      /[A-Z]/,
                      "Password must have at least one uppercase letter"
                    )
                    .matches(/[0-9]/, "Password must contain a number")
                    .required("Password is required"),
                  otherwise: yup.string().notRequired(),
                }),
              })}
              onSubmit={(values) => {
                createUser({
                  ...values,
                  companyId,
                  userLogMessage,
                }).then((response) => {
                  setResponseData(
                    `\`\`\`\n${JSON.stringify(
                      response?.data ?? response,
                      null,
                      2
                    )}\n\`\`\``
                  );
                  setNewUser(response?.data);
                  setUsersAdded(usersAdded + 1);
                  triggerListUsers({ companyId });
                  if (isError)
                    addMessage("error", `User ${values.email} creation failed`);
                  else
                    addMessage(
                      "success",
                      `User ${values.email} created successfully`
                    );
                });
              }}
            >
              {(formik) => (
                <Component title={"Create a New User"}>
                  <Grid container spacing={config.GRID_SPACING}>
                    <Grid item xs={12}>
                      <Typography>{companyId}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"email"}
                        label={"Email Address"}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`Set Password `}</div>
                        <Checkbox
                          name={"setPassword"}
                          color={"primary"}
                          checked={formik.values.setPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <TextField
                          name={"password"}
                          label={"Password"}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={
                            formik.values.setPassword
                              ? formik.values.password
                              : ""
                          }
                          disabled={!formik.values.setPassword}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`web:read `}</div>
                        <Checkbox
                          name={"read"}
                          color={"primary"}
                          checked={formik.values.read}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`web:write `}</div>
                        <Checkbox
                          name={"write"}
                          color={"primary"}
                          checked={formik.values.write}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`Enabled `}</div>
                        <Checkbox
                          name={"enabled"}
                          color={"primary"}
                          checked={formik.values.enabled}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`Send Email `}</div>
                        <Checkbox
                          name={"sendEmail"}
                          color={"primary"}
                          checked={formik.values.sendEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item xs={10}>
                        <TextField
                          variant={"filled"}
                          label={"Reason to create a user"}
                          fullWidth
                          onChange={(e) => saveUserLogMessage(e.target.value)}
                          value={userLogMessage}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={formik.handleSubmit}
                          isLoading={isLoading}
                          isDisabled={isLoading || !hasUserLogMessage}
                          hasError={isError}
                          errorTooltip={"Failed to create user."}
                        >
                          {"Create User"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Component>
              )}
            </Formik>
          </Grid>
        )}
        {responseData && (
          <Component title={"Server Response"}>
            <Grid item xs={12}>
              <Typography>{"Response:"}</Typography>
            </Grid>
            {newUser && (
              <Grid item xs={12}>
                <User user={newUser} disabled={true} />
              </Grid>
            )}
            <Grid item xs={12}>
              <MDEditor.Markdown source={responseData} />
            </Grid>
            <Grid item xs={12}></Grid>
          </Component>
        )}
      </DashboardContent>
    </AdminDashboard>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
