import SurplusBrokerCreation from "./SurplusBrokerCreation";
import SurplusPersonSelect from "./SurplusPersonSelect";
import Disabled from "components/common/Disabled";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as peopleActions from "store/actions/people/peopleActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";

const SurplusBroker = () => {
  const {
    data: brokers,
    refetch: refreshBrokers,
  } = staticDataService.useConfigQuery("surplus_brokers");

  const dispatch = useDispatch();

  const broker = useSelector(peopleSelectors.selectSurplusBroker);

  const updateBroker = (value) => {
    dispatch(peopleActions.updateSurplusBroker(value));
  };

  const sortedBrokers = [...(brokers?.all ?? [])].sort(
    (a, b) =>
      (a.company || "z").localeCompare(b.company || "z") ||
      (a.name || "z").localeCompare(b.name || "z")
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <Disabled ifReadOnly>
      <SurplusPersonSelect
        person={broker}
        personList={sortedBrokers}
        onUpdatePerson={updateBroker}
        onUpdatePersonList={refreshBrokers}
        PersonCreationComponent={SurplusBrokerCreation}
        label={translateLabel("SURPLUS_LINES_BROKER", {
          default: "Surplus Lines Broker",
        })}
        showLabelOnCard
      />
    </Disabled>
  );
};

export default SurplusBroker;
