import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import FlagSelector from "components/common/FlagSelector";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import { connect } from "react-redux";
import * as flagActions from "store/actions/input/flags/flagsActions";
import * as flagSelectors from "store/selectors/input/flags/flagsSelectors";

const ExposureFlag = ({
  flag,
  deleteExposure,
  updateType,
  updateCategory,
  updateComment,
}) => {
  return (
    <TableRow>
      <TableCell>
        {!flag?.isDefault && flag?.canDelete ? (
          <IconButton icon={DeleteIcon} onClick={deleteExposure} />
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell
        style={{ verticalAlign: "top" }}
        className={"cell-condensed-padding-md"}
        colSpan={1 + (flag?.categoryType === "none" ? 1 : 0)}
      >
        <Tooltip title={flag?.type}>
          {flag?.isDefault ? (
            <Typography>{flag?.displayName ?? flag?.type}</Typography>
          ) : (
            <TextField
              value={flag?.type}
              onChange={(e) => updateType(e.target.value)}
              size={"small"}
              variant={"filled"}
              autoComplete={"off"}
              hiddenLabel
              fullWidth
            />
          )}
        </Tooltip>
      </TableCell>
      {flag?.categoryType !== "none" && (
        <TableCell
          align={
            flag?.categoryType === "boolean" || flag?.isBooleanFlag
              ? "center"
              : "left"
          }
          style={{ verticalAlign: "top" }}
          className={"cell-condensed-padding-md"}
        >
          <FlagSelector flag={flag} updateCategory={updateCategory} />
        </TableCell>
      )}
      <TableCell
        style={{ verticalAlign: "top" }}
        className={"cell-condensed-padding-md"}
      >
        {!flag?.noComments && (
          <Notes
            key={`ExposureFlagComments-${flag?.type}`}
            title={null}
            hasEditButton={false}
            update={updateComment}
            value={flag?.comments}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export const ExposureFlagsComponent = ({
  flags,
  deleteExposureFlag,
  updateExposureFlag,
}) => {
  return (
    <Component
      title={"Exposure Flags"}
      subtitle={
        "Important exposure details that factors into risk assessment. These inputs do not factor into pricing calculations."
      }
    >
      <TableContainer>
        <Table size={"small"} className={"flags"}>
          <TableHead>
            <TableRow>
              <TableCell width={"5%"} sx={{ minWidth: "30px" }} />
              <TableCell width={"15%"}>{"Type"}</TableCell>
              <TableCell width={"15%"} align={"center"}>
                {"Category"}
              </TableCell>
              <TableCell width={"65%"}>{"Comments"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flags?.map((flag, i) => (
              <ExposureFlag
                key={i}
                flag={flag}
                deleteExposure={() => deleteExposureFlag(i)}
                updateType={(type) => updateExposureFlag("type", i, type)}
                updateCategory={(category) =>
                  updateExposureFlag("category", i, category)
                }
                updateComment={(comment) =>
                  updateExposureFlag("comments", i, comment)
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapDispatchToProps = {
  updateExposureFlag: flagActions.updateExposureFlag,
  deleteExposureFlag: flagActions.deleteExposure,
};

const mapStateToProps = (state) => {
  return {
    flags: flagSelectors.getExposuresForDisplay(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExposureFlagsComponent);
