import Box from "@mui/material/Box";
import * as chartUtils from "chartUtils";
import * as charts from "common/charts";
import { minWidthBarFactory, getDateTicks } from "common/charts";
import * as dates from "common/dates";
import DefaultLoader from "components/common/DefaultLoader";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceArea,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import { updateChartZoom } from "store/actions/input/claims/claimsActions";

const CustomTooltipContent = (props) => {
  if (props.payload?.[0]?.payload?.ultimate != null) {
    return (
      <DefaultTooltipContent
        {...props}
        payload={[
          ...props.payload,
          {
            name: "Total",
            value: props.payload[0].payload.ultimate,
          },
        ]}
      />
    );
  }
  return <DefaultTooltipContent {...props} />;
};

const FilteredClaimsChart = (props) => {
  const [zoom, setZoom] = useState();
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();
  const cartesianGridId = useRef("cartesian-grid");
  const tooltipLabelFormatter = (_, props) => {
    return dates.formatDate(props?.[0]?.payload?.date);
  };

  const tooltipFormatter = (value) => {
    return `$${value.toLocaleString("en-US", {
      maximumFractionDigits: 0,
    })}`;
  };

  const highlightFilter = () => {
    const claim_domain_max = 1e10;
    const claim_domain_min = -1e10;
    const left = props.data.domain[0];
    const right = props.data.domain[1];

    const x1 = dates.strDateToMonthsFloat(props.filter?.from) ?? left;
    const x2 = dates.strDateToMonthsFloat(props.filter?.to) ?? right;
    const attachment = props.filter.attachment;
    const exhaust = props.filter.attachment + props.filter.limit;

    return (
      <>
        <ReferenceArea
          x1={left}
          x2={right}
          y1={claim_domain_min}
          y2={attachment}
          ifOverflow={"hidden"}
          fillOpacity={0.25}
        />
        {!props.disabled && (
          <>
            <ReferenceArea
              x1={left}
              x2={right}
              y1={exhaust}
              y2={claim_domain_max}
              ifOverflow={"hidden"}
              fillOpacity={0.25}
            />
            <ReferenceArea
              x1={left}
              x2={x1}
              y1={attachment}
              y2={exhaust}
              ifOverflow={"hidden"}
              fillOpacity={0.25}
            />
            <ReferenceArea
              x1={right}
              x2={x2}
              y1={attachment}
              y2={exhaust}
              ifOverflow={"hidden"}
              fillOpacity={0.25}
            />
          </>
        )}
      </>
    );
  };

  const getChartZoomDomain = (zoomIdentifier, chartZoom, domain) => {
    const chart = chartZoom[zoomIdentifier];
    if (!chart) {
      return domain;
    }
    return [chart.from, chart.to];
  };

  const zoomedDomain = getChartZoomDomain(
    props.zoomIdentifier,
    props.chartZoom,
    props.dateDomain
  );

  const getMonthOfEvent = (e) => {
    if (e?.chartX == null) {
      return undefined;
    }
    const gridEl = document.getElementById(cartesianGridId);
    if (gridEl == null) {
      return undefined;
    }
    const gridX = e.chartX - gridEl.getAttribute("x");
    const gridWidth = gridEl.getAttribute("width");
    const along = Math.min(Math.max(0, gridX / gridWidth), 1);
    const month = zoomedDomain[0] + along * (zoomedDomain[1] - zoomedDomain[0]);
    return month;
  };

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      {props.data == null || props.data.more ? (
        <Box>
          <DefaultLoader
            color={"#dc7f4c"}
            size={60}
            style={{
              display: "block",
              textAlign: "center",
            }}
          />
        </Box>
      ) : (
        <BarChart
          ref={chartRef}
          data={props.data.values.filter((value) => {
            return (
              value.months > zoomedDomain[0] && value.months < zoomedDomain[1]
            );
          })}
          onMouseDown={(e, baseEvent) => {
            const monthOfEvent = getMonthOfEvent(e);
            if (monthOfEvent != null) {
              setZoom({
                from: monthOfEvent,
              });
            }
          }}
          onMouseMove={(e, baseEvent) => {
            const monthOfEvent = getMonthOfEvent(e);
            if (monthOfEvent != null && zoom?.from != null) {
              setZoom({
                from: zoom.from,
                to: monthOfEvent,
                selecting: true,
              });
            }
          }}
          onMouseUp={(e, baseEvent) => {
            if (zoom?.from != null && zoom?.to != null) {
              const [from, to] = [
                Math.min(zoom.from, zoom.to),
                Math.max(zoom.from, zoom.to),
              ];
              props.onZoom
                ? props.onZoom(from, to)
                : props.updateChartZoom(props.zoomIdentifier, { from, to });
            }
            setZoom({});
          }}
          isAnimationActive={props.isAnimationActive ?? false}
        >
          <CartesianGrid strokeDasharray={"3 3"} id={cartesianGridId} />
          <XAxis
            dataKey={"months"}
            type={"number"}
            tickFormatter={charts.dateTickFormatter}
            ticks={getDateTicks(zoomedDomain)}
            domain={zoomedDomain}
            style={zoom?.selecting ? { userSelect: "none" } : {}}
          />
          <YAxis
            tickFormatter={chartUtils.tickFormatter}
            ticks={props.numberTicks}
            domain={props.numberDomain}
            style={zoom?.selecting ? { userSelect: "none" } : {}}
          />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            content={<CustomTooltipContent />}
            position={tooltipPosition}
            isAnimationActive={false}
          />
          <Bar
            dataKey={"value"}
            name={"Claim Reported"}
            stackId={"a"}
            fill={"#FFAB53"}
            barSize={5}
            shape={minWidthBarFactory(5)}
          />
          <Bar
            dataKey={"development"}
            name={"Development & Inflation"}
            stackId={"a"}
            fill={"#FF634E"}
            barSize={5}
            shape={minWidthBarFactory(5)}
          />
          {highlightFilter()}
          {zoom?.from != null && zoom?.to != null && (
            <ReferenceArea
              x1={zoom.from}
              x2={zoom.to}
              y1={props.numberDomain[0]}
              y2={props.numberDomain[1]}
              ifOverflow={"hidden"}
              fillOpacity={0.25}
            />
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

const mapDispatchToProps = {
  updateChartZoom,
};

const mapStateToProps = (state) => ({
  chartZoom: state.temp?.claims?.chartZoom,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteredClaimsChart);
