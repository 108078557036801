import * as ids from "common/ids";
import * as submissions from "domain/submissions";
import * as flagsSelectors from "store/selectors/input/flags/flagsSelectors";
import * as uuid from "uuid";

export const toSchemaVersion3 = (data) => {
  const mapPricing = (pricing) => {
    if (pricing == null) {
      return pricing;
    }
    const default_tower = {
      selectedLayer: 0,
      layers: [{}],
      updatingTowerPricing: false,
      hasError: false,
    };
    const { tower, ...restOfPricing } = pricing;
    return {
      tower: {
        ...default_tower,
        ...tower,
        layers: (tower?.layers || [{}]).map((layer) => {
          const {
            carrier,
            limit,
            attachment,
            marketGrossWrittenPremium,
            marketRatePerMillion,
            ...towerPricing
          } = layer;
          return {
            carrier,
            limit,
            attachment,
            grossPremium: marketGrossWrittenPremium,
            towerPricing,
          };
        }),
      },
      ...restOfPricing,
    };
  };
  const mapProgram = (program) => {
    if (program == null) {
      return program;
    }
    return {
      ...program,
      inUS: !!program.inUS,
    };
  };
  const mapInput = (input) => {
    if (input == null) {
      return input;
    }
    return {
      ...input,
      program: mapProgram(input.program),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: mapInput(data.state.input),
      pricing: mapPricing(data.state.pricing),
      schemaVersion: 3,
    },
  };
};

export const toSchemaVersion4 = (data) => {
  const removePolicyForms = (program) => {
    if (program == null) {
      return program;
    }
    const { policyForms, ...residue } = program;
    return residue;
  };

  const removeRows = (exposure) => {
    if (exposure == null) {
      return exposure;
    }
    const { rows, ...residue } = exposure;
    return residue;
  };

  const updateInput = (input) => {
    if (input == null) {
      return input;
    }
    return {
      ...input,
      exposure: removeRows(input.exposure),
      program: removePolicyForms(input.program),
    };
  };

  const updatePricing = (pricing, policyForms) => {
    if (pricing == null || policyForms == null) {
      return pricing;
    }
    let policyForm = policyForms.find((x) => x !== null);
    if (policyForm == null) {
      return pricing;
    }
    if (policyForm === "BSF" || policyForm === "MXS") {
      policyForm += "_OCC";
    }
    return {
      ...pricing,
      layers: pricing.layers.map((layer) => ({ ...layer, policyForm })),
    };
  };

  return {
    ...data,
    state: {
      ...data.state,
      input: updateInput(data.state?.input),
      pricing: updatePricing(
        data.state?.pricing,
        data.state?.input.program.policyForms
      ),
      schemaVersion: 4,
    },
  };
};
export const toSchemaVersion5 = (data) => {
  const upgradeChart = (chart, key) => {
    if (chart == null) {
      return chart;
    }
    return chart.map(({ name, ...row }) => ({
      name,
      value: row[key],
    }));
  };
  const upgradeClaims = (claims) => {
    if (claims == null) {
      return claims;
    }
    const {
      frequencySummary,
      aggregateSummary,
      severitySummary,
      ...residue
    } = claims;
    return {
      ...residue,
      frequencySummary: upgradeChart(frequencySummary, "Frequency"),
      aggregateSummary: upgradeChart(aggregateSummary, "Aggregate"),
      severitySummary: upgradeChart(severitySummary, "Severity"),
    };
  };
  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    const { claims, ...residue } = input;
    return {
      ...residue,
      claims: upgradeClaims(claims),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: upgradeInput(data.state?.input),
      schemaVersion: 5,
    },
  };
};

export const toSchemaVersion6 = (data) => {
  const upgradeClaims = (claims) => {
    if (claims == null) {
      return claims;
    }
    const {
      file,
      uploadedFilename,
      fileIsUploading,
      fileIsProcessing,
      errorMessage,
      sheets,
      activeSheet,
      columnMapping,
      columns,
      lossTypes,
      lossTypeMapping,
      claimsKey,
      lastMappedLossDate,
      lastLossDate,
      firstMappedLossDate,
      firstLossDate,
      transformedDownloadHasError,
      originalDownloadHasError,
      transformedDownloading,
      originalDownloading,
      sheetsDialogIsOpen,
      activeStep,
      newFileProcessed,
      ...residue
    } = claims;
    const activeFile = {
      file,
      uploadedFilename,
      fileIsUploading,
      fileIsProcessing,
      errorMessage,
      sheets,
      activeSheet,
      columnMapping,
      columns,
      lossTypes,
      lossTypeMapping,
      claimsKey,
      lastMappedLossDate,
      lastLossDate,
      firstMappedLossDate,
      firstLossDate,
      transformedDownloadHasError,
      originalDownloadHasError,
      transformedDownloading,
      originalDownloading,
    };
    return {
      ...residue,
      files: sheetsDialogIsOpen
        ? []
        : file == null
        ? []
        : [{ ...activeFile, checked: true }],
      activeFile: sheetsDialogIsOpen
        ? { ...activeFile, step: activeStep }
        : null,
    };
  };
  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    const { claims, ...residue } = input;
    return {
      ...residue,
      claims: upgradeClaims(claims),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: upgradeInput(data.state?.input),
      schemaVersion: 6,
    },
  };
};

export const toSchemaVersion7 = (data) => {
  const upgradeClaims = (claims) => {
    if (claims == null) {
      return claims;
    }
    const {
      frequencySummary,
      severitySummary,
      aggregateSummary,
      ...residue
    } = claims;
    return {
      ...residue,
    };
  };

  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    const { claims, ...residue } = input;
    return {
      ...residue,
      claims: upgradeClaims(claims),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: upgradeInput(data.state?.input),
      schemaVersion: 7,
    },
  };
};

export const toSchemaVersion8 = (data) => {
  const upgradeProgram = (program) => {
    if (program == null) {
      return program;
    }
    const currency = (program.currency ?? "") === "" ? "USD" : program.currency;
    return {
      ...program,
      currency,
    };
  };

  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    const { program, ...residue } = input;
    return {
      ...residue,
      program: upgradeProgram(program),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: upgradeInput(data.state?.input),
      schemaVersion: 8,
    },
  };
};

export const toSchemaVersion9 = (data) => {
  const upgradeFile = (file) => {
    if (file == null) {
      return file;
    }
    return {
      ...file,
      activeSheet: file.sheets.indexOf(file.activeSheet),
    };
  };
  const upgradeClaims = (claims) => {
    if (claims == null) {
      return claims;
    }
    return {
      ...claims,
      activeFile: upgradeFile(claims.activeFile),
      files: claims.files.map(upgradeFile),
    };
  };

  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    return {
      ...input,
      claims: upgradeClaims(input.claims),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      input: upgradeInput(data.state?.input),
      schemaVersion: 9,
    },
  };
};

export const toSchemaVersion10 = (data) => {
  const createId = (localId) => {
    try {
      return uuid.v5(
        localId,
        submissions.extractSubmissionIdFromFilename(data.filename)
      );
    } catch (e) {
      return null;
    }
  };

  const upgradeLayer = (layer, layerIndex) => {
    const upgradePremiumAdjustment = (
      premiumAdjustment,
      premiumAdjustmentIndex
    ) => {
      return {
        ...premiumAdjustment,
        id: createId(
          `layer-${layerIndex}-premiumAdjustment-${premiumAdjustmentIndex}`
        ),
      };
    };

    const { premiumAdjustments, ...rest } = layer ?? {};
    return {
      ...rest,
      premiumAdjustments: (premiumAdjustments ?? []).map(
        upgradePremiumAdjustment
      ),
      id: createId(`layer-${layerIndex}`),
    };
  };

  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: (pricing.layers ?? []).map(upgradeLayer),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 10,
    },
  };
};

export const toSchemaVersion11 = (data) => {
  const upgradePrices = (prices) => {
    const { improvexRatedLossCost, ...rest } = prices ?? {};
    return improvexRatedLossCost != null
      ? {
          ...rest,
          modelPrice: improvexRatedLossCost,
        }
      : rest;
  };

  const upgradeLayer = (layer) => {
    if (layer == null) {
      return null;
    }
    const { purePremium, ...rest } = layer;
    const { uwSelectedPrice, ...prices } = purePremium ?? {};
    return {
      ...rest,
      pricing: {
        selectedModel: "__v1__",
        selectedPrices: {
          __v1__: uwSelectedPrice ?? null,
        },
        selectedMeasures: {
          __v1__: "uwAdjustedPrice",
        },
        prices: {
          __v1__: upgradePrices(prices),
        },
      },
    };
  };

  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: (pricing.layers ?? []).map(upgradeLayer),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 11,
    },
  };
};

export const toSchemaVersion12 = (data) => {
  const createId = (localId) => {
    try {
      return uuid.v5(
        localId,
        submissions.extractSubmissionIdFromFilename(data.filename)
      );
    } catch (e) {
      return null;
    }
  };

  function updateState(state) {
    function updateInput(input) {
      function updateClaims(claims) {
        if (!claims) return claims;
        if (claims.inflationModel) return claims;
        return {
          ...claims,
          inflationModel: "LEGACY",
        };
      }
      if (!input) return input;
      return {
        ...input,
        claims: updateClaims(input.claims),
      };
    }

    function updatePricing(pricing) {
      function updateLayer(layer, layerIndex) {
        if (!layer) return layer;
        const { id, ...rest } = layer;
        return {
          ...rest,
          pricing: {
            selectedModel: "__v1__",
            ...layer.pricing,
          },
          id: id || createId(`layer-${layerIndex}`),
        };
      }

      if (!pricing) return pricing;
      return {
        ...pricing,
        layers: (pricing.layers ?? []).map(updateLayer),
      };
    }

    if (!state) return state;
    return {
      ...state,
      input: updateInput(state.input),
      pricing: updatePricing(state.pricing),
      schemaVersion: 12,
    };
  }
  if (!data) return data;
  return {
    ...data,
    state: updateState(data.state),
  };
};

export const toSchemaVersion13 = (data) => {
  const upgradeProgram = (program) => {
    if (program == null) {
      return program;
    }
    const { jasmineReference, ...residue } = program;
    return {
      ...residue,
      reference: jasmineReference,
    };
  };

  const upgradeInput = (input) => {
    if (input == null) {
      return input;
    }
    const { program, ...residue } = input;
    return {
      ...residue,
      program: upgradeProgram(program),
    };
  };

  const { jasmineid, state, ...residue } = data;
  return {
    ...residue,
    reference: jasmineid ?? "",
    state: {
      ...state,
      input: upgradeInput(state?.input),
      schemaVersion: 13,
    },
  };
};

export const toSchemaVersion14 = (data) => {
  const upgradeTowerLayer = (layer) => {
    const { carrier, ...residue } = layer;
    const carriers = (carrier ?? "").split("/").map((c) => c.trim());
    const shares =
      carriers.length === 1
        ? [{ carrier: carriers[0], share: 100 }]
        : carriers.map((carrier) => ({ carrier }));
    return {
      ...residue,
      shares,
    };
  };
  const upgradeTower = (tower) => {
    if (tower == null) {
      return tower;
    }
    return {
      ...tower,
      layers: (tower.layers ?? []).map(upgradeTowerLayer),
    };
  };

  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return pricing;
    }
    return {
      ...pricing,
      tower: upgradeTower(pricing.tower),
    };
  };

  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 14,
    },
  };
};

export const toSchemaVersion15 = (data) => {
  const upgradeV1Prices = (v1Prices) => {
    if (v1Prices == null) {
      return v1Prices;
    }
    if (v1Prices.uwAdjustedPrice != null) {
      return v1Prices;
    } else if (v1Prices.recommendedPrice != null) {
      const judgmentFactor = flagsSelectors.getJudgmentFactor(data.state);
      return {
        ...v1Prices,
        uwAdjustedPrice: v1Prices.recommendedPrice * judgmentFactor,
      };
    } else {
      return v1Prices;
    }
  };
  const upgradePrices = (prices) => {
    if (prices == null) {
      return prices;
    }
    const { __v1__, ...residue } = prices;
    if (__v1__ == null) {
      return prices;
    } else {
      return {
        ...residue,
        __v1__: upgradeV1Prices(__v1__),
      };
    }
  };
  const upgradeLayerPricing = (pricing) => {
    const { prices, ...residue } = pricing;
    return {
      ...residue,
      prices: upgradePrices(prices),
    };
  };
  const upgradeLayer = (layer) => {
    if (layer == null) {
      return layer;
    }
    return {
      ...layer,
      pricing: upgradeLayerPricing(layer.pricing ?? {}),
    };
  };
  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return pricing;
    }
    return {
      ...pricing,
      layers: (pricing.layers ?? []).map(upgradeLayer),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 15,
    },
  };
};

export const toSchemaVersion16 = (data) => {
  const upgradeRateChange = (rateChange) => {
    if (rateChange == null) {
      return rateChange;
    }
    const { selectedExposures, ...residue } = rateChange;
    return {
      ...residue,
      selectedExposures:
        selectedExposures != null && selectedExposures.length > 0
          ? selectedExposures
          : residue.selectedExposure != null
          ? [residue.selectedExposure]
          : [],
    };
  };
  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return pricing;
    }
    const { rateChange, ...residue } = pricing;
    if (rateChange == null) {
      return pricing;
    } else {
      return {
        ...residue,
        rateChange: upgradeRateChange(rateChange),
      };
    }
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 16,
    },
  };
};

export const toSchemaVersion17 = (data) => {
  const upgradeLayer = (layer) => {
    if (layer == null) {
      return null;
    }
    const { bound, ...residue } = layer;
    return {
      ...residue,
      status: !!bound ? "BOUND" : "DRAFT",
    };
  };

  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: (pricing.layers ?? []).map(upgradeLayer),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 17,
    },
  };
};

export const toSchemaVersion18 = (data) => {
  const createId = (localId) => {
    try {
      return uuid.v5(
        localId,
        submissions.extractSubmissionIdFromFilename(data.filename)
      );
    } catch (e) {
      return null;
    }
  };

  const upgradeLayer = (layer, layerIndex) => {
    return {
      ...layer,
      id: layer.id ?? createId(`layer-${layerIndex}`),
      status: layer.status ?? "DRAFT",
    };
  };

  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: (pricing.layers ?? []).map(upgradeLayer),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 18,
    },
  };
};

export const toSchemaVersion19 = (data) => {
  const upgradeScenarios = (scenarios) => {
    if (scenarios == null) {
      return null;
    }
    return {
      ...scenarios,
      scenarios: Object.fromEntries(
        (scenarios?.scenarios ?? []).map((key) => [key, 2])
      ),
    };
  };

  return {
    ...data,
    state: {
      ...data.state,
      input: {
        ...data.state?.input,
        scenarios: upgradeScenarios(data.state?.input?.scenarios),
      },
      schemaVersion: 19,
    },
  };
};

export const toSchemaVersion20 = (data) => {
  if (data.state.summary?.peerReview?.date === "")
    return {
      ...data,
      state: {
        ...data.state,
        summary: {
          ...data.state.summary,
          peerReview: {
            ...data.state.summary.peerReview,
            date: null,
          },
        },
        schemaVersion: 20,
      },
    };
  return {
    ...data,
    state: {
      ...data.state,
      schemaVersion: 20,
    },
  };
};

export const toSchemaVersion21 = (data) => {
  const makeRateChangeFromLayer = (layer) => {
    const out = {};

    const note = layer?.rateChange?.note;
    if (note != null) {
      out.note = note;
    }

    const pureRateChangeOverride = layer?.rateChange?.summaryOverrides?.pure;
    if (pureRateChangeOverride != null) {
      out.pureRateChangeOverride = pureRateChangeOverride;
    }

    if (Object.keys(out).length) {
      out.inflation = 5;
    }

    return out;
  };
  const upgradeLayer = (layer) => {
    if (layer == null) {
      return null;
    }
    const { rateChange, ...rest } = layer;
    return rest;
  };
  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    const { rateChange, ...rest } = pricing;
    return {
      ...rest,
      layers: rest?.layers?.map((layer) => upgradeLayer(layer)),
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      rateChange: {
        byLayer: Object.fromEntries(
          (data.state?.pricing?.layers ?? [])
            .map((layer) => [layer.id, makeRateChangeFromLayer(layer)])
            .filter(([_, rateChange]) => Object.keys(rateChange).length > 0)
        ),
      },
      schemaVersion: 21,
    },
  };
};

export const toSchemaVersion22 = (data) => {
  const oldAtt = data?.state?.input?.attachments;
  const attachments = {
    attachedFiles: [
      ...(oldAtt?.attachedFiles || []),
      ...(oldAtt?.rootFiles || []),
    ],
    attachedFileNameOverrides: { ...oldAtt?.attachedFileNameOverrides },
    attachedFilesHidden: { ...oldAtt?.attachedFilesHidden },
  };
  const addFileData = (fileId, file) => {
    if (file.displayName)
      attachments.attachedFileNameOverrides[fileId] = file.displayName;
    if (file.hidden) attachments.attachedFilesHidden[fileId] = true;
    if (file.children) {
      for (const child in file.children) {
        addFileData(child.id, child);
      }
    }
  };
  if (oldAtt?.files) {
    for (const id in oldAtt.files) {
      addFileData(id, oldAtt.files[id]);
    }
  }
  const res = {
    ...data,
    state: {
      ...data?.state,
      schemaVersion: 22,
    },
  };
  if (oldAtt) res.state.input.attachments = attachments;
  if (!res.id) {
    if (res.filename && res.filename.length > 36) {
      res.id = res.filename.substring(0, 36);
    }
  }
  return res;
};

export const toSchemaVersion23 = (data) => {
  const att = data.state?.input?.attachments || {};
  const attFiles = att.attachedFiles || [];
  const hidFiles = att.attachedFilesHidden || {};
  const newHidFiles = Object.fromEntries(
    Object.entries(hidFiles).filter((e) => !attFiles.includes(e[0]))
  );

  return {
    ...data,
    state: {
      ...data?.state,
      input: {
        ...data?.state?.input,
        attachments: {
          ...att,
          attachedFiles: attFiles.filter((id) => !hidFiles[id]),
          deletedFiles: attFiles.filter((id) => hidFiles[id]),
          attachedFilesHidden: newHidFiles,
        },
      },
      schemaVersion: 23,
    },
  };
};

export const toSchemaVersion24 = (data) => {
  const id = ids.makeNewId();
  return {
    ...data,
    state: {
      ...data?.state,
      lossDevelopment:
        data.state?.lossDevelopment == null
          ? data.state?.lossDevelopment
          : {
              triangles: {
                [id]: {
                  ...data.state?.lossDevelopment,
                  id,
                  name: "Loss Development",
                },
              },
            },
      schemaVersion: 24,
    },
  };
};

export const toSchemaVersion25 = (data) => {
  const upgradeLayer = (layer) => {
    if (layer == null) {
      return null;
    }
    return layer?.reinsurance == null
      ? layer
      : {
          ...layer,
          paperProvider: layer.reinsurance?.[0] ?? null,
        };
  };
  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: pricing?.layers?.map((layer) => upgradeLayer(layer)) ?? [],
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 25,
    },
  };
};

export const toSchemaVersion26 = (data) => {
  const upgradeMeta = (meta) => {
    if (meta == null) {
      return meta;
    }
    return {
      ...meta,
      pending:
        meta?.pending === false || meta?.pending === "False"
          ? null
          : meta?.pending,
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      meta: upgradeMeta(data.state?.meta),
      schemaVersion: 26,
    },
  };
};

export const toSchemaVersion27 = (data) => {
  const upgradeLayer = (layer) => {
    if (layer == null) {
      return null;
    }
    const { reinsurance, ...rest } = layer;
    return rest;
  };
  const upgradePricing = (pricing) => {
    if (pricing == null) {
      return null;
    }
    return {
      ...pricing,
      layers: pricing?.layers?.map((layer) => upgradeLayer(layer)) ?? [],
    };
  };
  return {
    ...data,
    state: {
      ...data.state,
      pricing: upgradePricing(data.state?.pricing),
      schemaVersion: 27,
    },
  };
};

export const toSchemaVersion28 = (data) => {
  const BROKER_TYPE = "BROKER";
  const UNDERWRITER_TYPE = "UNDERWRITER";
  const origPr = data.state.input.program;
  const {
    broker,
    brokerContact,
    brokerHouse,
    brokerId,
    preferredUnderwriter,
    preferredUnderwriterId,
    secondaryUnderwriter,
    ...program
  } = origPr;
  const people = [];
  if (preferredUnderwriter || preferredUnderwriterId) {
    people.push({
      type: UNDERWRITER_TYPE,
      name: preferredUnderwriter ?? "",
      id: preferredUnderwriterId ?? null,
    });
  }
  if (secondaryUnderwriter) {
    people.push({ ...secondaryUnderwriter, type: UNDERWRITER_TYPE });
  }
  if (broker || brokerId || brokerHouse || brokerContact) {
    people.push({
      type: BROKER_TYPE,
      name: broker ?? "",
      id: brokerId ?? null,
      company: brokerHouse ?? "",
      email: brokerContact ?? "",
    });
  }

  const newData = {
    ...data,
    state: {
      ...data.state,
      input: {
        ...data.state.input,
        program: {
          ...program,
        },
      },
      people: {
        people,
      },
      schemaVersion: 28,
    },
  };
  return newData;
};

export const toSchemaVersion29 = (data) => {
  const newData = {
    ...data,
    state: {
      ...data.state,
      rateChange: {
        ...data.state?.rateChange,
        byLayer: Object.fromEntries(
          Object.entries(data.state?.rateChange?.byLayer ?? {}).map(
            ([layerId, layerRateChange]) => {
              const { queryData, ...rest } = layerRateChange;
              return [layerId, rest];
            }
          )
        ),
      },
      schemaVersion: 29,
    },
  };
  return newData;
};

export const upgradeState = (data) => {
  switch (data.state.schemaVersion) {
    case undefined:
      return upgradeState(toSchemaVersion3(data));
    case 2:
    case 3:
      return upgradeState(toSchemaVersion4(data));
    case 4:
      return upgradeState(toSchemaVersion5(data));
    case 5:
      return upgradeState(toSchemaVersion6(data));
    case 6:
      return upgradeState(toSchemaVersion7(data));
    case 7:
      return upgradeState(toSchemaVersion8(data));
    case 8:
      return upgradeState(toSchemaVersion9(data));
    case 9:
      return upgradeState(toSchemaVersion10(data));
    case 10:
      return upgradeState(toSchemaVersion11(data));
    case 11:
      return upgradeState(toSchemaVersion12(data));
    case 12:
      return upgradeState(toSchemaVersion13(data));
    case 13:
      return upgradeState(toSchemaVersion14(data));
    case 14:
      return upgradeState(toSchemaVersion15(data));
    case 15:
      return upgradeState(toSchemaVersion16(data));
    case 16:
      return upgradeState(toSchemaVersion17(data));
    case 17:
      return upgradeState(toSchemaVersion18(data));
    case 18:
      return upgradeState(toSchemaVersion19(data));
    case 19:
      return upgradeState(toSchemaVersion20(data));
    case 20:
      return upgradeState(toSchemaVersion21(data));
    case 21:
      return upgradeState(toSchemaVersion22(data));
    case 22:
      return upgradeState(toSchemaVersion23(data));
    case 23:
      return upgradeState(toSchemaVersion24(data));
    case 24:
      return upgradeState(toSchemaVersion25(data));
    case 25:
      return upgradeState(toSchemaVersion26(data));
    case 26:
      return upgradeState(toSchemaVersion27(data));
    case 27:
      return upgradeState(toSchemaVersion28(data));
    case 28:
      return upgradeState(toSchemaVersion29(data));
    default:
      return data;
  }
};
