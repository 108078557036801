import SaveConflictAlert from "./SaveConflictAlert";
import {
  ChatOutlined as CommentsIcon,
  ExitToAppRounded as InputIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  Send as AllocationIcon,
  SettingsEthernet as ToggleWidthtIcon,
  ShowChartRounded as AnalysisIcon,
  SubjectRounded as SummaryIcon,
  WidgetsOutlined as ComparisonIcon,
  ContentCopyRounded as DuplicateIcon,
} from "@mui/icons-material";
import CommentsDrawer from "components/comments/CommentsDrawer";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import HiddenIfReadOnly from "components/common/HiddenIfReadOnly";
import IconButton from "components/common/IconButton";
import ShortcutButton from "components/common/ShortcutButton";
import Visible from "components/common/Visible";
import AllocationDialog from "components/workflow/AllocationDialog";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useRetrieveNavigationConfigQuery } from "services/staticDataService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as metaSelectors from "store/selectors/meta/metaSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as navigationSelectors from "store/selectors/temp/staticData/navigation/navigationSelectors";
import * as transferSelectors from "store/selectors/temp/transfer/transferSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

export const NavButton = ({ text, icon, path, ...props }) => (
  <NavLink
    to={`${path}`}
    className={({ isActive }) =>
      "nav-link " + (isActive ? "active-section" : "")
    }
    {...props}
  >
    {icon} {text}
  </NavLink>
);

export const CommentsButton = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      <IconButton
        icon={CommentsIcon}
        className={"link"}
        tooltip={"Comments"}
        onClick={() => setShowDrawer(!showDrawer)}
      />
      <CommentsDrawer
        isOpen={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
    </>
  );
};

export const AllocationButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        icon={AllocationIcon}
        className={"link"}
        tooltip={"Allocate"}
        onClick={() => setOpen(true)}
      />
      {open && <AllocationDialog onClose={() => setOpen(false)} />}
    </>
  );
};

export const NavigationComponent = (props) => {
  const navigate = useNavigate();

  const createRenewalSubmission = useCallback(() => {
    props.renewSubmission({ navigate });
  }, [navigate, props]);

  const duplicateSubmission = useCallback(() => {
    props.duplicateSubmission({ navigate });
  }, [navigate, props]);

  const {
    data: navigationConfig,
    isLoading: isNavigationConfigLoading,
  } = useRetrieveNavigationConfigQuery();

  const translateTab = staticDataHooks.useTranslator("tabs");

  if (isNavigationConfigLoading) {
    return <></>;
  }

  const tabs = {
    Inputs: {
      text: "Inputs",
      icon: <InputIcon />,
      path: "inputs",
      visibilityTag: "inputs",
      defaultVisibility: true,
    },
    Analysis: {
      text: "Analysis",
      icon: <AnalysisIcon />,
      path: "pricing",
      visibilityTag: "pricing",
      defaultVisibility: true,
    },
    Comparison: {
      text: "Comparison",
      icon: <ComparisonIcon />,
      path: "peers",
      visibilityTag: "comparison",
      defaultVisibility: true,
    },
    Summary: {
      text: "Summary",
      icon: <SummaryIcon />,
      path: "summary",
      visibilityTag: "summary",
      defaultVisibility: true,
    },
  };

  const sections = (
    navigationConfig?.tabOrder?.map(([tabName, _]) => tabName) ??
    Object.keys(tabs)
  )
    .map((tabName) => tabs[tabName])
    .filter(
      (tabConfig) =>
        props.userConfig?.componentVisibility?.[tabConfig.visibilityTag] ??
        tabConfig.defaultVisibility
    );

  return (
    <div className={"navbar-wrapper"}>
      <div
        className={
          "navbar container" + (props.isFullWidth ? " full-width" : "")
        }
      >
        <div className={"nav-left"}>
          {sections.map((section, i) => {
            return (
              <NavButton
                text={translateTab(section.path, { default: section.text })}
                icon={section.icon}
                path={section.path}
                data-testid={`parent-tab-${section.path}`}
                key={i}
              />
            );
          })}
        </div>

        <div className={"nav-right"}>
          <IconButton
            icon={ToggleWidthtIcon}
            onClick={() => props.toggleIsFullWidth()}
            className={"link"}
            tooltip={"Toggle Width"}
          />
          {props.hasPriorSubmission && (
            <HiddenIfReadOnly>
              <Link
                to={`/submissions/${props.priorSubmissionId}`}
                target={"_blank"}
              >
                <IconButton
                  icon={RotateLeftIcon}
                  className={"link"}
                  tooltip={"Prior Submission"}
                />
              </Link>
            </HiddenIfReadOnly>
          )}
          {props.isNew || (
            <Disabled ifReadOnly>
              <IconButton
                icon={RotateRightIcon}
                onClick={createRenewalSubmission}
                className={"link"}
                tooltip={"Renew"}
              />
            </Disabled>
          )}
          <Visible byTag={"<Submission>.duplicate"} defaultVisible={false}>
            <Disabled ifReadOnly>
              <IconButton
                icon={DuplicateIcon}
                onClick={duplicateSubmission}
                className={"link"}
                tooltip={"Duplicate"}
              />
            </Disabled>
          </Visible>
          <HiddenIfReadOnly>
            <Visible byTag={"<Submission>.allocation"}>
              <AllocationButton />
            </Visible>
          </HiddenIfReadOnly>
          <HiddenIfReadOnly>
            <Visible byTag={"<Submission>.comments"}>
              <CommentsButton />
            </Visible>
          </HiddenIfReadOnly>
          <Disabled ifReadOnly>
            <ShortcutButton
              onClick={() => {
                props.saveState();
              }}
              variant={"contained"}
              size={"small"}
              disableElevation
              disabled={props.saving || props.transferInOperation}
              id={"save-button"}
              className={props.isSaveRequired ? "" : "button nav-button"}
              style={{ minWidth: "78px", marginLeft: "12px" }}
              shortcut={"s"}
            >
              {props.saving ? (
                <DefaultLoader
                  color={"#dc7f4c"}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "3px",
                  }}
                  size={16}
                />
              ) : props.isSaveRequired ? (
                "Save"
              ) : (
                "Saved"
              )}
            </ShortcutButton>
          </Disabled>
        </div>
      </div>
      <div
        className={
          "navbar container" + (props.isFullWidth ? " full-width" : "")
        }
      >
        {props.submissionStatus === "default" && (
          <HiddenIfReadOnly>
            <Visible byTag={"saveConflictAlert"} defaultVisible={false}>
              <SaveConflictAlert />
            </Visible>
          </HiddenIfReadOnly>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  saveState: persistenceActions.saveState.requested,
  renewSubmission: persistenceActions.renewSubmission,
  duplicateSubmission: persistenceActions.duplicateSubmission,
};

const mapStateToProps = (state) => {
  const priorSubmissionId = programSelectors.getPriorSubmissionId(state);
  return {
    saving: state.persistence.saving,
    isNew: persistenceSelectors.selectIsNew(state),
    submissionStatus: persistenceSelectors.selectSubmissionStatus(state),
    userConfig: userSelectors.selectUserConfig(state),
    priorSubmissionId,
    hasPriorSubmission: !!priorSubmissionId,
    isSaveRequired: navigationSelectors.isSaveRequired(state),
    companyId: userSelectors.selectUserAttributes(state)["custom:company_id"],
    transferInOperation:
      (metaSelectors.selectIsDeleted(state) &&
        !!metaSelectors.selectPending(state)) ||
      transferSelectors.saveTransferInProgress(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationComponent);
