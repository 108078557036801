import { SettingsBackupRestore as ResetIcon } from "@mui/icons-material";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import * as objects from "common/objects";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import { useEffect, useState } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import { useParams } from "react-router-dom";
import * as transferService from "services/transferService";

const stringify = (value) =>
  JSON.stringify(value ?? {}, objects.stringifyReducers.sortKeys, 4);

const Content = ({ mergeDefinition, updateMergeDefinition }) => {
  const [diffBase, setDiffBase] = useState("incoming");
  const [submissionString, setSubmissionString] = useState("");

  useEffect(() => {
    setSubmissionString(stringify(mergeDefinition?.merged?.submission));
  }, [mergeDefinition, setSubmissionString]);

  const incoming = mergeDefinition?.incoming?.submission ?? {};
  const existing = mergeDefinition?.existing?.submission ?? {};

  const diffBaseSubmissionString =
    diffBase === "incoming" ? stringify(incoming) : stringify(existing);

  return (
    <Stack
      direct={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={2}
    >
      <TextField
        value={submissionString}
        onChange={(e) => setSubmissionString(e.target.value)}
        hiddenLabel
        fullWidth
        multiline
        rows={50}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ width: "100%" }}
      >
        <Button
          onClick={() => {
            const newMergeDefinition = {
              ...mergeDefinition,
              merged: {
                submission: JSON.parse(submissionString),
              },
            };
            updateMergeDefinition(newMergeDefinition);
          }}
        >
          {"Save"}
        </Button>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        sx={{ width: "100%" }}
      >
        <FormControl>
          <FormLabel id={"diff-label"}>{"Diff relative to"}</FormLabel>
          <RadioGroup
            row
            value={diffBase}
            onChange={(e) => setDiffBase(e.target.value)}
          >
            <FormControlLabel
              value={"incoming"}
              control={<Radio />}
              label={"Incoming"}
            />
            <FormControlLabel
              value={"existing"}
              control={<Radio />}
              label={"Existing"}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <ReactDiffViewer
        oldValue={diffBaseSubmissionString}
        newValue={submissionString}
        splitView={true}
      />
    </Stack>
  );
};

const Merge = () => {
  const { transferId, companyId } = useParams();

  const {
    currentData: mergeDefinition,
    isFetching: isMergeDefinitionFetching,
    error,
    refetch,
  } = transferService.useRetrieveMergeDefinitionQuery({
    transferId,
    impersonateCompanyId: companyId,
  });

  const [
    _updateMergeDefinition,
    { isFetching: isUpdateMergeDefinitionFetching },
  ] = transferService.useUpdateMergeDefinitionMutation();

  const updateMergeDefinition = async (newMergeDefinition) => {
    // eslint-disable-next-line
    console.log("newMergeDefinition:", newMergeDefinition);
    await _updateMergeDefinition({
      mergeDefinition: newMergeDefinition,
      impersonateCompanyId: companyId,
    });
    refetch();
  };

  const isFetching =
    isMergeDefinitionFetching || isUpdateMergeDefinitionFetching;

  return (
    <Component
      title={"Merge"}
      options={
        <IconButton
          variant={"bright"}
          icon={ResetIcon}
          onClick={refetch}
          tooltip={"Reload the transfer"}
        />
      }
    >
      {isFetching ? (
        <BigLoader />
      ) : error ? (
        <Alert severity={"error"}>{error}</Alert>
      ) : (
        <Content
          mergeDefinition={mergeDefinition}
          updateMergeDefinition={updateMergeDefinition}
        />
      )}
    </Component>
  );
};

export default Merge;
