import {
  getFirstOfNextMonthStrNoTimeZone,
  getMoveForwardOneYear,
} from "common/dates";
import * as programResolvers from "domain/programResolvers";
import { extractSubmissionIdFromFilename } from "domain/submissions";
import { createSelector } from "reselect";
import * as prior from "store/selectors/common/prior";
import * as stateSelectors from "store/selectors/stateSelectors";

export const getProgram = (state) => state.input.program;

export const getInception = (state) => state?.input?.program?.inception;
export const getInceptionYear = createSelector([getInception], (inception) => {
  return inception == null
    ? undefined
    : new Date(inception).getUTCFullYear() || undefined;
});

export const getInsured = (state) => state.input.program?.insured || "";
const getRawExpiration = (state) => state.input.program.expiration;
const getPricingNaicsRaw = (state) => state.input.program.naics;
const getCompanyNaicsRaw = (state) => state.input.program.companyNaics;
export const getPriorSubmission = (state) =>
  state?.input?.program?.priorSubmission;

export const getPriorSubmissionId = createSelector(
  [getPriorSubmission],
  (priorSubmission) => {
    if (priorSubmission == null) {
      return priorSubmission;
    }
    return extractSubmissionIdFromFilename(priorSubmission);
  }
);

export const getPricingNaicsCode = createSelector(
  [getPricingNaicsRaw],
  (naics) => naics?.code
);
export const getTurnoverCategory = (state) =>
  state.input.program.turnoverCategory;
export const getInUs = (state) => state.input.program.inUS;

export const getPriorExpiration = createSelector(
  [prior.getPrior(getRawExpiration), prior.getPrior(getInception)],
  (rawExpiration, inception) => {
    if (isNaN(new Date(inception).getTime())) {
      inception = getFirstOfNextMonthStrNoTimeZone();
    }
    return rawExpiration ? rawExpiration : getMoveForwardOneYear(inception);
  }
);

export const getCompanyNaics = createSelector(
  [getPricingNaicsRaw, getCompanyNaicsRaw],
  (pricingNaics, companyNaics) => {
    return companyNaics ?? pricingNaics;
  }
);

export const isPricingNaicsCodeInError = (code) => {
  if (code === 446110 || code === 621991 || code === 999998) {
    return true;
  }
  const strCode = `${code}`;
  return ["3254", "3391", "4242", "484"].some((_) => strCode.startsWith(_));
};

export const isPricingNaicsInError = createSelector(
  [getPricingNaicsRaw],
  (naics) => {
    if (naics == null) {
      return false;
    }
    return isPricingNaicsCodeInError(naics.code);
  }
);

export const selectProgram = (state) => state?.input?.program ?? {};

export const selectResolvedProgram = stateSelectors.createSelector(
  [selectProgram],
  programResolvers.resolveProgram
);
