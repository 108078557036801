export const UPDATE_ACTIVE_LAYER_LIMIT = "UPDATE_ACTIVE_LAYER_LIMIT";
export const UPDATE_LAYER_COMMENT = "PRICING_UPDATE_LAYER_COMMENT";
export const UPDATE_ACTIVE_LAYER_ATTACHMENT = "UPDATE_ACTIVE_LAYER_ATTACHMENT";
export const UPDATE_ACTIVE_LAYER_LINE_SIZE_ABSOLUTE =
  "UPDATE_ACTIVE_LAYER_LINE_SIZE_ABSOLUTE";
export const REFRESH_ACTIVE_LAYER_PRICING = "REFRESH_ACTIVE_LAYER_PRICING";
export const ACTIVE_LAYER_PRICING_RETURNED = "ACTIVE_LAYER_PRICING_RETURNED";
export const ACTIVE_LAYER_PRICING_FAILED = "ACTIVE_LAYER_PRICING_FAILED";
export const UPDATE_BASE_LAYER_LIMIT = "UPDATE_BASE_LAYER_LIMIT";
export const UPDATE_BASE_LAYER_ATTACHMENT = "UPDATE_BASE_LAYER_ATTACHMENT";
export const UPDATE_BASE_LAYER_FROM = "UPDATE_BASE_LAYER_FROM";
export const UPDATE_BASE_LAYER_TO = "UPDATE_BASE_LAYER_TO";
export const UPDATE_GROSS_PREMIUM = "UPDATE_GROSS_PREMIUM";
export const UPDATE_BASE_LAYER_EXPECTED_NUMBER_OF_BLENDED_LOSSES =
  "UPDATE_BASE_LAYER_EXPECTED_NUMBER_OF_BLENDED_LOSSES";
export const UPDATE_INSURED_WEIGHT = "UPDATE_INSURED_WEIGHT";
export const UPDATE_IMPROVEX_WEIGHT = "UPDATE_IMPROVEX_WEIGHT";
export const UPDATE_CHOSEN_EXPOSURE_METRIC = "UPDATE_CHOSEN_EXPOSURE_METRIC";
export const UPDATE_NUMBER_OF_EVENTS_AT_INSURED_IMPACTING_BASE_LAYER =
  "UPDATE_NUMBER_OF_EVENTS_AT_INSURED_IMPACTING_BASE_LAYER";
export const RECALCULATING_INSURED_EXPECTED_EVENTS =
  "RECALCULATING_INSURED_EXPECTED_EVENTS";
export const RECALCULATE_INSURED_EXPECTED_EVENTS =
  "RECALCULATE_INSURED_EXPECTED_EVENTS";
export const RECALCULATED_INSURED_EXPECTED_EVENTS =
  "RECALCULATED_INSURED_EXPECTED_EVENTS";
export const RECALCULATED_INSURED_EXPECTED_EVENTS_FAILED =
  "RECALCULATED_INSURED_EXPECTED_EVENTS_FAILED";
export const UPDATE_ACTIVE_LAYER_GROSS_PPM = "UPDATE_ACTIVE_LAYER_GROSS_PPM";
export const UPDATE_ACTIVE_LAYER_SHARE_OF_LINE =
  "UPDATE_ACTIVE_LAYER_SHARE_OF_LINE";
export const UPDATE_ACTIVE_LAYER_TRIA = "UPDATE_ACTIVE_LAYER_TRIA";
export const UPDATE_ACTIVE_LAYER_SHARE_OF_PREMIUM =
  "UPDATE_ACTIVE_LAYER_SHARE_OF_PREMIUM";
export const UPDATE_ACTIVE_LAYER_AUTHORIZED_LIMIT =
  "UPDATE_ACTIVE_LAYER_AUTHORIZED_LIMIT";
export const UPDATE_ACTIVE_LAYER_BROKERAGE = "UPDATE_ACTIVE_LAYER_BROKERAGE";
export const UPDATE_ACTIVE_LAYER_EXPENSES = "UPDATE_ACTIVE_LAYER_EXPENSES";
export const UPDATE_ACTIVE_LAYER_PROFIT = "UPDATE_ACTIVE_LAYER_PROFIT";
export const UPDATE_ACTIVE_LAYER_OTHER_ACQUISITION_COSTS =
  "UPDATE_ACTIVE_LAYER_OTHER_ACQUISITION_COSTS";
export const UPDATE_ACTIVE_LAYER_STATUS =
  "LAYER_PRICING_UPDATE_ACTIVE_LAYER_STATUS";
export const UPDATE_ACTIVE_LAYER_REFERENCE = "UPDATE_ACTIVE_LAYER_REFERENCE";
export const UPDATE_ACTIVE_LAYER_PAYMENT_TERMS =
  "UPDATE_ACTIVE_LAYER_PAYMENT_TERMS";
export const UPDATE_ACTIVE_LAYER_REINSURANCE =
  "UPDATE_ACTIVE_LAYER_REINSURANCE";
export const UPDATE_ALL_LAYERS_REINSURANCE = "UPDATE_ALL_LAYERS_REINSURANCE";
export const UPDATE_ACTIVE_LAYER = "LAYER_PRICING_UPDATE_ACTIVE_LAYER";
export const DELETE_LAYER = "LAYER_PRICING_DELETE_LAYER";
export const ADD_LAYER = "LAYER_PRICING_ADD_LAYER";
export const SET_AS_BASE_LAYER_FOR_TOWER_PRICING_SCALING =
  "SET_AS_BASE_LAYER_FOR_TOWER_PRICING_SCALING";
export const UPDATE_LAYER_STATUS = "UPDATE_LAYER_STATUS";
export const UPDATE_LAYER_PRICING_NOTE = "UPDATE_LAYER_PRICING_NOTE";
export const UPDATE_FREQUENCY_PRICING_NOTE = "UPDATE_FREQUENCY_PRICING_NOTE";
export const SET_ACTIVE_LAYER_POLICY_FORM = "SET_ACTIVE_LAYER_POLICY_FORM";
export const UPDATE_LAYER = "UPDATE_LAYER";
export const UPDATE_PRICING_INPUT = "UPDATE_PRICING_INPUT";
export const SET_PRICING_ASSUMPTION = "SET_PRICING_ASSUMPTION";
export const UPDATE_CLAIMS_DATE_RANGE = "UPDATE_CLAIMS_DATE_RANGE";
export const UPDATE_CLAIMS_INCLUDED_LOSS_TYPES =
  "UPDATE_CLAIMS_INCLUDED_LOSS_TYPES";
export const UPDATE_RECOMMENDED_BASE_LAYER = "UPDATE_RECOMMENDED_BASE_LAYER";
