import { Alert } from "@mui/material";
import * as chartUtils from "chartUtils";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const formatChart = (chart) => {
  if (chart == null) {
    return chart;
  }
  return {
    data: [
      ...chart.data.map(({ year, value, ultimate }) => ({
        name: year,
        value,
        ultimate: ultimate == null ? ultimate : ultimate - value,
      })),
    ],
    mean: chart.mean,
  };
};

const Chart = ({
  data,
  dataName,
  ultimateName,
  averageLabel,
  referenceStyles,
}) => {
  const chart = formatChart(data);

  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const customLegendText = (value) => {
    return (
      <span style={{ color: "#45494e", fontSize: "12px", fontWeight: "bold" }}>
        {value}
      </span>
    );
  };

  const defaultAverageLabel = (mean) => {
    return `${ultimateName ? "Developed " : ""}Avg. to ${
      mean.year
    }: ${chartUtils.tickFormatter(ultimateName ? mean.ultimate : mean.value)}`;
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
    >
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          data={chart.data}
          margin={{
            top: 0,
            right: 50,
            left: 0,
            bottom: 0,
          }}
          ref={chartRef}
        >
          <CartesianGrid strokeDasharray={"3 3"} />
          <Tooltip
            position={tooltipPosition}
            isAnimationActive={false}
            formatter={chartUtils.formatToolTip}
          />
          <Legend formatter={customLegendText} />
          <XAxis dataKey={"name"} />
          <YAxis tickFormatter={chartUtils.tickFormatter} />
          <Bar
            dataKey={"value"}
            name={dataName}
            stackId={"a"}
            fill={"#FFAB53"}
          />
          {ultimateName == null ? (
            <></>
          ) : (
            <Bar
              dataKey={"ultimate"}
              name={ultimateName}
              stackId={"a"}
              fill={"#FF634E"}
            />
          )}
          {chart.mean && (
            <ReferenceLine
              y={ultimateName ? chart.mean.ultimate : chart.mean.value}
              stroke={"#62BEE5"}
              label={{
                value: chart.mean,
                position: "insideBottom",
                formatter: averageLabel || defaultAverageLabel,
                style: referenceStyles,
              }}
              strokeWidth={2}
              dot={false}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const ClaimsChart = ({
  title,
  subtitle,
  loading,
  error,
  chart,
  dataName,
  ultimateName,
  averageLabel,
  xs_width,
  styles,
  titleStyles,
  referenceStyles,
}) => {
  const defaultStyles = styles || {
    position: "relative",
    width: "100%",
    height: "300px",
  };

  return (
    <Component
      title={title}
      subtitle={subtitle}
      xs_width={xs_width || 12}
      sm_width={12}
      md_width={12}
      lg_width={4}
      titleStyles={titleStyles}
    >
      <div style={defaultStyles}>
        {loading ? (
          <BigLoader />
        ) : !!error ? (
          <Alert severity={"error"}>{error}</Alert>
        ) : !chart ? (
          <></>
        ) : (
          <Chart
            data={chart}
            dataName={dataName}
            ultimateName={ultimateName}
            averageLabel={averageLabel}
            referenceStyles={referenceStyles}
          />
        )}
      </div>
    </Component>
  );
};

export default ClaimsChart;
