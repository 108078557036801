import BigLoader from "./common/BigLoader";
import Disabled from "./common/Disabled";
import UploadIcon from "@mui/icons-material/DriveFolderUpload";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Component from "components/Component";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import { useDropzone } from "react-dropzone";

const calculateAsAtDateHelperText = (date) => {
  if (date) {
    return `Recommended: ${new Date(date).toLocaleString("en-US", {
      timeZone: "UTC",
      day: "numeric",
      month: "long",
      year: "numeric",
    })}`;
  } else {
    return "";
  }
};

const FileUpload = ({
  onDrop,
  disabled = false,
  size,
  asAtDateNotRequired,
  dropMessage = "Drop an .xlsx, .csv, or .tsv file here to upload",
  updateAsAtDate,
  asAtDate,
  recommendedAsAtDate,
  loading = false,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    disabled,
  });
  return (
    <Grid item xs={12} {...size}>
      <Component title={"Upload Data"}>
        <section className={"container"}>
          {loading ? (
            <BigLoader />
          ) : (
            <div
              {...getRootProps({
                className: `dropzone ${isDragActive ? "active" : ""} ${
                  isDragAccept ? "accept" : ""
                } ${isDragReject ? "reject" : ""}`,
              })}
              style={{ marginBottom: 16 }}
              data-testid={"file-upload"}
            >
              <input {...getInputProps()} />
              <UploadIcon fontSize={"large"} />
              <p className={"message"}>{dropMessage}</p>
              <Divider style={{ width: "100%", marginBottom: 16 }}>
                {"OR"}
              </Divider>
              <Disabled ifReadOnly>
                <Button
                  variant={"contained"}
                  disableElevation
                  color={"primary"}
                >
                  {"BROWSE FILES"}
                </Button>
              </Disabled>
            </div>
          )}
        </section>
        {asAtDateNotRequired ? (
          ""
        ) : (
          <FormattedDatePicker
            label={"As At Date"}
            onChangeDate={updateAsAtDate}
            value={asAtDate || recommendedAsAtDate || ""}
            helperText={calculateAsAtDateHelperText(recommendedAsAtDate)}
            fullWidth
            disabled={disabled || loading}
          />
        )}
      </Component>
    </Grid>
  );
};

export default FileUpload;
