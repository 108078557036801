import { SettingsBackupRestore as ResetIcon } from "@mui/icons-material";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as modelStore from "store/modelDataStore";

const NONE_KEY = "__NONE__";

function AdditionalInfoEditor({ ...componentProps }) {
  const dispatch = useDispatch();
  const modelData = useSelector(modelStore.selectModelData);

  const { data: submissionDefaults } = staticDataService.useConfigQuery(
    "submission_defaults"
  );

  const data = modelData?.additionalInfo ?? {};

  const resetData = () =>
    dispatch(
      modelStore.update({
        ...modelData,
        additionalInfo: {
          ...submissionDefaults?.modelData?.additionalInfo,
        },
      })
    );

  const updateData = (values) =>
    dispatch(
      modelStore.update({
        ...modelData,
        additionalInfo: {
          ...data,
          ...values,
        },
      })
    );

  return (
    <Component
      title={"Modeling Details"}
      options={
        <Disabled ifReadOnly>
          <IconButton
            variant={"bright"}
            icon={ResetIcon}
            onClick={resetData}
            tooltip={"Reset to defaults"}
          />
        </Disabled>
      }
      {...componentProps}
    >
      <Stack
        direction={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        sx={{ width: "100%" }}
        spacing={0.5}
      >
        <FormControlLabel
          label={"EDM from broker?"}
          control={
            <Checkbox
              checked={data?.edmFromBroker ?? false}
              onChange={(e) => updateData({ edmFromBroker: e.target.checked })}
            />
          }
        />
        <FormControlLabel
          label={"Exposures match submission?"}
          control={
            <Checkbox
              checked={data?.exposuresMatchSubmission ?? false}
              onChange={(e) =>
                updateData({ exposuresMatchSubmission: e.target.checked })
              }
            />
          }
        />
        <PrettyNumberTextField
          InputLabelProps={{ shrink: true }}
          label={"Number of Locations"}
          value={data?.numberOfLocations ?? null}
          onChangeNumber={(numberOfLocations) =>
            updateData({ numberOfLocations })
          }
          variant={"filled"}
          fullWidth
        />
        <FormControlLabel
          label={"Storm surge modeled?"}
          control={
            <Checkbox
              checked={data?.stormSurgeModeled ?? false}
              onChange={(e) =>
                updateData({ stormSurgeModeled: e.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label={"Demand surge modeled?"}
          control={
            <Checkbox
              checked={data?.demandSurgeModeled ?? false}
              onChange={(e) =>
                updateData({ demandSurgeModeled: e.target.checked })
              }
            />
          }
        />
        <FormControl variant={"filled"} fullWidth>
          <InputLabel shrink>{"Analysis Type"}</InputLabel>
          <Select
            value={data?.analysisType ?? NONE_KEY}
            onChange={(e) => updateData({ analysisType: e.target.value })}
            fullWidth
          >
            {data?.analysisType == null && (
              <MenuItem value={NONE_KEY}>{"--- Please Select ---"}</MenuItem>
            )}
            <MenuItem value={"DETAILED"}>{"Detailed"}</MenuItem>
            <MenuItem value={"AGGREGATE"}>{"Aggregate"}</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          label={"Non-US exposures?"}
          control={
            <Checkbox
              checked={data?.nonUsExposures ?? false}
              onChange={(e) => updateData({ nonUsExposures: e.target.checked })}
            />
          }
        />
      </Stack>
    </Component>
  );
}

export default AdditionalInfoEditor;
