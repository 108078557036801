import AdditionalInfoEditor from "./AdditionalInfo";
import LossCurves from "./LossCurves";
import PerilsModel from "./PerilsModel";
import RDSContributionEditor from "./RDSContribution";
import StateSummary from "./StateSummary";
import { Grid, Stack } from "@mui/material";
import TextNote from "components/common/TextNote";
import * as config from "config";
import { useSelector, useDispatch } from "react-redux";
import * as modelStore from "store/modelDataStore";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const GROUND_UP_TABLES = [
  {
    key: "total",
    name: "TOTAL - RMS",
  },
  {
    key: "wind_ss",
    name: "WIND + SS RMS",
  },
  {
    key: "quake_ff_sl",
    name: "QUAKE + FF + SL RMS",
  },
  {
    key: "tornado_hail",
    name: "TORNADO + HAIL RMS",
  },
  {
    key: "windstorm",
    name: "WINTERSTORM RMS",
  },
];

const Modeling = () => {
  const dispatch = useDispatch();
  const modelData = useSelector(modelStore.selectModelData);

  const layers = useSelector(pricingSelectors.selectResolvedLayers);

  const updateModelDataById = (id) => (values) => {
    const newModelData = {
      byId: {
        ...modelData?.byId,
        [id]: {
          ...modelData?.byId?.[id],
          ...values,
        },
      },
    };
    dispatch(modelStore.update(newModelData));
  };

  return (
    <>
      <TextNote title={"Notes"} noteKey={"modeling"} />
      <PerilsModel xs_width={12} lg_width={9} />
      <AdditionalInfoEditor xs_width={12} lg_width={3} />
      <Grid item xs={12} sm={6}>
        <Stack direction={"column"} spacing={config.GRID_SPACING}>
          {GROUND_UP_TABLES.map((table) => (
            <LossCurves
              key={table.key}
              id={table.key}
              label={table.name}
              modelDataFacet={modelData?.byId?.[table.key]}
              updateModelDataFacet={updateModelDataById(table.key)}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction={"column"} spacing={config.GRID_SPACING}>
          {(layers ?? []).map((layer) => (
            <LossCurves
              key={layer.id}
              id={layer.id}
              label={utils.shortLayerName(layer)}
              modelDataFacet={modelData?.byId?.[layer.id]}
              updateModelDataFacet={updateModelDataById(layer.id)}
            />
          ))}
        </Stack>
      </Grid>
      <RDSContributionEditor />
      <StateSummary />
    </>
  );
};

export default Modeling;
