import * as support from "./ClaimsTable.support";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { Alert, Box, Button, Checkbox, Grid, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import Options from "components/common/Options";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { connect, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as utils from "utils";

const isExcluded = (exclusions, row) => {
  const excluded = exclusions?.[row.key]?.includes(row.index) || false;
  const noCoverage = row._COVERAGE == null;
  return excluded || noCoverage;
};

const floatAsDollars = (value) => {
  if (value == null) {
    return "";
  }
  return "$" + value.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const useAllClaimsYears = ({ claimsQuery }) => {
  const lossYears = useSelector(
    (state) => state?.input?.claims?.lossYears ?? []
  );
  const { data: summaryCharts } = claimsService.useSummaryChartQuery(
    claimsQuery
  );

  return support.makeClaimsYears({ summaryCharts, lossYears });
};

const ClaimsTable = (props) => {
  const claimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const lossYears = useSelector(
    (state) => state.input?.claims?.lossYears || []
  );
  const {
    data: claims,
    isFetching: isClaimsJsonLoading,
    error: claimsError,
  } = claimsService.useClaimsQuery({
    claimsQuery,
    limit: 100,
    years: lossYears,
    includeExcluded: true,
  });

  const rows = (claims ?? []).map((row, index) => {
    return { id: index, ...row };
  });

  const inMerge = (row) => {
    return props.mergedClaims
      .filter((ki) => ki.key === row.key && ki.index === row.index)
      .reduce(() => true, false);
  };
  const withCoverageList =
    props.createCoverageList &&
    rows.filter((r) => r["_COVERAGE_LIST"] && r["_COVERAGE_LIST"].length > 1)
      .length > 0;
  const DISPLAY_CLAIMS_COLUMNS = [
    {
      field: "CLAIMS_FLAG",
      headerName: "To Merge",
      width: 100,
      renderCell: (params) => {
        return params.row.key === "MANUAL" || inMerge(params.row) ? (
          <LockOutlined sx={{ marginLeft: "10px" }} />
        ) : (
          <Checkbox
            checked={props.mergeEntrySelected(params.row)}
            onChange={(e) =>
              props.setMergeStateEntry(params.row, e.target.checked)
            }
          />
        );
      },
      disableColumnMenu: true,
      hide: !props.mergingClaims,
    },
    {
      field: "_EXCLUDED",
      headerName: " ",
      width: 65,
      renderCell: (params) => {
        const excludedKey = props.excludedKeys.includes(params.row.key);
        const excluded =
          excludedKey || isExcluded(props.exclusions, params.row);
        if (
          props.mergedClaims.filter(
            (mce) =>
              mce.key === params.row.key && mce.index === params.row.index
          ).length === 0
        ) {
          return (
            <Checkbox
              checked={!excluded}
              disabled={excludedKey || params.row._COVERAGE === null}
              onChange={(e) =>
                props.toggleClaimExclude(
                  params.row.key,
                  params.row.index,
                  !e.target.checked
                )
              }
            />
          );
        } else {
          return <LockOutlined sx={{ marginLeft: "10px" }} />;
        }
      },
      disableColumnMenu: true,
      hide: props.mergingClaims,
    },
    {
      field: "_DATE_OF_LOSS",
      headerName: "Date",
      width: 110,
      align: "right",
      headerAlign: "right",
      valueFormatter: (value) => utils.usDateFormat(value),
      disableColumnMenu: true,
    },
    {
      field: "_GROUP_COUNT",
      headerName: "№ Claims",
      align: "center",
      width: 100,
      disableColumnMenu: true,
      hide: !rows?.some((row) => row?._GROUP_COUNT > 1),
    },
    {
      field: "_TOTAL_LOSS",
      headerName: "Reported",
      width: 110,
      align: "right",
      headerAlign: "right",
      valueFormatter: (value) => floatAsDollars(value),
      disableColumnMenu: true,
    },
    {
      field: "_ULTIMATE_INCURRED",
      headerName: "Ultimate",
      width: 110,
      align: "right",
      headerAlign: "right",
      valueFormatter: (value) => floatAsDollars(value),
      disableColumnMenu: true,
    },
    {
      field: "override",
      headerName: "Override",
      width: 160,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <PrettyNumberTextField
              fullWidth
              type={"text"}
              hiddenLabel
              size={"small"}
              variant={"filled"}
              inputProps={{ style: { textAlign: "right" } }}
              onChangeNumber={(x) =>
                props.updateClaimOverride(params.row.key, params.row.index, x)
              }
              allowClear
              value={
                props.overrides?.[params.row.key]?.[params.row.index] ?? ""
              }
            />
          </Box>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "_COVERAGE",
      headerName: "LOB",
      headerAlign: "center",
      align: "center",
      width: withCoverageList ? 120 : 60,
      disableColumnMenu: true,
    },
    {
      field: "_CLAIMANT_NAME",
      headerName: "Claimant",
      flex: 5,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value || ""}>
            <div>{params.value}</div>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "_DESCRIPTION",
      headerName: "Description",
      flex: 15,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value || ""}>
            <div>{params.value}</div>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "_OPEN_CLOSED",
      headerName: "O/C",
      align: "center",
      width: 40,
      disableColumnMenu: true,
    },
    {
      field: "_DATE_CLOSED",
      headerName: "Date Closed",
      width: 120,
      align: "right",
      headerAlign: "right",
      valueFormatter: (value) => {
        return value == null ? null : utils.usDateFormat(value);
      },
      disableColumnMenu: true,
    },
  ].filter((column) => !column.hide);

  const populatedColumnKeys = (claims ?? []).reduce(
    (acc, curr) => new Set([...Object.keys(curr), ...acc]),
    new Set(["override"])
  );

  const columns = DISPLAY_CLAIMS_COLUMNS.filter(
    (column) =>
      column.field === "CLAIMS_FLAG" || populatedColumnKeys.has(column.field)
  );

  const allClaimsYears = useAllClaimsYears({ claimsQuery });

  const yearOptions = allClaimsYears.map((year) => {
    const yearInt = parseInt(year.name);
    const selected = props.lossYears.includes(yearInt);
    return {
      id: yearInt,
      label: year.name,
      tip: `${year.name} (${year.value} losses)`,
      selected: selected,
    };
  });

  const formatCoverage = (row) => {
    if (!withCoverageList) return row;
    if (row["_COVERAGE_LIST"] && row["_COVERAGE_LIST"].length > 1) {
      const comb =
        row["_COVERAGE"] + "(" + row["_COVERAGE_LIST"].join(", ") + ")";
      return { ...row, _COVERAGE: comb };
    } else return row;
  };
  const formattedCoverage = rows.map((r) => formatCoverage(r));
  return (
    <Component
      title={
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item style={{ height: props.mergingClaims ? "80px" : "50px" }}>
            <div style={{ marginTop: "13px" }}>{"Top Claims"}</div>
            {props.mergingClaims ? (
              <Button
                variant={"contained"}
                sx={{ marginTop: "10px" }}
                onClick={props.merge}
                disableElevation
              >
                {"Merge Selected"}
              </Button>
            ) : (
              ""
            )}
          </Grid>

          {isClaimsJsonLoading && (
            <Grid item>
              <DefaultLoader
                color={"#dc7f4c"}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "8px",
                  marginRight: "auto",
                }}
                size={14}
              />
            </Grid>
          )}
        </Grid>
      }
      options={
        <div style={{ maxWidth: "85%" }}>
          <Options
            items={yearOptions}
            onClick={({ id, selected }) => {
              (selected ? props.removeLossYear : props.addLossYear)(id);
            }}
            variant={"button"}
          />
        </div>
      }
    >
      {claimsError ? (
        <Alert severity={"error"}>{String(claimsError)}</Alert>
      ) : (
        <div className={"data-grid"} style={{ height: "670px", width: "100%" }}>
          <DataGrid
            rows={formattedCoverage}
            columns={columns}
            pageSizeOptions={[10, 20, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </div>
      )}
    </Component>
  );
};

const mapDispatchToProps = {
  toggleClaimExclude: claimsActions.toggleClaimExclude,
  updateClaimOverride: claimsActions.updateClaimOverride,
  removeLossYear: claimsActions.removeLossYear,
  addLossYear: claimsActions.addLossYear,
};

const mapStateToProps = (state) => ({
  exclusions: state.input.claims.exclusions || {},
  excludedKeys: claimsSelectors.selectExcludedKeys(state),
  overrides: state.input.claims.overrides || {},
  lossYears: state.input.claims.lossYears || [],
  mergedClaims: claimsSelectors.mergedClaims(state) || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsTable);
