import MDEditor from "@uiw/react-md-editor/nohighlight";
import { insertPhotoImage } from "components/common/notes/commands/Image";
import { useRef } from "react";
import { uriTransformer } from "react-markdown";
import { connect } from "react-redux";
import rehypeSanitize from "rehype-sanitize";
import { uploadImage } from "store/actions/input/images/imagesActions";
import * as tempActions from "store/actions/temp/images/imagesActions";

const Markdown = (props) => {
  const mdRef = useRef();

  const handlePasteImage = (e) => {
    if ((e?.clipboardData?.files?.length ?? 0) === 0) {
      return;
    }
    const commandOrchestrator = mdRef.current?.commandOrchestrator;
    if (commandOrchestrator == null) {
      return;
    }
    const state = commandOrchestrator?.getState();
    const api = commandOrchestrator ? commandOrchestrator?.textApi : undefined;
    if (state == null || api == null) {
      return;
    }
    for (let i = 0; i < e.clipboardData.files.length; i++) {
      insertPhotoImage(props.uploadImage, e.clipboardData.files[i], state, api);
    }
  };

  const handlePaste = (e) => handlePasteImage(e);

  const transformImageUri = (src) => {
    if (src.startsWith("https://marmalade/")) {
      return transformMarmaladeUri(src.slice("https://marmalade/".length));
    }
    return uriTransformer(src);
  };

  const getRemoteImageCacheUrl = ({
    submissionId,
    imageId,
    imageType = "PNG",
  } = {}) => {
    if (props.imageCache[submissionId]?.[imageId] != null) {
      return props.imageCache[submissionId][imageId];
    }
    if (submissionId && imageId) {
      props.cacheImage(submissionId, imageId, imageType);
    }
    return "/loading.gif";
  };

  const transformMarmaladeUri = (src) => {
    let [type, submissionId, imageId] = src.split("/");
    if (
      props.cacheImage == null ||
      props.imageCache == null ||
      props.remoteImages == null ||
      type !== "image"
    ) {
      return "";
    }
    if (submissionId === "local") {
      return getRemoteImageCacheUrl(props.remoteImages[imageId]);
    }
    return getRemoteImageCacheUrl({ submissionId, imageId, imageType: "PNG" });
  };

  return (
    <div data-color-mode={"light"} data-testid={"markdown"}>
      {props.editMode ? (
        <MDEditor
          ref={mdRef}
          autoFocus
          value={props.value}
          onChange={props.onChange}
          onPaste={handlePaste}
          commands={props.commands}
          preview={"edit"}
          previewOptions={{
            transformImageUri,
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      ) : (
        <MDEditor.Markdown
          source={props.value}
          rehypePlugins={[[rehypeSanitize]]}
          transformImageUri={transformImageUri}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  cacheImage: tempActions.cacheImage,
  uploadImage,
};

const mapStateToProps = (state) => {
  return {
    imageCache: state.temp.images?.cache,
    remoteImages: state.input.images?.remote,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Markdown);
