import * as ids from "common/ids";
import * as claimsActionTypes from "store/actions/input/claims/claimsActionTypes";
import * as programTowerActions from "store/actions/input/program/programTowerActions";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as actionTypes from "store/actions/pricing/pricingActionTypes";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as towerPricingActionTypes from "store/actions/pricing/tower/towerPricingActionTypes";
import * as towerPricingActions from "store/actions/pricing/tower/towerPricingActions";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const INIT_STATE = {
  activeLayer: 0,
  assumptions: {},
  baseLayer: {
    limit: 5e5,
    attachment: 5e5,
  },
  recommendedBaseLayer: null,
  layers: [],
  frequency: {
    note: null,
  },
  selectedExposureMetric: {
    key: "REVENUE",
    name: "Revenue",
    unit: "$",
  },
  insuredWeight: 20,
  improvexWeight: 80,
  claimsWeightsProbableError: false,
  towerPricingScalingLayer: 0,
  tower: {
    note: null,
  },
  note: null,
};

const newActiveLayer = (activeLayer, layersLength, removingLayer) => {
  if (activeLayer === layersLength - 1) {
    return layersLength - 2;
  }
  if (activeLayer > removingLayer) {
    return activeLayer - 1;
  }
  return activeLayer;
};

const updateActiveLayer = (state, key, value) => {
  let layers = [...(state.layers ?? [])];
  layers[state.activeLayer] = {
    ...layers[state.activeLayer],
    [key]: value,
  };
  return layers;
};

const updateActiveLayerWithDict = (state, values) => {
  let layers = [...(state.layers ?? [])];
  layers[state.activeLayer] = {
    ...layers[state.activeLayer],
    ...values,
  };
  return layers;
};

const updateActiveLayerWithLambda = (state, modifyActiveLayer) => {
  let layers = [...(state.layers ?? [])];
  layers[state.activeLayer] = modifyActiveLayer(layers[state.activeLayer]);
  return layers;
};

const updateLayer = ({ state, layerId, values }) => ({
  ...state,
  layers: (state.layers ?? []).map((layer) =>
    layer.id === layerId
      ? {
          ...layer,
          ...values,
        }
      : layer
  ),
});

const updateTowerShare = ({ state, layerIndex, shareIndex, values }) => {
  const layers = state.tower?.layers ?? [];
  const layer = layers[layerIndex] ?? {};
  const shares = layer.shares ?? [];
  const share = shares?.[shareIndex] ?? {};

  const newShare = {
    ...share,
    ...values,
  };

  [
    ["share", "shareOfLimit"],
    ["shareOfLimit", "share"],
  ].forEach(([key, keyToClear]) => {
    if (key in values) {
      delete newShare[keyToClear];
      newShare[key] = values[key];
    }
  });

  return {
    ...state,
    tower: {
      ...state.tower,
      selectedLayer: Math.max(state.tower?.selectedLayer ?? 0, 0),
      layers: [
        ...layers.slice(0, layerIndex),
        {
          ...layer,
          shares: [
            ...shares.slice(0, shareIndex),
            newShare,
            ...shares.slice(shareIndex + 1),
          ],
        },
        ...layers.slice(layerIndex + 1),
      ],
    },
  };
};

const getKey = (action) => {
  switch (action.type) {
    case towerPricingActionTypes.UPDATE_TOWER_CARRIER:
      return "carrier";
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_LIMIT:
    case towerPricingActionTypes.UPDATE_TOWER_LIMIT:
      return "limit";
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_ATTACHMENT:
    case towerPricingActionTypes.UPDATE_TOWER_ATTACHMENT:
      return "attachment";
    case towerPricingActionTypes.UPDATE_TOWER_SELECTED:
      return "selectedLayer";
    case towerPricingActionTypes.UPDATE_TOWER_MARKET_GROSS_WRITTEN_PREMIUM:
      return "grossPremium";
    case towerPricingActionTypes.UPDATE_TOWER_PRICING_NOTE:
      return "note";
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_COMMENT:
      return "comment";
    default:
      return undefined;
  }
};

const applyProgramTowerToState = (state, layers) => {
  return {
    ...state,
    layers,
    tower: {
      ...state.tower,
      layers: layers.map((layer) => {
        return {
          limit: layer.limit,
          attachment: layer.attachment,
          grossPremium: layer.grossPremium,
        };
      }),
    },
  };
};

const updateProgramTowerLayer = (state, action) => {
  const { layerIndex, key, value } = action.payload;
  let oldLayers = state.layers ?? [];
  if (layerIndex >= oldLayers.length) {
    oldLayers = [...oldLayers, createNewLayer(null)];
  }
  const layers = [
    ...oldLayers.slice(0, layerIndex),
    {
      ...oldLayers[layerIndex],
      [key]: value,
    },
    ...oldLayers.slice(layerIndex + 1),
  ];
  return applyProgramTowerToState(state, layers);
};

const deleteProgramTowerLayer = (state, action) => {
  const { layerIndex } = action.payload;
  const oldLayers = state.layers ?? [];
  const layers = [
    ...oldLayers.slice(0, layerIndex),
    ...oldLayers.slice(layerIndex + 1),
  ];
  return applyProgramTowerToState(state, layers);
};

const createNewLayer = (state) => {
  const reinsurersConfig =
    staticDataSelectors.selectConfig("reinsurers")(state) ?? {};
  const defaultPaperProvider =
    reinsurersConfig.defaultPaperProvider ??
    reinsurersConfig?.default?.[0] ??
    null;
  const defaultPaperProviderFields = !!defaultPaperProvider
    ? { paperProvider: defaultPaperProvider }
    : {};

  return {
    id: ids.makeNewId(),
    status: "DRAFT",
    pricing: {
      selectedModel:
        staticDataSelectors.selectAnalyticsConfig(state)?.layerPricing
          ?.defaultModel ??
        "UNEXPECTEDLY_THE_SELECTED_PRICING_MODEL_HAS_NOT_BEEN_SET",
    },
    ...defaultPaperProviderFields,
  };
};

const updatePricingInput = ({ state, key, layerId = null, values }) => {
  const pricingInput = state.pricingInputs?.[key];

  const newPricingInput = !!layerId
    ? {
        ...pricingInput,
        layers: {
          ...pricingInput?.layers,
          [layerId]: {
            ...pricingInput?.layers?.[layerId],
            ...values,
          },
        },
      }
    : {
        ...pricingInput,
        ...values,
      };

  return {
    ...state,
    pricingInputs: {
      ...state.pricingInputs,
      [key]: newPricingInput,
    },
  };
};

const convertExpiringLayerToTowerLayer = (layer) => {
  const carrierText = layer.carrier || "";
  const shares = [];

  if (carrierText.includes("/")) {
    carrierText.split("/").forEach((carrier) => {
      shares.push({
        carrier: carrier.trim() || "",
        share: null,
      });
    });
  } else {
    shares.push({
      carrier: carrierText.trim(),
      share: 100,
    });
  }

  return {
    limit: layer.limit,
    attachment: layer.attachment,
    grossPremium: layer.grossPremium,
    shares: shares,
  };
};

const pricingReducer = (state, action) => {
  if (state == null) {
    state = INIT_STATE;
  }

  const key = getKey(action);

  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...Object.fromEntries(
          Object.entries(action.payload?.data?.state?.pricing ?? {}).filter(
            ([k, v]) => !(k === "baseLayer" && v == null)
          )
        ),
      };
    case actionTypes.UPDATE_LAYER:
      return updateLayer({
        ...action.payload,
        state,
      });
    case actionTypes.UPDATE_CLAIMS_DATE_RANGE:
      return {
        ...state,
        claimsDateRange: action.payload,
      };
    case actionTypes.UPDATE_CLAIMS_INCLUDED_LOSS_TYPES:
      return {
        ...state,
        claimsIncludedLossTypes: action.payload,
      };
    case actionTypes.UPDATE_LAYER_COMMENT:
      return {
        ...state,
        layers: updateActiveLayer(state, "comment", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_LIMIT:
      return {
        ...state,
        layers: updateActiveLayer(state, "limit", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_ATTACHMENT:
      return {
        ...state,
        layers: updateActiveLayer(state, "attachment", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_LINE_SIZE_ABSOLUTE:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          lineSize: action.payload,
          shareOfLine: null,
        }),
      };
    case actionTypes.UPDATE_GROSS_PREMIUM:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          grossPremium: action.payload,
          grossPPM: null,
        }),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_GROSS_PPM:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          grossPremium: null,
          grossPPM: action.payload,
        }),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_SHARE_OF_LINE:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          shareOfLine: action.payload,
          lineSize: null,
        }),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_TRIA:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          TRIA: action.payload,
        }),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_SHARE_OF_PREMIUM:
      return {
        ...state,
        layers: updateActiveLayerWithDict(state, {
          shareOfPremium: action.payload,
        }),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_AUTHORIZED_LIMIT:
      return {
        ...state,
        layers: updateActiveLayer(state, "authorizedLimit", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_BROKERAGE:
      return {
        ...state,
        layers: updateActiveLayer(state, "brokerage", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_OTHER_ACQUISITION_COSTS:
      return {
        ...state,
        layers: updateActiveLayer(
          state,
          "otherAcquisitionCosts",
          action.payload
        ),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_PROFIT:
      return {
        ...state,
        layers: updateActiveLayer(state, "profit", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_EXPENSES:
      return {
        ...state,
        layers: updateActiveLayer(state, "expenses", action.payload),
      };
    case pricingActions.setActiveLayerPricingSelectedModel.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer, index) => {
          return index !== state.activeLayer
            ? layer
            : {
                ...layer,
                pricing: {
                  ...layer.pricing,
                  selectedModel: action.payload.model,
                },
              };
        }),
      };
    case pricingActions.setActiveLayerPricingSelectedPrice.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer, index) => {
          return index !== state.activeLayer
            ? layer
            : {
                ...layer,
                pricing: {
                  ...layer.pricing,
                  selectedPrices: {
                    ...layer.pricing.selectedPrices,
                    [action.payload.model]: action.payload.value,
                  },
                },
              };
        }),
      };
    case actionTypes.SET_ACTIVE_LAYER_POLICY_FORM:
      return {
        ...state,
        layers: updateActiveLayer(state, "policyForm", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_REFERENCE:
      return {
        ...state,
        layers: updateActiveLayer(state, "reference", action.payload),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_REINSURANCE:
      return {
        ...state,
        layers: updateActiveLayer(state, "paperProvider", action.payload),
      };
    case actionTypes.UPDATE_ALL_LAYERS_REINSURANCE:
      return {
        ...state,
        layers: (state.layers ?? []).map((layer) => ({
          ...layer,
          paperProvider: action.payload,
        })),
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_PAYMENT_TERMS:
      return {
        ...state,
        layers: updateActiveLayer(
          state,
          "paymentTerms",
          action.payload ?? null
        ),
      };
    case actionTypes.UPDATE_INSURED_WEIGHT:
      const insuredWeight = parseFloat(action.payload);
      return {
        ...state,
        insuredWeight,
        improvexWeight: 100 - insuredWeight,
        claimsWeightsProbableError: 1 >= insuredWeight && insuredWeight > 0,
      };
    case actionTypes.UPDATE_IMPROVEX_WEIGHT:
      const improvexWeight = parseFloat(action.payload);
      return {
        ...state,
        insuredWeight: 100 - improvexWeight,
        improvexWeight,
        claimsWeightsProbableError: 1 >= improvexWeight && improvexWeight > 0,
      };
    case claimsActionTypes.USE_FILTER_AS_BASE_LAYER:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_BASE_LAYER_LIMIT:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          limit: action.payload,
        },
      };
    case actionTypes.UPDATE_BASE_LAYER_FROM:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          from: action.payload,
        },
      };
    case actionTypes.UPDATE_BASE_LAYER_TO:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          to: action.payload,
        },
      };
    case actionTypes.UPDATE_BASE_LAYER_ATTACHMENT:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          attachment: action.payload,
        },
      };
    case actionTypes.UPDATE_BASE_LAYER_EXPECTED_NUMBER_OF_BLENDED_LOSSES:
      return {
        ...state,
        baseLayer: {
          ...state.baseLayer,
          expectedLosses: action.payload,
        },
      };
    case actionTypes.UPDATE_NUMBER_OF_EVENTS_AT_INSURED_IMPACTING_BASE_LAYER:
      return {
        ...state,
        frequency: {
          ...state.frequency,
          underwriterSelectedNumberOfClaims: action.payload,
        },
      };
    case actionTypes.UPDATE_CHOSEN_EXPOSURE_METRIC:
      return {
        ...state,
        selectedExposureMetric: action.payload,
      };
    case actionTypes.REFRESH_ACTIVE_LAYER_PRICING:
      return {
        ...state,
        layers: updateActiveLayer(state, "loadingPurePremium", true),
      };
    case actionTypes.ACTIVE_LAYER_PRICING_RETURNED:
      let layers = [...(state.layers ?? [])];
      let oldLayer = layers[action.payload.query.targetLayer];
      if (oldLayer.loadingPurePremium) {
        layers[action.payload.query.targetLayer] = {
          ...oldLayer,
          pricing: {
            ...oldLayer.pricing,
            prices: action.payload.prices,
            selectedMeasures: action.payload.selectedMeasures,
            selectedModel:
              oldLayer.pricing?.selectedModel in action.payload.prices
                ? oldLayer.pricing?.selectedModel
                : action.payload.defaultModel,
          },
          loadingPurePremium: false,
          hasError: false,
        };
        return {
          ...state,
          layers: layers,
        };
      }
      return state;
    case actionTypes.ACTIVE_LAYER_PRICING_FAILED:
      return {
        ...state,
        layers: [
          ...(state.layers ?? []).slice(0, action.payload.targetLayer),
          {
            ...(state.layers ?? [])[action.payload.targetLayer],
            hasError: true,
            loadingPurePremium: false,
          },
          ...(state.layers ?? []).slice(action.payload.targetLayer + 1),
        ],
      };
    case actionTypes.UPDATE_RECOMMENDED_BASE_LAYER:
      return {
        ...state,
        recommendedBaseLayer: action.payload ?? null,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      const priorState = action.payload.priorState;
      return {
        ...INIT_STATE,
        layers: [createNewLayer(priorState)],
        note:
          staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.pricing?.note ?? null,
        tower: {
          note:
            staticDataSelectors.selectConfig("submission_defaults")(
              action?.payload?.priorState
            )?.pricing?.tower?.note ?? null,
        },
        frequency: {
          note:
            staticDataSelectors.selectConfig("submission_defaults")(
              action?.payload?.priorState
            )?.pricing?.frequency?.note ?? null,
        },
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      const renewedNote = ({
        renewalKey,
        priorValue,
        defaultAction = "drop",
        key = "note",
      }) => {
        const renewalConfig = staticDataSelectors.selectConfig("renewal")(
          action?.payload?.priorState
        );
        const renewalAction =
          renewalConfig?.byKey?.[renewalKey]?.action ?? defaultAction;

        if (!priorValue) {
          return {};
        } else if (renewalAction === "keep") {
          return { [key]: priorValue };
        } else if (renewalAction === "keep_with_renewal_message") {
          return {
            [key]: `**Carried forward from the prior submission:**\n\n${priorValue}`,
          };
        } else {
          return {};
        }
      };

      return {
        ...INIT_STATE,
        activeLayer: state.activeLayer,
        layers: (state.layers ?? []).map(
          ({
            id,
            limit,
            attachment,
            lineSize,
            shareOfLine,
            brokerage,
            otherAcquisitionCosts,
            expenses,
            profit,
            policyForm,
            paymentTerms,
          }) => ({
            renewedFromId: id,
            limit,
            attachment,
            lineSize,
            shareOfLine,
            brokerage,
            otherAcquisitionCosts,
            expenses,
            profit,
            policyForm,
            paymentTerms,
            ...createNewLayer(action.payload.priorState),
          })
        ),
        selectedExposureMetric: state.selectedExposureMetric,
        tower: {
          ...INIT_STATE.tower,
          selectedLayer: Math.max(state.tower?.selectedLayer ?? 0, 0),
          layers: (state.tower?.layers ?? []).map(
            ({ towerPricing, grossPremium, shares, ...tower }) => {
              const renewedTower = { ...tower };
              if (shares) {
                renewedTower["shares"] = shares.map(
                  ({ shareOfGrossPremium, comments, ...share }) => ({
                    ...share,
                    ...renewedNote({
                      key: "comments",
                      renewalKey: "pricing.tower.layers.shares.comments",
                      defaultAction: "keep",
                      priorValue: comments,
                    }),
                  })
                );
              }
              return renewedTower;
            }
          ),
          ...renewedNote({
            renewalKey: "pricing.tower.note",
            priorValue: state.tower?.note,
          }),
        },
        towerPricingScalingLayer: state.towerPricingScalingLayer,
        baseLayer: {
          ...state.baseLayer,
          expectedLosses: null,
        },
        rateChange: state.rateChange,
      };
    case persistenceActionTypes.DUPLICATE_SUBMISSION:
      return {
        ...state,
        layers: (state.layers ?? []).map(({ id, status, ...rest }) => ({
          ...rest,
          id: ids.makeNewId(),
          status: "DRAFT",
        })),
      };
    case actionTypes.RECALCULATING_INSURED_EXPECTED_EVENTS:
      return {
        ...state,
        frequency: {
          ...state.frequency,
          loadingInsuredExpectedEvents: true,
        },
      };
    case actionTypes.RECALCULATED_INSURED_EXPECTED_EVENTS:
      return {
        ...state,
        frequency: {
          ...state.frequency,
          ...action.payload.data,
          request: action.payload.request,
          loadingInsuredExpectedEvents: false,
          loadingInsuredExpectedEventsFailed: false,
        },
      };
    case actionTypes.RECALCULATED_INSURED_EXPECTED_EVENTS_FAILED:
      return {
        ...state,
        frequency: {
          ...state.frequency,
          loadingInsuredExpectedEvents: false,
          loadingInsuredExpectedEventsFailed: true,
        },
      };
    case towerPricingActionTypes.UPDATE_TOWER_LIMIT:
    case towerPricingActionTypes.UPDATE_TOWER_CARRIER:
    case towerPricingActionTypes.UPDATE_TOWER_ATTACHMENT:
    case towerPricingActionTypes.UPDATE_TOWER_MARKET_GROSS_WRITTEN_PREMIUM:
      return {
        ...state,
        tower: {
          ...state.tower,
          selectedLayer: Math.max(state.tower?.selectedLayer ?? 0, 0),
          layers: [
            ...(state.tower?.layers ?? []).slice(0, action.payload.index),
            {
              ...(state.tower?.layers ?? [])[action.payload.index],
              [key]: action.payload[key],
            },
            ...(state.tower?.layers ?? []).slice(action.payload.index + 1),
          ],
        },
      };
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_LIMIT:
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_ATTACHMENT:
    case towerPricingActionTypes.UPDATE_TOWER_PRIMARIES_COMMENT:
      return {
        ...state,
        primaryLayers: {
          ...state.primaryLayers,
          layers: [
            ...(state.primaryLayers?.layers ?? []).slice(
              0,
              action.payload.index
            ),
            {
              ...(state.primaryLayers?.layers ?? [])[action.payload.index],
              [key]: action.payload[key],
            },
            ...(state.primaryLayers?.layers ?? []).slice(
              action.payload.index + 1
            ),
          ],
        },
      };
    case towerPricingActionTypes.ADD_TOWER_PRIMARIES_LAYER:
      return {
        ...state,
        primaryLayers: {
          ...state.primaryLayers,
          layers: [
            ...(state.primaryLayers?.layers ?? []).slice(0, action.payload),
            {},
            ...(state.primaryLayers?.layers ?? []).slice(action.payload),
          ],
        },
      };
    case towerPricingActionTypes.DELETE_TOWER_PRIMARIES_LAYER:
      return {
        ...state,
        primaryLayers: {
          ...state.primaryLayers,
          layers: [
            ...(state.primaryLayers?.layers ?? []).slice(0, action.payload),
            ...(state.primaryLayers?.layers ?? []).slice(action.payload + 1),
          ],
        },
      };
    case towerPricingActionTypes.UPDATE_TOWER_SHARE:
      return updateTowerShare({
        state,
        ...action.payload,
      });
    case towerPricingActionTypes.DELETE_TOWER_SHARE:
      return {
        ...state,
        tower: {
          ...state.tower,
          layers: [
            ...(state.tower?.layers ?? []).slice(0, action.payload.layerIndex),
            {
              ...(state.tower?.layers ?? [])[action.payload.layerIndex],
              shares: [
                ...(
                  (state.tower?.layers ?? [])[action.payload.layerIndex]
                    ?.shares ?? []
                ).slice(0, action.payload.shareIndex),
                ...(
                  (state.tower?.layers ?? [])[action.payload.layerIndex]
                    ?.shares ?? []
                ).slice(action.payload.shareIndex + 1),
              ],
            },
            ...(state.tower?.layers ?? []).slice(action.payload.layerIndex + 1),
          ],
        },
      };
    case towerPricingActionTypes.UPDATE_TOWER_PRICING_NOTE:
    case towerPricingActionTypes.UPDATE_TOWER_SELECTED:
      return {
        ...state,
        tower: {
          ...state.tower,
          [key]: action.payload,
        },
      };
    case towerPricingActionTypes.ADD_TOWER_LAYER:
      return {
        ...state,
        tower: {
          ...state.tower,
          selectedLayer:
            (state.tower?.selectedLayer ?? 0) >= action.payload
              ? (state.tower?.selectedLayer ?? 0) + 1
              : state.tower?.selectedLayer ?? 0,
          layers: [
            ...(state.tower?.layers ?? []).slice(0, action.payload),
            {},
            ...(state.tower?.layers ?? []).slice(action.payload),
          ],
        },
      };
    case towerPricingActionTypes.DELETE_TOWER_LAYER:
      return {
        ...state,
        tower: {
          ...state.tower,
          selectedLayer: newActiveLayer(
            state.tower?.selectedLayer,
            (state.tower?.layers ?? []).length,
            action.payload
          ),
          layers: [
            ...(state.tower?.layers ?? []).slice(0, action.payload),
            ...(state.tower?.layers ?? []).slice(action.payload + 1),
          ],
        },
      };
    case towerPricingActionTypes.UPDATE_TOWER_LAYER:
      return {
        ...state,
        tower: {
          ...state.tower,
          layers: [
            ...(state.tower?.layers ?? []).slice(0, action.payload.index),
            {
              ...state.tower?.layers?.[action.payload.index],
              ...action.payload.values,
            },
            ...(state.tower?.layers ?? []).slice(action.payload.index + 1),
          ],
        },
      };
    case towerPricingActionTypes.COPY_FROM_EXPIRING:
      return {
        ...state,
        tower: {
          ...state.tower,
          layers: (action?.payload ?? []).map(convertExpiringLayerToTowerLayer),
        },
      };
    case towerPricingActions.calculateTowerPricing.started.toString():
      return {
        ...state,
        tower: {
          ...state.tower,
          updatingTowerPricing: true,
        },
      };
    case towerPricingActions.calculateTowerPricing.succeeded.toString():
      return {
        ...state,
        tower: {
          ...state.tower,
          updatingTowerPricing: false,
          error: null,
          layers: (state.tower?.layers ?? []).map((responseLayer, index) => ({
            ...responseLayer,
            towerPricing: action.payload.layers?.[index] ?? {},
          })),
        },
      };
    case towerPricingActions.calculateTowerPricing.failed.toString():
      return {
        ...state,
        tower: {
          ...state.tower,
          updatingTowerPricing: false,
          error: action.payload ?? "Pricing failed",
        },
      };
    case actionTypes.ADD_LAYER:
      return {
        ...state,
        activeLayer: state.layers?.length ?? 0,
        layers: [...(state.layers ?? []), createNewLayer(action.payload.state)],
      };
    case actionTypes.DELETE_LAYER:
      return {
        ...state,
        activeLayer: newActiveLayer(
          state.activeLayer,
          state.layers?.length ?? 0,
          action.payload
        ),
        towerPricingScalingLayer: newActiveLayer(
          state.towerPricingScalingLayer,
          state.layers?.length ?? 0,
          action.payload
        ),
        layers: [
          ...(state.layers ?? []).slice(0, action.payload),
          ...(state.layers ?? []).slice(action.payload + 1),
        ],
      };
    case actionTypes.UPDATE_ACTIVE_LAYER:
      return {
        ...state,
        activeLayer: action.payload,
      };
    case actionTypes.UPDATE_ACTIVE_LAYER_STATUS:
      return {
        ...state,
        layers: updateActiveLayer(state, "status", action.payload),
      };
    case actionTypes.SET_AS_BASE_LAYER_FOR_TOWER_PRICING_SCALING:
      return {
        ...state,
        towerPricingScalingLayer: action.payload,
      };
    case actionTypes.UPDATE_LAYER_STATUS:
      return {
        ...state,
        layers: [
          ...(state.layers ?? []).slice(0, action.payload.index),
          {
            ...(state.layers ?? [])[action.payload.index],
            status: action.payload.status,
          },
          ...(state.layers ?? []).slice(action.payload.index + 1),
        ],
      };
    case actionTypes.UPDATE_LAYER_PRICING_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case actionTypes.UPDATE_FREQUENCY_PRICING_NOTE:
      return {
        ...state,
        frequency: {
          ...state.frequency,
          note: action.payload,
        },
      };
    case programTowerActions.updateValue.toString():
      return updateProgramTowerLayer(state, action);
    case programTowerActions.deleteLayer.toString():
      return deleteProgramTowerLayer(state, action);
    case pricingActions.addPremiumAdjustment.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer) => {
          if (layer.id === action.payload.layerId) {
            return {
              ...layer,
              premiumAdjustments: [
                ...(layer.premiumAdjustments ?? []),
                action.payload.premiumAdjustment,
              ],
            };
          } else {
            return layer;
          }
        }),
      };
    case pricingActions.editPremiumAdjustment.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer) => {
          if (layer.id === action.payload.layerId) {
            return {
              ...layer,
              premiumAdjustments: (layer.premiumAdjustments ?? []).map(
                (premiumAdjustment) => {
                  if (
                    premiumAdjustment.id === action.payload.premiumAdjustmentId
                  ) {
                    return {
                      ...premiumAdjustment,
                      ...action.payload.edits,
                    };
                  } else {
                    return premiumAdjustment;
                  }
                }
              ),
            };
          } else {
            return layer;
          }
        }),
      };
    case pricingActions.deletePremiumAdjustment.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer) => {
          if (layer.id === action.payload.layerId) {
            return {
              ...layer,
              premiumAdjustments: (layer.premiumAdjustments ?? []).filter(
                (premiumAdjustment) =>
                  premiumAdjustment.id !== action.payload.premiumAdjustmentId
              ),
            };
          } else {
            return layer;
          }
        }),
      };
    case pricingActions.deleteActiveLayerCustomOverrides.toString():
      return {
        ...state,
        layers: updateActiveLayerWithLambda(state, (layer) => {
          const { customOverrides, ...restOfLayer } = layer;
          return restOfLayer;
        }),
      };
    case pricingActions.deletePremiumAdjustmentCustomOverrides.toString():
      return {
        ...state,
        layers: (state.layers ?? []).map((layer) => {
          if (layer.id === action.payload.layerId) {
            return {
              ...layer,
              premiumAdjustments: (layer.premiumAdjustments ?? []).map(
                (premiumAdjustment) => {
                  if (
                    premiumAdjustment.id === action.payload.premiumAdjustmentId
                  ) {
                    const { customOverrides, ...rest } =
                      premiumAdjustment ?? {};
                    return rest;
                  } else {
                    return premiumAdjustment;
                  }
                }
              ),
            };
          } else {
            return layer;
          }
        }),
      };
    case actionTypes.UPDATE_PRICING_INPUT:
      return updatePricingInput({
        ...action.payload,
        state,
      });

    case actionTypes.SET_PRICING_ASSUMPTION:
      return {
        ...state,
        assumptions: {
          ...(state.assumptions || {}),
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default pricingReducer;
