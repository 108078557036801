import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FlagSelector from "components/common/FlagSelector";
import Markdown from "components/common/Markdown";

const ExposureFlags = (props) => (
  <Grid item xs={6}>
    <span className={"print-label"}>{"Exposure Flags"}</span>

    <TableContainer>
      <Table size={"small"} className={"flags"}>
        <TableHead>
          <TableRow>
            <TableCell width={"12%"}>{"Type"}</TableCell>
            <TableCell width={"12%"}>{"Category"}</TableCell>
            <TableCell width={"76%"}>{"Comments"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.exposures.map((row, i) => (
            <TableRow key={`EXPOSURES_ROW_${i}`}>
              <TableCell
                sx={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
              >
                {row.type}
              </TableCell>
              <TableCell
                sx={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
              >
                <FlagSelector flag={row} />
              </TableCell>
              <TableCell
                sx={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
              >
                <Markdown value={row.comments} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
);

export default ExposureFlags;
