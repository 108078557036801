import { useEffect, useState } from "react";

export const useLocalStorageStringState = (key, { default: default_ }) => {
  const [internalState, setInternalState] = useState(default_);

  const normalize = (key) => {
    key = key.trim();
    const prefix = "MMM.";
    return key.startsWith(prefix) ? key : prefix + key;
  };

  useEffect(() => {
    const value = localStorage.getItem(normalize(key));
    if (value !== null) {
      setInternalState(value);
    }
  }, [key]);

  const setState = (value) => {
    localStorage.setItem(normalize(key), value);
    setInternalState(value);
  };

  return [internalState, setState];
};

export const useLocalStorageJsonState = (key, { default: default_ }) => {
  const [state, setState] = useLocalStorageStringState(key, {
    default: default_,
  });

  return [JSON.parse(state), (x) => setState(JSON.stringify(x))];
};
