import * as dates from "common/dates";
import * as strings from "common/strings";
import * as config from "config";
import * as programActionTypes from "store/actions/input/program/programActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as searchActionTypes from "store/actions/search/searchActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const INIT_STATE = {
  insured: "",
  riskDescription: "",
  inception: null,
  expiration: null,
  proRataAdjustment: null,
  reference: "",
  naics: null,
  inUS: true,
  turnoverCategory: "E",
  industryClass: null,
  brokerage: null,
  otherAcquisitionCosts: null,
  profit: null,
  expenses: null,
  duns: [],
  policyForms: [],
  insuredAddress: "",
  currency: "USD",
  reassured: null,
  paymentTerms: null,
  notes: null,
  riskSummary: null,
  model: null,
};

const getKey = (action) => {
  switch (action.type) {
    case programActionTypes.ADD_DUNS:
    case programActionTypes.REMOVE_DUNS:
      return "duns";
    default:
      return null;
  }
};

const parseFloatOrNull = (input) => (input != null ? parseFloat(input) : null);

const createInitialState = (priorState) => {
  const defaults = staticDataSelectors.selectConfig("submission_defaults")(
    priorState
  )?.input?.program;
  const out = {
    ...INIT_STATE,
    ...defaults,
    inception: dates.getFirstOfNextMonthStrNoTimeZone(),
    brokerage: defaults?.brokerage ?? 15,
    profit: defaults?.profit ?? 40,
    riskSummary: defaults?.riskSummary ?? null,
    notes: defaults?.notes ?? null,
  };
  if (defaults?.targetTotalExpenses != null) {
    out.targetTotalExpenses = defaults.targetTotalExpenses;
  }
  return out;
};

const programReducer = (state, action) => {
  if (state === undefined) {
    state = {
      ...INIT_STATE,
    };
  }
  const key = getKey(action);
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.program,
      };
    case programActionTypes.UPDATE_PROGRAM:
      return {
        ...state,
        ...action.payload,
      };
    case programActionTypes.UPDATE_DATA_QUALITY:
      return {
        ...state,
        dataQuality: action.payload,
      };
    case programActionTypes.UPDATE_INSURED_NAME:
      return {
        ...state,
        insured: action.payload,
      };
    case programActionTypes.UPDATE_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case programActionTypes.UPDATE_RISK_DESCRIPTION:
      return {
        ...state,
        riskDescription: action.payload,
      };
    case programActionTypes.UPDATE_INCEPTION:
      return {
        ...state,
        inception: strings.setEmptyToNull(action.payload),
      };
    case programActionTypes.UPDATE_EXPIRATION:
      return {
        ...state,
        expiration: strings.setEmptyToNull(action.payload),
      };
    case programActionTypes.UPDATE_PRO_RATA_ADJUSTMENT:
      return {
        ...state,
        proRataAdjustment: action.payload,
      };
    case programActionTypes.UPDATE_REFERENCE:
      return {
        ...state,
        reference: action.payload,
      };
    case programActionTypes.CLEAR_COMPANY_NAICS:
      return {
        ...state,
        companyNaics: undefined,
      };
    case searchActionTypes.SELECT_SEARCH_VALUE:
      switch (action.payload?.searchType) {
        case config.NAICS_SEEKER.key:
          return {
            ...state,
            naics: {
              code: action.payload.selectedValue._CODE,
              title: action.payload.selectedValue._TITLE,
            },
          };
        case config.COMPANY_NAICS_SEEKER.key:
          return {
            ...state,
            companyNaics: {
              code: action.payload.selectedValue._CODE,
              title: action.payload.selectedValue._TITLE,
            },
          };
        case config.CATEGORY_CODE_SEEKER.key:
          return {
            ...state,
            categoryCode: {
              code: action.payload.selectedValue._CODE,
              title: action.payload.selectedValue._TITLE,
            },
          };
        default:
          return state;
      }
    case programActionTypes.UPDATE_WHETHER_IN_US:
      return {
        ...state,
        inUS: action.payload,
      };
    case programActionTypes.UPDATE_TURNOVER:
      return {
        ...state,
        turnoverCategory: action.payload,
      };
    case programActionTypes.UPDATE_INDUSTRY_CLASS:
      return {
        ...state,
        industryClass: action.payload,
      };
    case programActionTypes.UPDATE_BROKERAGE:
      return {
        ...state,
        brokerage: parseFloatOrNull(action.payload),
      };
    case programActionTypes.UPDATE_STATE_OF_FILING:
      return {
        ...state,
        stateOfFiling: action.payload,
      };
    case programActionTypes.UPDATE_REQUESTED_BROKERAGE:
      return {
        ...state,
        requestedBrokerage: parseFloatOrNull(action.payload),
      };
    case programActionTypes.UPDATE_OTHER_ACQUISITION_COSTS:
      return {
        ...state,
        otherAcquisitionCosts: parseFloatOrNull(action.payload),
      };
    case programActionTypes.UPDATE_PROFIT:
      return {
        ...state,
        profit: parseFloatOrNull(action.payload),
      };
    case programActionTypes.UPDATE_EXPENSES:
      return {
        ...state,
        expenses: parseFloatOrNull(action.payload),
      };
    case programActionTypes.UPDATE_INSURED_ADDRESS:
      return {
        ...state,
        insuredAddress: action.payload,
      };
    case programActionTypes.UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case programActionTypes.UPDATE_REASSURED:
      return {
        ...state,
        reassured: action.payload === "" ? null : action.payload ?? null,
      };
    case programActionTypes.UPDATE_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload ?? null,
      };
    case programActionTypes.ADD_DUNS:
      return {
        ...state,
        [key]: [
          ...(state[key] || []).filter((item) => item !== action.payload),
          action.payload,
        ],
      };
    case programActionTypes.UPDATE_PROGRAM_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case programActionTypes.UPDATE_RISK_SUMMARY:
      return {
        ...state,
        riskSummary: action.payload,
      };
    case programActionTypes.REMOVE_DUNS:
      return {
        ...state,
        [key]: (state[key] || []).filter((item) => item !== action.payload),
      };
    case programActionTypes.UPDATE_PRIOR_SUBMISSION:
      return {
        ...state,
        priorSubmission: action.payload,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return createInitialState(action?.payload?.priorState);
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...state,
        priorSubmission: action.payload.priorState.persistence.filename,
        inception:
          state.expiration || dates.getMoveForwardOneYear(state.inception),
        expiration: null,
        reference: "",
      };
    default:
      return state;
  }
};

export default programReducer;
