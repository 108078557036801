import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as analyticsService from "services/analyticsService";
import * as staticDataService from "services/staticDataService";
import * as analyticsStore from "store/analytics";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as submissionSelector from "store/selectors/submission/submissionSelector";

export const usePricing = () => {
  const dispatch = useDispatch();

  const submission = useDebouncedValue(
    useSelector(submissionSelector.selectResolvedSubmissionWithoutInput)
  );

  const { data: analyticsConfig } = staticDataService.useConfigQuery(
    "analytics"
  );

  const out = analyticsService.useSubmissionAnalyticsQuery(
    {
      engine: analyticsConfig?.programPricing?.engine,
      metric: "program_pricing",
      submission,
    },
    {
      skip: !analyticsConfig?.programPricing?.engine,
    }
  );

  const data = out?.data;

  useEffect(() => {
    dispatch(analyticsStore.update({ programPricing: data }));
  }, [data, dispatch]);

  return out;
};
