import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { useState } from "react";

const Tile = ({
  label,
  description,
  onClick,
  selected,
  width = "auto",
  spacing,
}) => {
  return (
    <div
      style={{
        width,
        marginRight: spacing,
        marginTop: spacing,
        maringBottom: spacing,
      }}
    >
      <Button
        onClick={onClick}
        variant={"outlined"}
        className={`tile-options button  ${selected ? "selected" : ""}`}
      >
        <div className={`tile-options${selected ? " selected" : ""}`}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <h3 className={`tile-options h3 ${selected ? "selected" : ""}`}>
              {label}
            </h3>
            {selected && (
              <CheckCircleIcon fontSize={"small"} color={"primary"} />
            )}
          </Stack>
          {description && (
            <p style={{ marginTop: 5, marginBottom: 5 }}>{description}</p>
          )}
        </div>
      </Button>
    </div>
  );
};

const TileOptions = ({
  options,
  onChange = () => {},
  initialActiveId,
  title,
  spacing = 20,
  sx,
}) => {
  const [selected, setSelection] = useState(initialActiveId);

  return (
    <>
      <h2>{title}</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          ...sx,
        }}
      >
        {options.map((option) => (
          <Tile
            key={option.id}
            {...option}
            spacing={spacing}
            selected={option.id === selected}
            onClick={(e) => {
              setSelection(option.id);
              onChange(option.id);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default TileOptions;
