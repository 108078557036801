import CatCoverages from "./CatCoverages";
import Layers from "./Layers";
import NonCatCoverages from "./NonCatCoverages";
import Perils from "./Perils";
import FeatureFlagged from "components/common/FeatureFlagged";
import ActiveLayerEditor from "components/layers/ActiveLayerEditor";
import ActiveLayerSelector from "components/layers/ActiveLayerSelector";

const Layers20240907 = () => (
  <>
    <ActiveLayerSelector
      includedColumnKeys={[
        "delete",
        "status",
        "copyId",
        "shareOfLimit",
        "limit",
        "attachment",
        "grossPremium",
        "grossPPM",
        "shareOfNetPremium",
        "tac",
      ]}
    />
    <ActiveLayerEditor />
  </>
);

const Sublimits = () => {
  return (
    <>
      <Perils />
      <CatCoverages />
      <NonCatCoverages />
      <FeatureFlagged
        flag={"ui/revertCoverageLayer"}
        on={Layers20240907}
        off={Layers}
      />
    </>
  );
};

export default Sublimits;
