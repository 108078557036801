import {
  Send as AllocationIcon,
  Email as MessageIcon,
} from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import * as logger from "common/logger";
import * as time from "common/time";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import PersonSelect from "components/inputs/program/PersonSelect";
import * as config from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as emailService from "services/emailService";
import * as staticDataService from "services/staticDataService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as submissionSelectors from "store/selectors/submission/submissionSelector";

const SuccessAnimation = () => {
  const [isIn, setIsIn] = useState(true);

  useEffect(() => {
    setIsIn(false);
  }, []);

  return (
    <Stack
      direction={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      spacing={6}
      sx={{
        minHeight: 300,
      }}
    >
      <Alert severity={"success"} sx={{ width: "100%" }}>
        {"Sent email"}
      </Alert>
      <Slide direction={"left"} timeout={900} in={isIn} appear={false}>
        <MessageIcon
          color={"success"}
          sx={{
            width: "100px",
            height: "100px",
          }}
        />
      </Slide>
    </Stack>
  );
};

const AllocationDialog = ({ onClose }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [toPerson, setToPerson] = useState(null);
  const [comments, setComments] = useState("");

  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);
  const submissionVersionId = useSelector(
    persistenceSelectors.selectSavedToken
  );
  const resolvedSubmission = useSelector(
    submissionSelectors.selectResolvedSubmissionWithoutInput
  );

  const {
    data: generalConfig,
    isLoading: isGeneralConfigLoading,
  } = staticDataService.useConfigQuery("general_configuration");

  const [
    sendSubmissionAllocationEmail,
    { isLoading: isSendingAllocation },
  ] = emailService.useSendSubmissionAllocationEmailMutation();

  const people = generalConfig?.workflowUsers ?? [];
  const insured = resolvedSubmission?.program?.insured ?? "";
  const reference = (resolvedSubmission?.layers ?? [])
    .map((layer) => layer?.reference)
    .filter((reference) => !!reference)
    .join(", ");

  return (
    <Dialog open maxWidth={"md"} fullWidth>
      <DialogTitle>{"Allocate Submission"}</DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        {isGeneralConfigLoading ? (
          <BigLoader />
        ) : success ? (
          <SuccessAnimation />
        ) : (
          <Stack direction={"column"} spacing={2}>
            <PersonSelect
              style={{ alignSelf: "flex-start" }}
              label={"Allocate To"}
              person={toPerson}
              personList={people}
              onUpdatePerson={setToPerson}
              onUpdatePersonList={() => {}}
              showCompany={false}
              showEmail={false}
              showLabelOnCard={true}
            />
            <TextField
              multiline
              label={"Comments"}
              variant={"filled"}
              InputLabelProps={{ shrink: true }}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              minRows={10}
            />
            {error && <Alert severity={"error"}>{error}</Alert>}
          </Stack>
        )}
      </DialogContent>
      {success || (
        <DialogActions>
          <Button
            onClick={onClose}
            color={"secondary"}
            isDisabled={isSendingAllocation}
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={async () => {
              try {
                setError(null);
                const response = await sendSubmissionAllocationEmail({
                  submissionId,
                  submissionVersionId,
                  recipientEmail: toPerson.email,
                  comments,
                  insured,
                  reference,
                });
                if (response.error) {
                  logger.error(response.error);
                  setError(
                    `Failed to send email. Please try again or contact ${config.SUPPORT_EMAIL}.`
                  );
                } else {
                  setSuccess(true);
                  await time.sleep(1_000);
                  onClose();
                }
              } catch (e) {
                logger.exception(e);
                setError(
                  `Failed to send email. Please try again or contact ${config.SUPPORT_EMAIL}.`
                );
              }
            }}
            color={"primary"}
            startIcon={<AllocationIcon />}
            isLoading={isSendingAllocation}
            isDisabled={!submissionId || !toPerson}
            hasError={!!error}
          >
            {"Allocate"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AllocationDialog;
