import * as time from "../common/time";
import * as config from "../config";
import * as utils from "../utils";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

const baseTablesQuery = async (args, api, extraOptions) => {
  const payload = { ...args.payload };
  if (args.payload?.body) {
    if (!payload.method) payload.method = "POST";
    if (!payload.headers)
      payload.headers = {
        "Content-Type": "application/json",
      };
    payload.body = JSON.stringify(payload.body);
  }
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.tables(args.url),
      payload
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

const awaitCompletion = async (initialResponseData) => {
  const initialResponse = initialResponseData.data;
  const start = new Date();
  let resp = initialResponse;
  const retryPayload = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id: initialResponse.id }),
  };
  while (true) {
    if (new Date() - start > 200000) throw new Error("Tables access timeout");
    switch (resp.responseType) {
      case "INLINE":
        return resp;
      // eslint-disable-next-line no-fallthrough
      case "SLOW_PROCESS_S3_STORED":
      case "LARGE_FILE_S3_STORED":
        switch (resp.status) {
          case "READY":
            const fromS3 = await utils.plainFetch(resp.url);
            return await fromS3.json();
          case "NOT_READY":
            await time.sleep(1000);
            const baseResp = await utils.authenticatedFetch(
              config.endpoints.tables("retrieve-s3-stored-slow-response-data"),
              retryPayload
            );
            resp = await baseResp.json();
            break;
          default:
            throw new Error("Unknown status: " + resp);
        }
        break;
      default:
        throw new Error("Unknown responseType: " + resp);
    }
  }
};

export const tablesApi = createApi({
  reducerPath: "tablesApi",
  baseQuery: baseTablesQuery,
  endpoints: (builder) => ({
    getTableList: builder.query({
      query: (fileId) => ({
        url: "retrieve-table-list",
        payload: {
          body: { id: fileId },
        },
      }),
    }),
    getTableMetadata: builder.query({
      query: (fileId) => ({
        url: "retrieve-table-metadata",
        payload: {
          body: { id: fileId },
        },
      }),
    }),
    getHeaderRow: builder.query({
      query: ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
      }) => ({
        url: "retrieve-header-row",
        payload: {
          body: {
            id: fileId,
            tableName,
            skipColumns,
            skipRows,
            maxColumns,
            maxRows,
            headerRows,
          },
        },
      }),
    }),
    getColumnSummary: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        column,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "retrieve-column-summary",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              column,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        return { data: { ...resp } };
      },
    }),
    getColumnTypeSummary: builder.query({
      query: ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
      }) => ({
        url: "retrieve-column-type-summary",
        payload: {
          body: {
            id: fileId,
            tableName,
            skipColumns,
            skipRows,
            maxColumns,
            maxRows,
            headerRows,
          },
        },
      }),
    }),
    getTablePage: builder.query({
      query: ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        pageSize,
        pageIndex,
      }) => ({
        url: "retrieve-table-page",
        payload: {
          body: {
            id: fileId,
            tableName,
            skipColumns,
            skipRows,
            maxColumns,
            maxRows,
            pageSize,
            pageIndex,
          },
        },
      }),
    }),
    getConversionErrors: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        skipBlankRows,
        mapping,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "retrieve-conversion-errors",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              skipBlankRows,
              mapping,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        return {
          data: {
            fileId: fileId,
            tableName: tableName,
            errors: resp.errors,
            warnings: resp.warnings,
          },
        };
      },
    }),
    doConversion: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        skipBlankRows,
        mapping,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "create-subtable",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              skipBlankRows,
              mapping,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        return {
          data: {
            fileId: fileId,
            createdFile: resp.targetFileId,
            tableName: tableName,
            errors: resp.errors,
            warnings: resp.warnings,
          },
        };
      },
    }),
    getClaimsMapping: builder.query({
      queryFn: async ({
        columnMappings,
        substitutions,
        rowDeletions,
        deletionMappings,
        openClosedMapping,
        openClosedDeleteUnmappedRows,
        openClosedDefault,
        coverageMapping,
        coverageDeleteUnmappedRows,
        coverageDefault,
        excess,
        threshold,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "create-table-mapping",
          payload: {
            body: {
              mappingName: "claims",
              columnMappings,
              substitutions,
              rowDeletions,
              deletionMappings,
              openClosedMapping,
              openClosedDeleteUnmappedRows,
              openClosedDefault,
              coverageMapping,
              coverageDeleteUnmappedRows,
              coverageDefault,
              excess,
              threshold,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        return {
          data: resp.mapping,
        };
      },
    }),
    getClaimsColumns: builder.query({
      query: () => ({
        url: "retrieve-output-columns",
        payload: { body: { mappingName: "claims" } },
      }),
    }),
  }),
});

export const {
  useGetTableListQuery,
  useGetTableMetadataQuery,
  useGetHeaderRowQuery,
  useGetColumnSummaryQuery,
  useGetColumnTypeSummaryQuery,
  useGetTablePageQuery,
  useGetConversionErrorsQuery,
  useDoConversionQuery,
  useGetClaimsMappingQuery,
  useGetClaimsColumnsQuery,
} = tablesApi;
