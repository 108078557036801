export const UPDATE_CLAIM_THRESHOLD = "UPDATE_CLAIM_THRESHOLD";
export const CLAIMS_FILE_DROPPED = "CLAIMS_FILE_DROPPED";
export const CLAIMS_FILE_UPLOADED = "CLAIMS_FILE_UPLOADED";
export const FILE_CLAIMS_FILE_UPLOADED = "FILE_CLAIMS_FILE_UPLOADED";
export const CLAIMS_FILE_UPLOAD_FAILED = "CLAIMS_FILE_UPLOAD_FAILED";
export const SHEET_SELECTED = "SHEET_SELECTED";
export const ACTIVE_SHEET_CHANGED = "ACTIVE_SHEET_CHANGED";
export const FILE_ACTIVE_SHEET_CHANGED = "FILE_ACTIVE_SHEET_CHANGED";
export const SHEET_NAMES_RETURNED = "SHEET_NAMES_RETURNED";
export const FILE_SHEET_NAMES_RETURNED = "FILE_SHEET_NAMES_RETURNED";
export const COLUMN_NAMES_RETURNED = "COLUMN_NAMES_RETURNED";
export const COLUMN_NAMES_REQUESTED = "COLUMN_NAMES_REQUESTED";
export const COLUMN_NAMES_FAILED = "COLUMN_NAMES_FAILED";
export const COLUMN_MAPPING_UPDATED = "COLUMN_MAPPING_UPDATED";
export const CLAIMS_FILE_MAPPING_FAILED = "CLAIMS_FILE_MAPPING_FAILED";
export const EXCLUDED_MAPPING_UPDATED = "EXCLUDED_MAPPING_UPDATED";
export const LOSS_TYPE_MAPPING_UPDATED = "LOSS_TYPE_MAPPING_UPDATED";
export const DOWNSTREAM_FROM_CLAIMS = "DOWNSTREAM_FROM_CLAIMS";
export const UPDATE_CLAIMS_AS_AT_DATE = "UPDATE_CLAIMS_AS_AT_DATE";
export const OPEN_CLOSED_MAPPING_UPDATED = "OPEN_CLOSED_MAPPING_UPDATED";
export const SET_CLAIMS_MAPPING = "SET_CLAIMS_MAPPING";
export const DOWNLOAD_ORIGINAL_CLAIMS_DATA_STARTED =
  "DOWNLOAD_ORIGINAL_CLAIMS_DATA_STARTED";
export const DOWNLOAD_ORIGINAL_CLAIMS_DATA_RETURNED =
  "DOWNLOAD_ORIGINAL_CLAIMS_DATA_RETURNED";
export const DOWNLOAD_ORIGINAL_CLAIMS_DATA_FAILED =
  "DOWNLOAD_ORIGINAL_CLAIMS_DATA_FAILED";
export const CLAIMS_DIALOG_NEXT_STEP = "CLAIMS_DIALOG_NEXT_STEP";
export const CLAIMS_DIALOG_PREV_STEP = "CLAIMS_DIALOG_PREV_STEP";
export const CLAIMS_DIALOG_CLOSED = "CLAIMS_DIALOG_CLOSED";
export const UPDATE_CLAIMS_NOTE = "UPDATE_CLAIMS_NOTE";
export const MANUAL_CREATE_MERGE_ENTRY = "MANUAL_CREATE_MERGE_ENTRY";
export const MANUAL_UPDATE_LOSS_YEAR = "MANUAL_UPDATE_LOSS_YEAR";
export const MANUAL_UPDATE_COVERAGE = "MANUAL_UPDATE_COVERAGE";
export const MANUAL_UPDATE_LOSS = "MANUAL_UPDATE_LOSS";
export const MANUAL_UPDATE_DESCRIPTION = "MANUAL_UPDATE_DESCRIPTION";
export const MANUAL_UPDATE_CLAIMANT = "MANUAL_UPDATE_CLAIMANT";
export const MANUAL_UPDATE_OPEN_CLOSED = "MANUAL_UPDATE_OPEN_CLOSED";
export const MANUAL_UPDATE_DATE_CLOSED = "MANUAL_UPDATE_DATE_CLOSED";
export const DELETE_MANUAL_CLAIMS = "DELETE_MANUAL_CLAIMS";
export const OVERIDE_CLAIM_VALUE = "OVERIDE_CLAIM_VALUE";
export const TOGGLE_CLAIM_EXCLUSION = "TOGGLE_CLAIM_EXCLUSION";
export const CLAIMS_TABLE_ADD_LOSS_YEAR = "CLAIMS_TABLE_ADD_LOSS_YEAR";
export const CLAIMS_TABLE_REMOVE_LOSS_YEAR = "CLAIMS_TABLE_REMOVE_LOSS_YEAR";
export const CLAIMS_FILE_CHECKED = "CLAIMS_FILE_CHECKED";
export const HIDE_CLAIMS_FILE = "HIDE_CLAIMS_FILE";
export const MAPPING_SUGGESTIONS_RETURNED = "MAPPING_SUGGESTIONS_RETURNED";
export const ACTIVE_SHEET_TOP_LEFT_CHANGED = "ACTIVE_SHEET_TOP_LEFT_CHANGED";
export const SHEET_TOP_LEFT_SELECTED = "SHEET_TOP_LEFT_SELECTED";
export const UPDATE_COLUMNS = "UPDATE_COLUMNS";
export const EDIT_CLAIMS_FILE = "EDIT_CLAIMS_FILE";
export const UPDATE_CLAIMS_FILTER = "UPDATE_CLAIMS_FILTER";
export const CLEAR_CLAIMS_FILTER = "CLEAR_CLAIMS_FILTER";
export const USE_FILTER_AS_BASE_LAYER = "USE_FILTER_AS_BASE_LAYER";
export const UPDATE_CLAIMS_ZOOM = "UPDATE_CLAIMS_ZOOM";
export const CLEAR_CHART_ZOOM = "CLEAR_CHART_ZOOM";
export const SET_INFLATION_MODEL = "SET_INFLATION_MODEL";
export const UPDATE_SELF_INSURED_RETENTION = "UPDATE_SELF_INSURED_RETENTION";
export const DELETE_SELF_INSURED_RETENTION = "DELETE_SELF_INSURED_RETENTION";
export const SHOW_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY =
  "SHOW_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY";
export const HIDE_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY =
  "HIDE_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY";
export const CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOADED =
  "CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOADED";
export const START_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD =
  "START_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD";
export const CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD_FAILED =
  "CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD_FAILED";
