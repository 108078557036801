import ComponentSelector from "./ComponentSelector";
import CurrentLayerSelector from "./CurrentLayerSelector";
import InflationSelector from "./InflationSelector";
import Notes from "./Notes";
import OldSummaryTable from "./OldSummaryTable";
import PriorLayerSelector from "./PriorLayerSelector";
import * as support from "./RateChange.support";
import SideBySideClaimsChart from "./SideBySideClaimsChart";
import SummaryTable from "./SummaryTable";
import WaterfallChart from "./WaterfallChart";
import YearOnYearExposures from "./YearOnYearExposures";
import { Alert, Grid, Stack } from "@mui/material";
import * as logger from "common/logger";
import Component from "components/Component";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import Visible from "components/common/Visible";
import ClaimsDeltaChart from "components/inputs/claims/ClaimsDeltaChart";
import * as config from "config";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as analyticsService from "services/analyticsService";
import * as staticDataService from "services/staticDataService";
import * as rateChangeSlice from "store/rateChange";
import * as submissionSelectors from "store/selectors/submission/submissionSelector";

const _useRateChangeComponents = ({ activeLayerRateChange }) => {
  const componentDisabled = activeLayerRateChange.componentDisabled ?? {};

  const inflation = activeLayerRateChange.inflation ?? 0;
  const exposureChanges = support.useActiveLayerExposureChanges();

  const componentDefns = [
    {
      key: "structure",
      name: "Δ Structure",
      metric: { type: "Structure" },
    },
    {
      key: "commission",
      name: "Δ Commission",
      metric: { type: "Commission" },
    },
    {
      key: "exposure",
      name: "Δ Exposure",
      metric: {
        type: "Manual",
        deltaFraction: exposureChanges.totalRatio - 1,
      },
    },
    {
      key: "uwJudgment",
      name: "Δ Judgment",
      metric: { type: "UWJudgment" },
    },
    {
      key: "inflation",
      name: "Δ Inflation",
      metric: {
        type: "Manual",
        deltaFraction: 0.01 * inflation,
      },
    },
    {
      key: "pure",
      name: "Δ Pure",
      metric: { type: "Residual" },
    },
  ];

  const components = componentDefns.filter((x) => !componentDisabled?.[x.key]);

  return components;
};

const useRateChangeTrigger = ({ activeLayerRateChange, engine }) => {
  const {
    data: config,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = staticDataService.useConfigQuery("analytics");

  const [
    trigger,
    { currentData, isFetching, isError },
  ] = analyticsService.useLazyRateChangeAnalyticsQuery();

  const [hasErrorResult, setHasErrorResult] = useState(false);

  const submission = useSelector(
    submissionSelectors.selectResolvedSubmissionWithoutInput
  );

  const components = _useRateChangeComponents({ activeLayerRateChange });

  const dispatch = useDispatch();

  const calculate = () => {
    const engine = config?.rateChange?.engine;
    if (!engine) {
      logger.error("Skipping Rate Change calculation. No engine configured.");
      return;
    }
    const queryData = {
      submission,
      engine: config?.rateChange?.engine,
      args: { components },
    };
    dispatch(
      rateChangeSlice.updateLayerProperties({
        chartData: null,
      })
    );
    setHasErrorResult(false);
    trigger(queryData)
      .unwrap()
      .then((responseData) => {
        if (responseData?.values) {
          dispatch(
            rateChangeSlice.updateLayerProperties({
              chartData: responseData.values,
            })
          );
        } else {
          setHasErrorResult(true);
        }
      })
      .catch(() => {});
  };

  return {
    calculate,
    currentData,
    isFetching: isConfigLoading || isFetching,
    isError: isConfigError || isError || hasErrorResult,
  };
};

const RateChange = ({ rateChange, activeLayerRateChange }) => {
  const { calculate, currentData, isFetching, isError } = useRateChangeTrigger({
    activeLayerRateChange,
  });

  const [claimsComparisonQuery, setClaimsComparisonQuery] = useState({
    primaryMatch: "_DATE_OF_LOSS",
    requiredMatchColumns: ["_COVERAGE", "_CLAIMANT_NAME"],
    matchColumns: ["_DESCRIPTION", "_OPEN_CLOSED"],
    textColumns: [
      "_COVERAGE",
      "_CLAIMANT_NAME",
      "_DESCRIPTION",
      "_OPEN_CLOSED",
    ],
    numericColumns: ["_TOTAL_LOSS"],
  });

  return (
    <Grid item xs={12}>
      <Stack direction={"column"} spacing={config.GRID_SPACING}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={config.GRID_SPACING}
        >
          <CurrentLayerSelector />
          <PriorLayerSelector />
        </Stack>
        {activeLayerRateChange?.layerId &&
          activeLayerRateChange?.selectedPriorLayerId && (
            <>
              <Visible
                byTag={"<RateChange>.summaryTable"}
                defaultVisible={false}
              >
                <SummaryTable />
              </Visible>
              <ComponentSelector />
              {isError || currentData?.error ? (
                <Component
                  title={"Error"}
                  options={
                    <Disabled ifReadOnly>
                      <Button onClick={calculate} isLoading={isFetching}>
                        {"Calculate"}
                      </Button>
                    </Disabled>
                  }
                >
                  <Alert severity={"error"}>
                    {currentData?.failure ??
                      currentData?.error?.message ??
                      `Error calculating rate change. Please contact ${config.SUPPORT_EMAIL}.`}
                  </Alert>
                </Component>
              ) : (
                <>
                  <Visible
                    byTag={"<RateChange>.oldSummaryTable"}
                    defaultVisible={false}
                  >
                    <OldSummaryTable isLoading={isFetching} />
                  </Visible>

                  <WaterfallChart
                    activeLayerRateChange={activeLayerRateChange}
                    calculate={calculate}
                    isLoading={isFetching}
                  />
                </>
              )}
              <YearOnYearExposures />
              <InflationSelector />
              <Visible byTag={"<RateChange>.notes"} defaultVisible={true}>
                <Notes key={rateChange?.activeLayerId ?? null} />
              </Visible>
            </>
          )}
        <Visible byTag={"<RateChange>.sideBySideClaimsChart"} defaultVisible>
          <SideBySideClaimsChart />
        </Visible>
        <Visible byTag={"<RateChange>.claimsDeltaChart"}>
          <ClaimsDeltaChart
            query={claimsComparisonQuery}
            setQuery={setClaimsComparisonQuery}
          />
        </Visible>
      </Stack>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  rateChange: rateChangeSlice.selectRateChange(state),
  activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
});

export default connect(mapStateToProps)(RateChange);
