import {
  KeyboardArrowLeft as PreviousPageIcon,
  KeyboardArrowRight as NextPageIcon,
  SkipPrevious as FirstPageIcon,
  SkipNext as LastPageIcon,
} from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as logger from "common/logger";
import { makeChoicesColDef } from "components/common/dataGrid/choicesColumn";
import { makePrettyNumberColDef } from "components/common/dataGrid/prettyNumberColumn";
import { useState } from "react";

const renderCell = ({ params, column, config }) => {
  const override = config.overrides?.[params.id]?.[column.key] ?? null;

  const tooltip = override ? (
    <Box>
      {`Value: ${params.value}`}
      <br />
      {`Original: ${override.original}`}
    </Box>
  ) : (
    params.value
  );

  const sx = {
    textAlign: ["number", "float", "int"].includes(column.type)
      ? "right"
      : "left",
    ...(column.editable
      ? {
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }
      : {}),
  };

  return (
    <Tooltip title={tooltip} followCursor>
      <Box sx={sx}>{params.formattedValue}</Box>
    </Tooltip>
  );
};

const getClassName = ({ params, column, config }) => {
  const classes = [];

  const severity = config.validations?.[params.id]?.severity;
  if (severity === "error") {
    classes.unshift("error");
  } else if (severity === "warning") {
    classes.unshift("warning");
  }

  const hasFieldError = !!config.validations?.[params.id]?.fieldErrors?.[
    column.key
  ];
  if (hasFieldError) {
    classes.unshift("error-cell");
  }

  const hasOverride = !!config.overrides?.[params.id]?.[column.key];
  if (hasOverride) {
    classes.unshift("overridden");
  }

  return classes.join(" ");
};

const makeColumnDef = (config, column) => {
  switch (column.type) {
    case "int":
    case "float":
      const style =
        column.format?.style === "percent"
          ? {
              suffix: "%",
              dp: column.format?.dp ?? 6,
            }
          : column.format?.style === "currency"
          ? {
              prefix: "$",
              dp: 0,
            }
          : {
              dp: 0,
            };
      return makePrettyNumberColDef({
        field: column.key,
        headerName: column.name,
        width: column.width ?? 150,
        sortable: false,
        editable: column.editable ?? false,
        ...style,
        cellClassName: (params) => getClassName({ params, column, config }),
        renderCell: (params) => renderCell({ params, column, config }),
      });
    case "enum":
      return makeChoicesColDef({
        field: column.key,
        headerName: column.name,
        choices: config?.enum?.enums?.[column.enumKey]?.choices ?? [],
        width: column.width,
        sortable: false,
        cellClassName: (params) => getClassName({ params, column, config }),
        renderCell: (params) => renderCell({ params, column, config }),
      });
    case "string":
    default:
      return {
        type: "text",
        field: column.key,
        headerName: column.name,
        description: column.description,
        sortable: false,
        editable: column.editable,
        width: column.width ?? 150,
        cellClassName: (params) => getClassName({ params, column, config }),
        renderCell: (params) => renderCell({ params, column, config }),
      };
  }
};

const Pagination = ({
  rowCount,
  page,
  setPage,
  pageSize,
  setPageSize,
  pageSizeOptions,
  pageCount,
}) => {
  const activeColour = "#808080";
  const inactiveColour = "#DEDEDE";

  const makeIconStyle = (disabled) =>
    disabled
      ? { color: inactiveColour }
      : {
          color: activeColour,
          ":hover": {
            color: "black",
            cursor: "pointer",
          },
        };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={2}
    >
      <Typography color={activeColour}>{`Total rows: ${rowCount}`}</Typography>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography color={activeColour}>{"Rows per page: "}</Typography>
        <Select
          size={"small"}
          variant={"standard"}
          sx={{ padding: 0.5, paddingTop: 1, marginLeft: 1 }}
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <FirstPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page === 1)}
          onClick={() => setPage(1)}
        />
        <PreviousPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page <= 1)}
          onClick={() => setPage(Math.max(0, page - 1))}
        />
        <Typography color={activeColour}>{`${page} / ${pageCount}`}</Typography>
        <NextPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page >= pageCount)}
          onClick={() => setPage(Math.min(pageCount, page + 1))}
        />
        <LastPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page === pageCount)}
          onClick={() => setPage(pageCount)}
        />
      </Stack>
    </Stack>
  );
};

const SovGrid = ({
  columnsConfig,
  rows,
  overrides,
  validations,
  paginationOptions,
  enumConfig,
  onRowUpdate,
  onCellValueDelete,
}) => {
  const config = {
    columns: columnsConfig,
    rows: rows,
    enum: enumConfig,
    validations,
    overrides,
    onCellValueDelete: onCellValueDelete,
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(paginationOptions.pageSize || 10);
  const pageCount = Math.ceil(rows.length / pageSize);

  const columns = columnsConfig?.map((colConfig) =>
    makeColumnDef(config, colConfig)
  );
  const paginatedRows = rows.slice((page - 1) * pageSize, page * pageSize);

  const handleProcessRowUpdate = async (newRow, oldRow) => {
    if (onRowUpdate) {
      await onRowUpdate(newRow, oldRow);
    }
    return newRow;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={paginatedRows}
        columns={columns}
        sx={{
          p: 0,
          "& .warning": {
            backgroundColor: "#ffddc4",
            color: "#712608",
          },
          "& .error": {
            backgroundColor: "#f5beb7",
            color: "#710808",
          },
          "& .error-cell": {
            backgroundColor: "#de6e6e",
            color: "black",
          },
          "& .overridden": {
            color: "white",
            backgroundColor: "primary.main",
            fontWeight: "bold",
          },
        }}
        pageSize={pageSize}
        rowsPerPageOptions={paginationOptions.pageSizeOptions}
        pagination
        paginationMode={"client"}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={(error) => logger.error(error)}
        slots={{
          pagination: Pagination,
        }}
        slotProps={{
          pagination: {
            rowCount: rows.length,
            page,
            setPage,
            pageSize,
            setPageSize: (pageSize) => {
              setPageSize(pageSize);
              setPage(1);
            },
            pageSizeOptions: paginationOptions.pageSizeOptions,
            pageCount,
          },
        }}
        autoHeight
        disableColumnFilter
        disableColumnSorting
        onCellEditStart={(params, event) => {
          if (params?.reason === "deleteKeyDown") {
            onCellValueDelete(params.id, params.field);
            event.defaultMuiPrevented = true;
            return;
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _ADDRESS: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default SovGrid;
