import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import * as logger from "common/logger";
import { makeRenderCellWithTooltip } from "components/common/dataGrid/cells";
import { makeChoicesColDef } from "components/common/dataGrid/choicesColumn";
import { makePrettyNumberColDef } from "components/common/dataGrid/prettyNumberColumn";
import { useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programPricingStore from "store/programPricing";

const NO_LIMIT_SUBLIMIT_VALUES = ["-", "x", "NA", "N/A"];

const makeColumns = ({
  catExposuresConfig,
  deleteExposure,
  columnEditabilityOverride,
  makePerilName,
  sortable,
}) => {
  return [
    {
      field: "_DESCRIPTION",
      headerName: "Description/Details",
      type: "string",
      sortable,
      editable: true,
      width: 300,
      renderCell: makeRenderCellWithTooltip(),
    },
    makeChoicesColDef({
      field: "_REGION_PERIL",
      headerName: "Region/Peril",
      width: 300,
      choices: catExposuresConfig?.enums?.regionPeril?.choices,
      sortable,
    }),
    makePrettyNumberColDef({
      field: "_SUBLIMIT",
      headerName: "Sublimit",
      width: 200,
      sortable,
      editable: true,
      extraValues: NO_LIMIT_SUBLIMIT_VALUES,
      cellClassName: (params) =>
        params?.row?._OVERRIDE_SUBLIMIT != null ? "overridden" : "",
    }),
    makePrettyNumberColDef({
      field: "_VALUE",
      headerName: "Value",
      width: 200,
      sortable,
      editable: true,
      cellClassName: (params) =>
        params?.row?._OVERRIDE_VALUE != null ? "overridden" : "",
    }),
    makePrettyNumberColDef({
      field: "_EXPIRING_VALUE",
      headerName: "Expiring Value",
      width: 200,
      sortable,
      editable: false,
      cellClassName: "not-editable",
    }),
    {
      field: "_SUBLIMIT_ZONE",
      headerName: "Sublimit Zone",
      type: "string",
      sortable,
      editable: false,
      width: 200,
      valueFormatter: makePerilName,
    },
    makeChoicesColDef({
      field: "_CAT_HAZARD",
      headerName: "Cat Hazard",
      choices: catExposuresConfig?.enums?.catHazard?.choices,
      sortable,
    }),
    makeChoicesColDef({
      field: "_RISK_MANAGEMENT",
      headerName: "Risk Management",
      choices: catExposuresConfig?.enums?.riskManagement?.choices,
      sortable,
    }),
    makePrettyNumberColDef({
      field: "_EXPOSURE_RATE",
      headerName: "GU Exp Rate (%)",
      width: 100,
      dp: 3,
      multiplier: 100,
      sortable,
      editable: true,
      cellClassName: (params) =>
        params?.row?._OVERRIDE_EXPOSURE_RATE != null ? "overridden" : "",
    }),
    makePrettyNumberColDef({
      field: "_ADJUSTED_EXPOSURE_RATE",
      headerName: "Adj Exp Rate (%)",
      width: 100,
      dp: 3,
      multiplier: 100,
      sortable,
      editable: false,
      cellClassName: "not-editable",
    }),
    makePrettyNumberColDef({
      field: "_TOTAL_VALUE",
      headerName: "Total Value",
      width: 200,
      align: "right",
      sortable,
      editable: false,
      cellClassName: "not-editable",
    }),
    makePrettyNumberColDef({
      field: "_FGU_PREMIUM",
      headerName: "FGU Prem",
      width: 200,
      align: "right",
      sortable,
      editable: false,
      cellClassName: "not-editable",
    }),
    {
      field: "actions",
      type: "actions",
      width: 80,
      hide: !deleteExposure,
      getActions: (params) =>
        params?.id != null && !params?.row?.__IS_BLANK__
          ? [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label={"Delete"}
                onClick={() => {
                  deleteExposure(params?.id);
                }}
              />,
            ]
          : [],
    },
  ]
    .filter((column) => !column.hide)
    .map((column) => ({
      ...column,
      editable: columnEditabilityOverride?.[column.field] ?? column.editable,
    }));
};

const CatExposuresGrid = ({
  exposures,
  updateExposure,
  deleteExposure,
  columnEditabilityOverride,
  sortable = false,
  initialSortModel = null,
}) => {
  const { data: catExposuresConfig } = staticDataService.useConfigQuery(
    "cat_exposures"
  );

  const programPricing = useSelector(programPricingStore.select);
  const makePerilName = (peril) =>
    programPricing?.coverageConfig?.perils?.[peril]?.name ?? peril;

  const columns = makeColumns({
    catExposuresConfig,
    deleteExposure,
    columnEditabilityOverride,
    makePerilName,
    sortable,
  });

  return (
    <Box
      sx={{
        width: "100%",
        "& .not-editable": {
          bgcolor: "#EFEFEF",
        },
        "& .overridden": {
          color: "white",
          backgroundColor: "primary.main",
          fontWeight: "bold",
        },
      }}
    >
      <DataGrid
        rows={exposures}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        autoHeight
        onCellEditStart={(params, event) => {
          if (params?.reason === "deleteKeyDown") {
            updateExposure(params?.id)({ field: params?.field, value: null });
            event.defaultMuiPrevented = true;
            return;
          }
        }}
        processRowUpdate={(newRow, oldRow) => {
          Object.entries(newRow)
            .filter(([field, value]) => oldRow?.[field] !== value)
            .map(([field, value]) =>
              field === "_SUBLIMIT" && NO_LIMIT_SUBLIMIT_VALUES.includes(value.toString().trim())
                ? [field, "__NO_SUBLIMIT__"]
                : [field, value]
            )
            .forEach(([field, value]) => {
              updateExposure(newRow.id)({ field, value });
            });
          return newRow;
        }}
        onProcessRowUpdateError={(error) => logger.error(error)}
        initialState={{
          sorting: {
            sortModel: initialSortModel,
          },
          columns: {
            columnVisibilityModel: {
              _SUBLIMIT_ZONE: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default CatExposuresGrid;
