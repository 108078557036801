import React from "react";

class ShortcutListener extends React.Component {
  shortcutActive = (e) => {
    if (e.key !== this.props.shortcut.toLowerCase()) {
      return false;
    }
    const meta =
      navigator.platform.startsWith("Mac") || navigator.platform === "iPad";
    return meta ? e.metaKey : e.ctrlKey;
  };
  handleKeyEvent = (e) => {
    if (!e.repeat) {
      if (this.shortcutActive(e)) {
        if (!this.props.disabled) {
          this.props.action();
        }
        e.preventDefault();
      }
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyEvent);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyEvent);
  }
  render() {
    return null;
  }
}

export default ShortcutListener;
