import RootApp from "./RootApp";
import DefaultLoader from "./common/DefaultLoader";
import OfflineBanner from "./common/OfflineBanner";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "store/actions/user/userActions";
import * as userReducer from "store/reducers/user/userReducer";
import * as userSelectors from "store/selectors/user/userSelectors";

const AuthenticatedApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUserAttributes());
  }, [dispatch]);

  const userAttributesStatus = useSelector(
    (state) => userSelectors.selectUser(state).attributesStatus
  );

  if (userAttributesStatus === userReducer.ATTRIBUTES_STATUS.SUCCEEDED) {
    return <RootApp />;
  } else {
    return (
      <>
        <DefaultLoader
          color={"#dc7f4c"}
          size={100}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <OfflineBanner />
      </>
    );
  }
};

export default withAuthenticator(AuthenticatedApp, { hideSignUp: true });
