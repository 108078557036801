import { signOut } from "aws-amplify/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as userActions from "store/actions/user/userActions";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    signOut().then(() => {
      dispatch(userActions.logoutUser());
      navigate("/", { replace: true });
    });
  }, [dispatch, navigate]);

  return <></>;
};

export default Logout;
