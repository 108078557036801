import * as sagas from "../../../common/sagas";
import * as config from "../../../config";
import * as utils from "../../../utils";
import { handleError, fileOverwritten } from "../../actions/error/errorActions";
import { acceptPendingSubmission } from "../../actions/meta/metaActions";
import * as types from "../../actions/persistence/persistenceActionTypes";
import * as actions from "../../actions/persistence/persistenceActions";
import { setSavedPersistentState } from "../../actions/temp/navigation/navigationActions";
import {
  selectFilename,
  selectPersistedState,
  selectSavedToken,
} from "../../selectors/persistence/persistenceSelectors";
import * as logger from "common/logger";
import { call, put, select, takeLatest } from "redux-saga/effects";

export function* doSaveState(allowOverwriteWarning) {
  try {
    yield put(actions.saveState.started());
    const persistedState = yield select(selectPersistedState);
    const filename = yield select(selectFilename);
    const saved_token = yield select(selectSavedToken);

    const body = {
      state: persistedState,
      filename,
      savedToken: saved_token,
    };
    const url = config.endpoints.persistence("update");
    const method = "put";

    const response = yield sagas.retry(
      call(utils.authenticatedFetch, url, {
        method,
        body: JSON.stringify(body),
      }),
      { maxAttempts: 3, initialDelay: 500 }
    );

    const response_json = yield call([response, response.json]);
    yield put(
      actions.saveState.succeeded({
        savedAt: response_json.savedAt,
        filename: response_json.filename,
        savedBy: response_json.savedBy,
        savedToken: response_json.savedToken,
        overwritten: response_json.overwritten,
      })
    );
    if (response_json.overwritten && allowOverwriteWarning) {
      yield put(
        fileOverwritten(
          response_json.overwritten.writerEmail,
          response_json.overwritten.writerAt
        )
      );
    }
    yield put(setSavedPersistentState(persistedState));
    return true;
  } catch (e) {
    logger.exception(e);
    yield put(actions.saveState.failed());
    yield put(
      handleError(e, {
        title: "Failed to save.",
      })
    );
    return false;
  }
}

export function* saveState() {
  return yield call(doSaveState, true);
}

function* saveStateAndRedirect(action) {
  yield put(acceptPendingSubmission());
  const success = yield call(doSaveState, false);
  if (!success) return false;
  const url = config.endpoints.exchange("accept-transfer-v2");
  yield call(utils.authenticatedFetch, url, {
    body: JSON.stringify({
      submissionId: action.payload.submissionId,
      submissionTransferId: action.payload.submissionTransferId,
    }),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  yield put(actions.acceptedTransmissionSaved(action.payload.submissionId));
  return true;
}

export default function* persistenceSaga() {
  yield takeLatest(actions.saveState.requested, saveState);
  yield takeLatest(types.SAVE_ACCEPTED_TRANSMISSION, saveStateAndRedirect);
}
