import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Notes from "components/common/Notes";
import PeerReviewNote from "components/common/PeerReviewNotes";
import Visible from "components/common/Visible";
import { connect } from "react-redux";
import * as summaryActions from "store/actions/summary/summaryActions";
import { getInsured } from "store/selectors/input/program/programSelectors";
import * as notesSelectors from "store/selectors/notes/notesSelectors";
import { getPeerReviewer } from "store/selectors/review/reviewSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const EditableNote = ({ title, value, context, update }) => (
  <Notes
    title={title}
    value={value}
    update={(text) => update(context, text)}
    rows={10}
    lg_width={6}
    md_width={12}
    growVertically
  />
);

const ReadOnlyNote = ({ title, value, hideIfEmpty }) => {
  if (hideIfEmpty && (!value || value.trim() === "")) {
    return <></>;
  }

  return (
    <Notes
      title={title}
      value={value}
      placeholder={""}
      disabled={true}
      rows={10}
      lg_width={6}
      md_width={12}
      growVertically
    />
  );
};

const SummaryNotes = (props) => {
  const editableNotes = props.notesConfig.filter(
    (note) =>
      note.section === "Summary" &&
      !["considerationsFromLastRenewal", "peerReview"].includes(note.id)
  );

  const additionalNotes = props.notesConfig.filter(
    (note) =>
      note.section === "Additional Notes" &&
      (props.notes?.[note.id] || "").trim() !== ""
  );

  return (
    <>
      {editableNotes.map((note) => (
        <EditableNote
          key={note.id}
          title={note.label}
          value={props.notes?.[note.id] ?? ""}
          context={note.id}
          update={props.updateSummaryNote}
        />
      ))}

      <ReadOnlyNote
        title={"Considerations from Last Renewal"}
        value={props.notes.considerationsFromLastRenewal}
      />
      <Visible byTag={"summary.notes.peerReview"} defaultVisible={true}>
        <PeerReviewNote title={"Peer Reviewer Commentary"} {...props} />
      </Visible>
      {additionalNotes.length > 0 && (
        <>
          <Grid item lg={12}>
            <Typography variant={"h5"}>{"Additional Notes"}</Typography>
          </Grid>

          {additionalNotes.map((note) => (
            <ReadOnlyNote
              key={note.id}
              title={note.label}
              value={props.notes?.[note.id]}
              hideIfEmpty
            />
          ))}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateSummaryNote: summaryActions.updateSummaryNote,
  updateSummaryPeerReviewComment: summaryActions.updateSummaryPeerReviewComment,
  updateSummaryPeerReviewer: summaryActions.updateSummaryPeerReviewer,
  updateSummaryPeerReviewerFromName:
    summaryActions.updateSummaryPeerReviewerFromName,
  updateSummaryPeerReviewDate: summaryActions.updateSummaryPeerReviewDate,
};

const mapStateToProps = (state) => {
  const peerReview = state?.summary?.peerReview ?? {};
  return {
    notesConfig: notesSelectors.selectNotesConfig(state),
    notes: notesSelectors.selectNotes(state),
    peerReview,
    userEmail: userSelectors.selectUser(state).attributes?.email ?? "",
    underwriterList: staticDataSelectors.selectUnderwriterList(state),
    userIsUnderwriter:
      staticDataSelectors.selectUnderwriterFromUser(state) != null,
    underwriterFromUser: staticDataSelectors.selectUnderwriterFromUser(state),
    isUserReadOnly: userSelectors.isUserReadOnly(state),
    peerReviewer: getPeerReviewer(state),
    insured: getInsured(state),
    user: userSelectors.selectUser(state).attributes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryNotes);
