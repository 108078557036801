export const parseFloatTable = (input) => {
  const rows = input.trim().split(/\r?\n/);
  return rows.map((row) =>
    row
      .trim()
      .split(/\s+/)
      .map((cell) => {
        try {
          const value = parseFloat(cell.replace(/,/g, ""));
          return isFinite(value) ? value : null;
        } catch {
          return null;
        }
      })
  );
};
