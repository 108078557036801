import { attachedFilesFlattened } from "../../temp/attachments/tempAttachmentsSelectors";
import * as dates from "common/dates";
import * as config from "config";
import * as claims from "domain/claims";
import { createSelector } from "reselect";
import * as currencySlice from "store/currencySlice";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import { selectSubmissionId } from "store/selectors/persistence/persistenceSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const selectTargetClaimsColumns = createSelector(
  [
    staticDataSelectors.selectConfig("claims_files"),
    staticDataSelectors.selectTranslationsForDomain("claimsFileColumns"),
  ],
  (config, translations) => {
    const columnNames = config?.mappedClaimsColumns ?? [];

    return columnNames.map((columnName) => ({
      display: translations?.[columnName] ?? columnName,
      key: columnName,
    }));
  }
);

export const getClaims = (state) => state.input?.claims ?? null;

const selectFiles = createSelector(
  [getClaims],
  (claims) => claims?.files || []
);

export const getActiveFile = (state) => state.input.claims.activeFile;

const getAsAtDateRaw = (claims) => claims?.asAtDate;
const getRecommendedAsAtDate = (claims) => claims?.recommendedAsAtDate;
export const getAsAtDate = createSelector([getClaims], (claims) => {
  return getAsAtDateRaw(claims) || getRecommendedAsAtDate(claims);
});
export const selectClaimsThreshold = createSelector(
  [getClaims],
  (claims) => claims?.threshold ?? 1e3
);
const selectClaimsFilterRaw = createSelector(
  [getClaims],
  (claims) => claims?.filter
);
export const selectClaimsFilter = createSelector(
  [selectClaimsFilterRaw, selectClaimsThreshold],
  (filter, threshold) => ({
    limit: 1e6,
    from: null,
    to: null,
    ...filter,
    attachment: threshold,
  })
);

const getRawManualClaims = createSelector(
  [getClaims],
  (claims) => claims?.manualClaims || []
);
export const getManualClaims = createSelector(
  [getRawManualClaims],
  (manualClaims) =>
    [...manualClaims, { ...claims.DEFAULT_MANUAL_CLAIMS, isDefault: true }].map(
      (claim) => {
        const { lossYear, coverage, lossAmount } = claim;
        const hasValidLossYear =
          dates.parseDateStringFromDateOrYear(lossYear, {}) != null;
        const hasValidCoverage = coverage != null && coverage !== "";
        const hasValidLossAmount = lossAmount != null;
        const isActive =
          hasValidLossYear && hasValidCoverage && hasValidLossAmount;
        return {
          ...claim,
          hasValidLossYear,
          hasValidCoverage,
          hasValidLossAmount,
          isActive,
        };
      }
    )
);

export const manualClaimsRequest = createSelector(
  [getManualClaims, programSelectors.getInception],
  (manualClaims, inception) => {
    return manualClaims
      .filter(({ isActive }) => isActive)
      .map(
        ({
          lossYear,
          coverage,
          coverageList,
          lossAmount,
          description,
          claimant,
          oc,
          dateClosed,
        }) => ({
          dateOfLoss: dates.parseDateStringFromDateOrYear(lossYear, {
            defaultMMDD: inception == null ? "01-01" : inception.slice(5),
          }),
          incurred: lossAmount,
          coverage,
          coverageList,
          description,
          claimant,
          openClosed: oc,
          dateClosed: dates.parseDateStringFromDateOrYear(dateClosed, {
            defaultMMDD: inception == null ? "01-01" : inception.slice(5),
          }),
        })
      );
  }
);

export const selectExcludedKeys = createSelector([selectFiles], (files) =>
  files.filter((file) => !file.checked).map((file) => file.claimsKey)
);

const selectKeys = createSelector([selectFiles], (files) =>
  files.filter((file) => file.checked).map((file) => file.claimsKey)
);

export const selectInflationModel = (state) =>
  state.input?.claims?.inflationModel ?? null;

export const selectInputClaims = createSelector(
  [
    getClaims,
    programSelectors.selectResolvedProgram,
    getAsAtDate,
    manualClaimsRequest,
    selectKeys,
    selectExcludedKeys,
    selectInflationModel,
    currencySlice.selectResolvedCurrency,
  ],
  (
    claims,
    program,
    asAtDate,
    manualClaims,
    keys,
    excludedKeys,
    inflationModel,
    currency
  ) => ({
    keys,
    inception: program.inception,
    asAt: asAtDate,
    manualClaims,
    exclusions: claims?.exclusions || {},
    overrides: claims?.overrides || {},
    excludedKeys,
    inflationModel,
    currency: program.currency,
    fxRates: currency?.currencies || {},
  })
);

export const isClaimsQueryEmpty = createSelector(
  [selectInputClaims],
  (query) => {
    return claims.isClaimsQueryEmpty(query);
  }
);

const mergeSuggestions = (mapping, suggestions) => {
  if (suggestions === null || suggestions === undefined) {
    return mapping || {};
  }
  return Object.fromEntries(
    Object.entries({
      ...suggestions,
      ...mapping,
    }).filter(([_, value]) => value != null)
  );
};

export const selectActiveFileTopLeft = createSelector(
  [getActiveFile],
  (activeFile) => {
    return activeFile?.topLeft;
  }
);

export const getColumnMappings = createSelector(
  [getActiveFile],
  (activeFile) => {
    if (activeFile == null) return {};
    return mergeSuggestions(
      activeFile.columnMapping,
      activeFile.columnMappingSuggestions
    );
  }
);

export const getClaimsSheet = createSelector([getActiveFile], (activeFile) => {
  if (activeFile == null) {
    return activeFile;
  }
  const {
    uploadedFilename,
    activeSheet,
    topLeft,
    sheets,
    fileId,
    isFilesFile,
  } = activeFile;
  const sheet = sheets[activeSheet];
  if (topLeft) {
    return {
      filename: uploadedFilename,
      sheet,
      skipRows: topLeft.row,
      skipColumns: topLeft.column,
      fileId,
      isFilesFile,
    };
  }
  return { filename: uploadedFilename, sheet };
});

export const getIsFilesFile = (state) =>
  state.input?.claims?.activeFile?.isFilesFile ?? false;

export const getColumnsQuery = createSelector(
  [getClaimsSheet],
  ({ filename, sheet, skipColumns, skipRows }) => {
    return config.endpoints.claims(
      "excel/columns?" +
        new URLSearchParams({
          filename,
          sheet,
          skipColumns,
          skipRows,
        })
    );
  }
);

export const getClaimsTransformationRequest = createSelector(
  [selectSubmissionId, getActiveFile, getClaimsSheet],
  (submissionId, activeFile, claimsSheet) => {
    if (activeFile == null) {
      return activeFile;
    }
    return {
      sheet: claimsSheet,
      "submission-id": submissionId,
      "claims-key": activeFile.claimsKey,
      "column-mapping": mergeSuggestions(
        activeFile.columnMapping,
        activeFile.columnMappingSuggestions
      ),
      "coverage-mapping": mergeSuggestions(
        activeFile.lossTypeMapping,
        activeFile.lossTypeMappingSuggestions
      ),
      "open-closed-mapping": mergeSuggestions(
        activeFile.openClosedMapping,
        activeFile.openClosedMappingSuggestions
      ),
      "excluded-mapping": mergeSuggestions(
        activeFile.excludedMapping,
        activeFile.excludedMappingSuggestions
      ),
      "applied-excess": activeFile?.appliedExcess ?? null,
      "number-formats": {
        _TOTAL_LOSS: activeFile.totalLossNumberFormat,
      },
    };
  }
);

export const selectSumOfSelectedLossTypeCounts = createSelector(
  [getActiveFile],
  (file) => {
    if (file?.lossTypeMapping == null) {
      return undefined;
    }
    if (file.lossTypes == null || file.lossTypesMeta == null) {
      return 0;
    }
    return Object.keys(
      mergeSuggestions(file.lossTypeMapping, file.lossTypeMappingSuggestions)
    )
      .map((key) => file.lossTypesMeta[file.lossTypes.indexOf(key)]?.count ?? 0)
      .reduce((a, b) => a + b, 0);
  }
);

export const selectLossTypeAllRowMapped = createSelector(
  [getActiveFile],
  (file) => {
    return file?.lossTypeMapping?.__ALL__ ?? false;
  }
);

export const selectTotalOfLossTypeCounts = createSelector(
  [getActiveFile],
  (file) => {
    return file?.lossTypesTotal || 1;
  }
);

export const selectLossTypeFraction = createSelector(
  [selectSumOfSelectedLossTypeCounts, selectTotalOfLossTypeCounts],
  (sum, total) => {
    return sum / total;
  }
);

export const selectLossTypeFractionDisplayString = createSelector(
  [selectLossTypeFraction],
  (fraction) => {
    if (fraction == null) {
      return "";
    } else {
      return Math.round(fraction * 100) + "%";
    }
  }
);

export const selectSumOfSelectedOpenClosedCounts = createSelector(
  [getActiveFile],
  (file) => {
    if (file?.openClosedMapping == null) {
      return undefined;
    }
    if (file.openClosed == null || file.openClosedMeta == null) {
      return 0;
    }
    return Object.keys(
      mergeSuggestions(
        file.openClosedMapping,
        file.openClosedMappingSuggestions
      )
    )
      .map(
        (key) => file.openClosedMeta[file.openClosed.indexOf(key)]?.count ?? 0
      )
      .reduce((a, b) => a + b, 0);
  }
);

export const selectTotalOfOpenClosedCounts = createSelector(
  [getActiveFile],
  (file) => {
    return file?.openClosedTotal || 1;
  }
);
export const selectOpenClosedFraction = createSelector(
  [selectSumOfSelectedOpenClosedCounts, selectTotalOfOpenClosedCounts],
  (sum, total) => {
    return sum / total;
  }
);

export const selectOpenClosedFractionDisplayString = createSelector(
  [selectOpenClosedFraction],
  (fraction) => {
    if (fraction == null) {
      return "";
    } else {
      return Math.round(fraction * 100) + "%";
    }
  }
);

export const mergedClaims = createSelector([getRawManualClaims], (claims) =>
  claims.filter((c) => c["mergedFrom"]).flatMap((c) => c["mergedFrom"])
);

const rawSelfInsuredRetentions = (state) =>
  getClaims(state)?.selfInsuredRetentions;

export const selectSelfInsuredRetentions = createSelector(
  [rawSelfInsuredRetentions],
  (retentions) =>
    (retentions || []).map((retention) => ({
      lossType: null,
      value: null,
      notes: null,
      ...retention,
    }))
);

export const selectDownloadFiles = createSelector(
  [selectFiles, attachedFilesFlattened],
  (files, namedFiles) => {
    const filenames = {};
    for (var i in namedFiles) {
      filenames[namedFiles[i].id] = namedFiles[i].name;
    }
    return (files || []).map((file) => {
      const filename = file.isFilesFile
        ? file.uploadedFilename
        : filenames[file.attachment?.fileId] || file.file;
      return {
        sheetName: file.sheets[file.activeSheet],
        uploadedFilename: file.uploadedFilename,
        claimsKey: file.claimsKey,
        checked: file.checked,
        prettyName: filename,
        originalDownloadHasError: file.originalDownloadHasError || false,
        originalDownloading: file.originalDownloading || false,
        isFilesFile: file.isFilesFile,
        fileId: file.fileId || file.attachment?.fileId,
      };
    });
  }
);

export const selectClaimsFileColumnMappingDisplay = (state) => {
  return state?.temp?.claims?.claimsFileColumnMappingDisplay || {};
};

export const sheetSummaries = (state) =>
  state.temp?.claims?.sheetSummaries || [];
