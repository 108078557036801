import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DefaultLoader from "components/common/DefaultLoader";
import { CREATE_CLAIMS_COVERAGE_LIST } from "flags";
import { useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";
import * as utils from "utils";

const floatAsDollars = (value) => {
  if (value == null) {
    return "";
  }
  return "$" + value.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const usDate = (value) => utils.usDateFormat(value);

const getColDefinitions = ({ componentVisibility }) => {
  return [
    {
      field: "_DATE_OF_LOSS",
      headerName: "Date",
      align: "right",
      headerAlign: "right",
      valueFormatter: usDate,
      disableColumnMenu: true,
      flex: 0,
      minWidth: 120,
    },
    {
      field: "_TOTAL_LOSS",
      headerName: "Reported",
      align: "right",
      headerAlign: "right",
      valueFormatter: floatAsDollars,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "_ULTIMATE_INCURRED",
      headerName: "Ultimate",
      align: "right",
      headerAlign: "right",
      valueFormatter: floatAsDollars,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "_COVERAGE",
      headerName: "LOB",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "_CLAIMANT_NAME",
      headerName: "Claimant",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "_DESCRIPTION",
      headerName: "Description",
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: "_OPEN_CLOSED",
      headerName: "O/C",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: "_DATE_CLOSED",
      headerName: "Date Closed",
      align: "right",
      headerAlign: "right",
      disableColumnMenu: true,
      valueFormatter: usDate,
      flex: 0,
      minWidth: 120,
    },
  ].filter(
    (defn) => componentVisibility?.[`<Print>.topClaims.${defn.field}`] ?? true
  );
};

const LoadingOverlay = () => (
  <Box
    sx={{
      position: "relative",
      top: 56,
      display: "block",
      textAlign: "center",
      verticalAlign: "center",
    }}
  >
    <DefaultLoader color={"#dc7f4c"} size={60} />
  </Box>
);

const TopClaims = ({ fullWidth }) => {
  const componentVisibility =
    useSelector(userSelectors.selectUserConfig)?.componentVisibility ?? {};

  const createCoverageList =
    componentVisibility[CREATE_CLAIMS_COVERAGE_LIST] ?? true;

  const claimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const lossYears = useSelector(
    (state) => state.input?.claims?.lossYears || []
  );
  const {
    currentData: claims,
    isFetching: isClaimsLoading,
  } = claimsService.useClaimsQuery({
    claimsQuery,
    limit: 100,
    years: lossYears,
    includeExcluded: false,
  });

  const mapClaims = (x, index) => {
    const res = { ...x, id: index };
    const cl = res["_COVERAGE_LIST"];
    if (createCoverageList && cl && cl.length > 1) {
      res["_COVERAGE"] += "(" + cl.join(", ") + ")";
    }
    return res;
  };

  const topClaims = (claims ?? []).slice(0, 10).map(mapClaims);
  return (
    <div
      style={{
        width: fullWidth ? "100%" : "860px",
        marginBottom: 48,
        display: "block",
      }}
    >
      <span className={"print-label"}>{"Top Claims"}</span>
      <DataGrid
        rows={topClaims}
        columns={getColDefinitions({ componentVisibility })}
        pageSize={10}
        className={"print-claims-table"}
        rowHeight={30}
        autoHeight={true}
        hideFooter={true}
        loading={isClaimsLoading}
        components={{ LoadingOverlay }}
      />
    </div>
  );
};

export default TopClaims;
