import * as submissionsService from "../../services/submissionsService";
import * as transferSelectors from "../../store/selectors/temp/transfer/transferSelectors";
import {
  Delete as DeleteIcon,
  CheckRounded as ConfirmIcon,
  ErrorOutline as WarningIcon,
  MoreVert as MoreIcon,
  RestoreFromTrash as RestoreIcon,
  CopyAllRounded as CopyIcon,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import * as dates from "common/dates";
import Disabled from "components/common/Disabled";
import Visible from "components/common/Visible";
import AcceptTransferDialog from "components/transfer/AcceptTransferDialog";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as exchangeService from "services/exchangeService";
import * as metaActions from "store/actions/meta/metaActions";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as metaSelectors from "store/selectors/meta/metaSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const ActionMenu = ({
  submissionId,
  isDeleted,
  restoreSubmission,
  deleteSubmission,
}) => {
  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"menu"}>
      <IconButton
        ref={anchorRef}
        onClick={() => setIsOpen(!isOpen)}
        variant={"text"}
        className={"toggle"}
        style={{ marginLeft: "-15px" }}
      >
        <MoreIcon className={""} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={(_, reason) => {
          if (reason === "backdropClick") setIsOpen(!isOpen);
        }}
      >
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(submissionId);
            setIsOpen(!isOpen);
          }}
        >
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
          <ListItemText>{"Copy Submission ID"}</ListItemText>
        </MenuItem>

        <Divider />

        <Disabled ifReadOnly>
          <MenuItem
            onClick={() => {
              isDeleted ? restoreSubmission() : deleteSubmission();
              setIsOpen(false);
            }}
          >
            <ListItemIcon>
              {isDeleted ? <RestoreIcon /> : <DeleteIcon />}
            </ListItemIcon>
            <ListItemText>
              {isDeleted ? "Restore" : "Delete"} {"Submision"}
            </ListItemText>
          </MenuItem>
        </Disabled>
      </Menu>
    </div>
  );
};

const TransferMenu = ({ onAccept, alreadySaved }) => {
  const { data: exchangeConfig } = exchangeService.useClientConfigQuery();

  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);

  const showTransferAcceptanceDialog =
    exchangeConfig?.acceptanceWorkflow?.showDialog ?? false;

  return (
    <>
      <div
        className={"item"}
        style={{
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          disableElevation
          fullWidth
          disabled={!exchangeConfig}
          sx={{
            height: "2.5em",
          }}
          onClick={() => {
            if (!showTransferAcceptanceDialog) onAccept();
            setIsAcceptDialogOpen(true);
          }}
          startIcon={alreadySaved ? <WarningIcon /> : <ConfirmIcon />}
        >
          {alreadySaved ? "Overwrite" : "Accept"}
        </Button>
      </div>
      <AcceptTransferDialog
        open={isAcceptDialogOpen}
        alreadySaved={alreadySaved}
        showTransferAcceptencaDialog={showTransferAcceptanceDialog}
        onAccept={onAccept}
        onClose={() => setIsAcceptDialogOpen(false)}
      />
    </>
  );
};

const Reference = ({ programRef, layerRefs }) => {
  const ref =
    programRef ||
    layerRefs
      ?.filter((x) => x != null)
      ?.filter((value, index, self) => self.indexOf(value) === index)
      ?.join(", ");

  return ref ? (
    <div className={"item padding"}>
      <span className={"label"}>{"Ref:"}</span>
      <span className={"value"}>{ref}</span>
    </div>
  ) : null;
};

const ActiveSubmission = ({
  isDeleted,
  pending,
  overlay,
  program,
  layers,
  source,
  submissionId,
  deleteSubmission,
  undeleteSubmission,
  saveTransferInProgress,
  saveState,
  saveAcceptedTransmission,
  transferCleanup,
  saveTransferCompleted,
  isFullWidth,
  hide = false,
}) => {
  const shortId = (overlay?.submissionTransferId ?? "").slice(0, 8);
  const createTime = overlay?.submissionTransfer?.createTime ?? null;
  const senderEmail = overlay?.submissionTransfer?.sender?.user?.email ?? null;

  const navigate = useNavigate();

  const { data: versionList } = submissionsService.useListVersionsQuery(
    { submissionId: submissionId },
    { skip: !submissionId }
  );

  const submissionExists = versionList && versionList.length;

  const [navigated, setNavigated] = useState(false);

  const doNavigate = !navigated && saveTransferCompleted;
  useEffect(() => {
    if (doNavigate) {
      setNavigated(true);
      transferCleanup();
      navigate(`/submissions/${submissionId}`);
    }
  }, [setNavigated, navigate, doNavigate, transferCleanup, submissionId]);

  const statusChipLabel = !!pending
    ? submissionExists
      ? "ACCEPTED"
      : "PENDING"
    : "DELETED";
  const statusChipColour = !!pending ? "primary" : "error";

  const doAccept = () => {
    saveAcceptedTransmission(submissionId, pending);
  };

  return hide ? (
    <></>
  ) : (
    <div className={"active-submission-wrapper"}>
      {overlay?.type === "FromTransfer" && (
        <Alert
          severity={"info"}
          sx={{
            marginLeft: 2,
            marginRight: 2,
            marginBottom: 4,
          }}
        >
          {!!createTime || !!senderEmail
            ? `You are viewing the version ${shortId} of this submission, sent at ${
                createTime ? dates.formatDateTime(createTime) : "unknown time"
              } by ${senderEmail ?? "unknown sender"}`
            : `You are viewing the version ${shortId} of this submission`}
          <br />
          <br />
          <Link href={`/submissions/${submissionId}`} target={"_blank"}>
            {"Open the Live Submission"}
          </Link>
        </Alert>
      )}
      <div className={isFullWidth ? "container full-width" : "container"}>
        <div
          className={"company-name sentry-unmask"}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={"company-name"}>
            <span>
              {program.insured || "Untitled"}
              {isDeleted && (
                <Chip
                  label={statusChipLabel}
                  color={statusChipColour}
                  size={"small"}
                  sx={{ marginLeft: "8px", color: "white" }}
                />
              )}
            </span>
          </div>
          {(isDeleted && !!pending) || saveTransferInProgress ? (
            <TransferMenu
              alreadySaved={!!submissionExists}
              onAccept={doAccept}
            />
          ) : (
            <ActionMenu
              submissionId={submissionId}
              isDeleted={isDeleted}
              restoreSubmission={() => {
                undeleteSubmission();
                saveState();
              }}
              deleteSubmission={() => {
                deleteSubmission();
                saveState();
              }}
            />
          )}
        </div>
        <div className={"info"}>
          <div className={"item padding"}>
            <span className={"label"}>{"Incept:"}</span>
            <span className={"value"} style={{ whiteSpace: "nowrap" }}>
              {program.inception}
            </span>
          </div>
          <Reference
            programRef={program?.reference}
            layerRefs={layers?.map((layer) => layer?.reference)}
          />
          <Visible byTag={"<ActiveSubmission>.source"}>
            {source && (
              <div className={"item padding has-icon"}>
                <WarningIcon
                  color={"info"}
                  fontSize={"small"}
                  sx={{ marginRight: 0.5 }}
                />
                <span className={"label"}>{"Source:"}</span>
                <span className={"value"}>{source}</span>
              </div>
            )}
          </Visible>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isDeleted: metaSelectors.selectIsDeleted(state),
    pending: metaSelectors.selectPending(state),
    overlay: metaSelectors.selectOverlay(state),
    program: programSelectors.selectResolvedProgram(state),
    layers: pricingSelectors.selectLayers(state),
    source: metaSelectors.selectSource(state),
    submissionId: persistenceSelectors.selectSubmissionId(state),
    saveTransferInProgress: transferSelectors.saveTransferInProgress(state),
    saveTransferCompleted: transferSelectors.saveTransferCompleted(state),
  };
};

const mapDispatchToProps = {
  transferCleanup: persistenceActions.transferCleanup,
  deleteSubmission: metaActions.deleteSubmission,
  undeleteSubmission: metaActions.undeleteSubmission,
  saveState: persistenceActions.saveState.requested,
  saveAcceptedTransmission: persistenceActions.saveAcceptedTransmission,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSubmission);
