import DeleteIcon from "@mui/icons-material/Delete";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import NotesIcon from "@mui/icons-material/TextSnippetRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import ChangeArrow from "components/common/ChangeArrow";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import InlineSearch from "components/common/InlineSearch";
import MetricsComparison from "components/common/MetricsComparison";
import Notes from "components/common/Notes";
import Options from "components/common/Options";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import Visible from "components/common/Visible";
import * as exposureSupport from "components/inputs/exposure/Exposure.support";
import IndustryClass from "components/inputs/program/IndustryClass";
import * as _ from "lodash";
import { useState, Fragment, memo, useMemo } from "react";
import { connect } from "react-redux";
import * as exposureActions from "store/actions/input/exposure/exposureActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const matchesSearch = (candidate, searchTerm) => {
  const lowercaseCandidate = candidate.toLowerCase();
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const modifiedSearchTerm = lowercaseSearchTerm
    .replace("_", " ")
    .replace("#", "number")
    .replace(/ave(r?a?g?e?)/, "avg")
    .replace("semis", "semi's")
    .replace(/centr(e?s?)/, "centers");
  return lowercaseCandidate.includes(modifiedSearchTerm);
};

const MetricsSelector = ({ list, exposureData, onClick }) => {
  const [exposureFilter, setExposureFilter] = useState("");
  const searchOptions = (option) => {
    return matchesSearch(option.label, exposureFilter);
  };
  const flattenedData = Object.values(exposureData).reduce((acc, dict) => {
    return {
      ...acc,
      ...dict,
    };
  }, {});

  const options = list.map((metric) => {
    return {
      id: metric.key,
      label: metric.name,
      selected: metric.selected,
      faded: flattenedData[metric.key] ? true : false,
    };
  });

  return (
    <Component
      lg_width={6}
      title={"Tracked Exposure Metrics"}
      subtitle={
        "Select which metrics to track for this insured. Metrics shared across this industry class cannot be unselected."
      }
    >
      <InlineSearch
        placeholder={"Quick Search"}
        onChange={(searchText) => setExposureFilter(searchText)}
        sx={{ marginBottom: "2rem" }}
        enableTrimming={false}
        disabled={true}
      />

      <Options
        items={options.filter(searchOptions)}
        onClick={({ id }) => {
          const item = list.find((x) => x.key === id);
          onClick(item);
        }}
      />
    </Component>
  );
};

const ExposureRow = memo(
  ({
    row,
    rowData,
    comment,
    years,
    customKeys,
    updateValue,
    updateComment,
    toggleExposureMetric,
    isShowingComment,
    hideExposureComment,
    showExposureComment,
    isExposureFromTemplate,
  }) => {
    return (
      <Fragment key={row.key}>
        <TableRow>
          <TableCell className={"eye-column"}>
            {customKeys.filter(
              (key) => key.name === row.name && key.units === row.units
            ).length === 0 &&
              row.key !== "REVENUE" && (
                <IconButton
                  icon={VisibilityOffIcon}
                  onClick={() => {
                    toggleExposureMetric({
                      ...row,
                      fromTemplate: isExposureFromTemplate,
                      selected: true,
                    });
                  }}
                />
              )}
          </TableCell>
          <TableCell className={"name-column"} scope={"row"}>
            <Grid container alignItems={"center"}>
              <Typography variant={"subtitle1"}>{row.name}</Typography>
              {!!comment && (
                <IconButton
                  variant={"bright"}
                  scale={"small"}
                  icon={NotesIcon}
                  onClick={
                    isShowingComment(row.key)
                      ? () =>
                          hideExposureComment({
                            key: row.key,
                          })
                      : () =>
                          showExposureComment({
                            key: row.key,
                          })
                  }
                />
              )}
            </Grid>
            {row.key === "REVENUE" ? (
              <Visible byTag={"input.exposure.revenueRequired"}>
                <div>
                  <span
                    style={{
                      background: "#FFCFCF",
                      color: "#710808",
                      display: "inline-block",
                      letterSpacing: "0.2px",
                      fontSize: "11px",
                      padding: "1px 4px",
                      borderRadius: "4px",
                    }}
                  >
                    {"Required"}
                  </span>
                </div>
              </Visible>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell className={"units-column"} align={"right"}>
            <Typography variant={"subtitle2"}>{row.units}</Typography>
          </TableCell>

          {years.map((year) => {
            return (
              <Fragment key={`${row.key}-${year}-fragment`}>
                <TableCell>
                  <PrettyNumberTextField
                    type={"text"}
                    id={`${row.key}-${year}-pntf`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ style: { textAlign: "right" } }}
                    hiddenLabel
                    size={"small"}
                    variant={"filled"}
                    onChangeNumber={(x) => {
                      updateValue(year, row.key, x);
                    }}
                    value={rowData?.[year] ?? null}
                    fullWidth
                  />
                </TableCell>
                <TableCell className={"arrow-column"}>
                  <ChangeArrow
                    currentValue={rowData?.[year] ?? null}
                    priorValue={rowData?.[year - 1] ?? null}
                  />
                </TableCell>
              </Fragment>
            );
          })}
        </TableRow>
        {isShowingComment(row.key) && (
          <TableRow>
            <TableCell className={"eye-column"} />
            <TableCell className={"name-column"} />
            <TableCell className={"units-column"} />
            <TableCell colSpan={7} className={"notes-column"}>
              <Notes
                title={null}
                update={(comment) =>
                  updateComment({
                    key: row.key,
                    value: comment,
                  })
                }
                value={comment ?? ""}
                hasEditButton={false}
              />
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  },
  _.isEqual
);

export const ExposureComponent = (props) => {
  const [isChartsOpen, setIsChartsOpen] = useState(false);
  const trendData = exposureSupport.getExposureTrendData(
    props.rows,
    props.data
  );

  const exposureKeyToFromTemplateMapping = useMemo(
    () =>
      Object.fromEntries(
        (props.toggleList ?? []).map((defn) => [defn.key, defn.fromTemplate])
      ),
    [props.toggleList]
  );

  return (
    <>
      {isChartsOpen && (
        <MetricsComparison
          open={true}
          trendData={trendData}
          chartData={exposureSupport.getExposureChartData(props.data)}
          onClose={() => setIsChartsOpen(false)}
        />
      )}
      <Component
        title={"Historical Exposure"}
        subtitle={
          <Visible byTag={"input.exposure.revenueRequired"}>
            {
              "Revenue is required to calculate the Model average loss frequency. The Insured average loss frequency can be calculated using any of the exposure metrics."
            }
          </Visible>
        }
      >
        {props.rowsUpdating ? (
          <DefaultLoader
            color={"#dc7f4c"}
            style={{
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            size={60}
          />
        ) : props.industryClass ? (
          <>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  props.addExposureYear(Math.min(...props.years));
                }}
                sx={{ color: "primary.main" }}
              >
                {"Add Year"}
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={
                  props.commentVisibility.defaultVisible
                    ? props.hideAllExposureComments
                    : props.showAllExposureComments
                }
                sx={{ color: "primary.main" }}
              >
                {props.commentVisibility.defaultVisible
                  ? "Hide Notes"
                  : "Show Notes"}
              </Button>
              <span>
                <IconButton
                  variant={"bright"}
                  icon={QueryStatsRoundedIcon}
                  tooltip={"Compare Exposures"}
                  onClick={() => setIsChartsOpen(true)}
                  isDisabled={trendData.length === 0}
                />
              </span>
            </Stack>
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table
                className={"exposure-inputs"}
                size={"small"}
                stickyHeader={true}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={"eye-header"} />
                    <TableCell className={"name-header"}>{"Measure"}</TableCell>
                    <TableCell align={"right"} className={"unit-header"}>
                      {"Unit"}
                    </TableCell>
                    {props.years.map((year) => (
                      <Fragment key={`EXPOSURE_ARROW_HEADER_${year}`}>
                        <TableCell className={"years-header"} align={"right"}>
                          {(props.labels[year] ?? []).includes("projected")
                            ? "Projected"
                            : year.toString()}
                        </TableCell>
                        <TableCell className={"years-header"}></TableCell>
                      </Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.map((row) => (
                    <ExposureRow
                      row={row}
                      rowData={Object.fromEntries(
                        Object.entries(props.data).map(([k, v]) => [
                          k,
                          v[row.key],
                        ])
                      )}
                      comment={props.comments?.[row.key]}
                      years={props.years}
                      customKeys={props.customKeys}
                      toggleExposureMetric={props.toggleExposureMetric}
                      isShowingComment={props.isShowingComment}
                      hideExposureComment={props.hideExposureComment}
                      showExposureComment={props.showExposureComment}
                      updateValue={props.updateValue}
                      updateComment={props.updateComment}
                      isExposureFromTemplate={
                        exposureKeyToFromTemplateMapping[row.key] ?? false
                      }
                      key={row.key}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography>{"Please select an industry class."}</Typography>
        )}
      </Component>
      <Grid item xs={12} lg={6}>
        <IndustryClass componentProps={{ growVertically: true }} />
      </Grid>
      <Notes
        md_width={12}
        lg_width={6}
        update={props.updateNote}
        value={props.note}
      />
      <MetricsSelector
        list={props.toggleList}
        exposureData={props.data}
        onClick={props.toggleExposureMetric}
      />
      <Component
        lg_width={6}
        title={"Custom Exposure Metrics"}
        subtitle={
          "Add any exposure metrics you would like to track for this risk only."
        }
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{"Measure"}</TableCell>
                <TableCell>{"Unit"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.customKeys
                .filter((item) => !item.deleted)
                .map((item) => {
                  return (
                    <TableRow key={`CUSTOM_KEY_ROW_${item.key}`}>
                      <TableCell>
                        {!item.newExposure && (
                          <IconButton
                            scale={"small"}
                            icon={DeleteIcon}
                            onClick={() => {
                              props.deleteCustomMeasure(item.key);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          type={"text"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          hiddenLabel
                          autoComplete={"off"}
                          size={"small"}
                          variant={"filled"}
                          onChange={(e) =>
                            props.updateCustomMeasureName(
                              item.key,
                              e.target.value
                            )
                          }
                          value={item.name}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type={"text"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          hiddenLabel
                          autoComplete={"off"}
                          size={"small"}
                          variant={"filled"}
                          onChange={(e) =>
                            props.updateCustomMeasureUnit(
                              item.key,
                              e.target.value
                            )
                          }
                          value={item.units}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Component>
    </>
  );
};

const mapDispatchToProps = {
  updateValue: exposureActions.updateValue,
  updateNote: exposureActions.updateExposureNote,
  updateComment: exposureActions.updateExposureComment,
  showAllExposureComments: exposureActions.showAllExposureComments,
  hideAllExposureComments: exposureActions.hideAllExposureComments,
  showExposureComment: exposureActions.showExposureComment,
  hideExposureComment: exposureActions.hideExposureComment,
  toggleExposureMetric: exposureActions.toggleExposureMetric,
  updateCustomMeasureUnit: exposureActions.updateCustomMeasureUnit,
  updateCustomMeasureName: exposureActions.updateCustomMeasureName,
  deleteCustomMeasure: exposureActions.deleteCustomMeasure,
  addExposureYear: exposureActions.addExposureYear,
};

const mapStateToProps = (state) => {
  return {
    inception: state?.input?.program?.inception ?? null,
    rows: exposureSelectors.getExposureList(state),
    data: exposureSelectors.getData(state),
    rowsUpdating: exposureSelectors.isExposureListUpdating(state),
    toggleList: exposureSelectors.getExposureToggleList(state),
    customKeys: exposureSelectors.getCustomKeysToDisplay(state),
    industryClass: state?.input?.program?.industryClass ?? null,
    note: state?.input?.exposure?.note || "",
    comments: state?.input?.exposure?.comments ?? {},
    commentVisibility: state?.input?.exposure?.commentVisibility ?? {},
    years: exposureSelectors.getExposuresYearsToDisplay(state),
    labels: exposureSelectors.getExposuresLabels(state),
    isShowingComment: exposureSelectors.selectIsShowingCommentFunction(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExposureComponent);
