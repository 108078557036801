import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

export const DUMMY_TRIANGLE = {
  losses: [
    // 2016
    { inception: 2016, ageMonths: 12, value: 154.0 },
    { inception: 2016, ageMonths: 24, value: 152.5 },
    { inception: 2016, ageMonths: 36, value: 153.4 },
    { inception: 2016, ageMonths: 48, value: 153.8 },
    { inception: 2016, ageMonths: 60, value: 157.2 },
    { inception: 2016, ageMonths: 72, value: 156.5 },
    { inception: 2016, ageMonths: 84, value: 153.8 },
    // 2017
    { inception: 2017, ageMonths: 12, value: 151.1 },
    { inception: 2017, ageMonths: 24, value: 152.6 },
    { inception: 2017, ageMonths: 36, value: 148.6 },
    { inception: 2017, ageMonths: 48, value: 148.2 },
    { inception: 2017, ageMonths: 60, value: 149.0 },
    { inception: 2017, ageMonths: 72, value: 150.7 },
    // 2018
    { inception: 2018, ageMonths: 12, value: 177.4 },
    { inception: 2018, ageMonths: 24, value: 184.4 },
    { inception: 2018, ageMonths: 36, value: 178.9 },
    { inception: 2018, ageMonths: 48, value: 182.7 },
    { inception: 2018, ageMonths: 60, value: 183.5 },
    // 2019
    { inception: 2019, ageMonths: 12, value: 146.6 },
    { inception: 2019, ageMonths: 24, value: 150.5 },
    { inception: 2019, ageMonths: 36, value: 151.9 },
    { inception: 2019, ageMonths: 48, value: 147.9 },
    // 2020
    { inception: 2020, ageMonths: 12, value: 143.9 },
    { inception: 2020, ageMonths: 24, value: 143.8 },
    { inception: 2020, ageMonths: 36, value: 143.4 },
    // 2021
    { inception: 2021, ageMonths: 12, value: 152.5 },
    { inception: 2021, ageMonths: 24, value: 154.0 },
    // 2022
    { inception: 2022, ageMonths: 12, value: 152.4 },
  ],
  premiums: [
    { inception: 2016, value: 217.3 },
    { inception: 2017, value: 237.8 },
    { inception: 2018, value: 389.7 },
    { inception: 2019, value: 222.8 },
    { inception: 2020, value: 193.4 },
    { inception: 2021, value: 264.4 },
    { inception: 2022, value: 308.8 },
  ],
};

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.ingest(args.url),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: args.body,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const ingestApi = createApi({
  reducerPath: "ingestApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    status: builder.query({
      query: (ingestionId) => ({
        url: "retrieve-status",
        body: JSON.stringify({ ingestionId }),
      }),
    }),
    retrieveTransformHints: builder.query({
      query: ({ ingestionId, transformId }) => ({
        url: "retrieve-transform-hints",
        body: JSON.stringify({ ingestionId, transformId }),
      }),
    }),
    createIngestion: builder.mutation({
      query: (ingestionType) => ({
        url: "create-ingestion",
        body: JSON.stringify({ ingestionType }),
      }),
    }),
    applyTransform: builder.mutation({
      query: ({ ingestionId, transformId, args }) => ({
        url: "apply-transform",
        body: JSON.stringify({ ingestionId, transformId, args }),
      }),
    }),
    completeIngestion: builder.mutation({
      query: ({ ingestionId }) => ({
        url: "complete-ingestion",
        body: JSON.stringify({ ingestionId }),
      }),
    }),
    retrieveArtifact: builder.query({
      query: ({ artifactId }) => ({
        url: "retrieve-artifact",
        body: JSON.stringify({ artifactId }),
      }),
    }),
  }),
});

export const {
  useStatusQuery,
  useRetrieveTransformHintsQuery,
  useCreateIngestionMutation,
  useApplyTransformMutation,
  useCompleteIngestionMutation,
  useRetrieveArtifactQuery,
} = ingestApi;
