import { useSendPeerReviewEmailMutation } from "../../services/emailService";
import { getInsured } from "../../store/selectors/input/program/programSelectors";
import { Close, Done } from "@mui/icons-material";
import { Autocomplete, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import Notes from "components/common/Notes";
import TextField from "components/common/TextField";
import Visible from "components/common/Visible";
import PersonSelect from "components/inputs/program/PersonSelect";
import * as config from "config";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as summaryActions from "store/actions/summary/summaryActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import { getPeerReviewer } from "store/selectors/review/reviewSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const PeerReviewerListSelector = (props) => (
  <>
    <Grid item xs={true}>
      <Disabled ifReadOnly>
        <PersonSelect
          style={{ alignSelf: "flex-start" }}
          label={"Peer Reviewer"}
          person={props.peerReviewer}
          disabled={props.peerReviewComplete}
          personList={props.underwriterList}
          onUpdatePerson={(person) =>
            props.updateSummaryPeerReviewer(person ?? {})
          }
          onUpdatePersonList={() => {}}
          showCompany={false}
          showEmail={false}
          showLabelOnCard={false}
        />
      </Disabled>
    </Grid>
    {!props.peerReviewComplete &&
      props.userIsUnderwriter &&
      props.peerReviewer?.id !== props.user?.id && (
        <Disabled ifReadOnly>
          <Visible
            byTag={"summary.peerReview.me-reviewer"}
            defaultVisible={true}
          >
            <Grid item xs={"auto"}>
              <Disabled ifReadOnly>
                <Button
                  onClick={() =>
                    props.updateSummaryPeerReviewer(
                      props?.underwriterFromUser ?? {}
                    )
                  }
                  variant={"contained"}
                  disableElevation
                  color={"primary"}
                >
                  {"Me"}
                </Button>
              </Disabled>
            </Grid>
          </Visible>
        </Disabled>
      )}
  </>
);

const PeerReviewerInput = (props) => (
  <Grid item xs={12} lg={8}>
    <TextField
      type={"text"}
      label={"Peer Reviewer"}
      value={props.peerReviewer.name ?? ""}
      disabled={props.peerReviewComplete}
      onChange={(e) =>
        props.updateSummaryPeerReviewerFromName(e?.target?.value ?? "")
      }
    />
  </Grid>
);

const Attendees = () => {
  const dispatch = useDispatch();

  const attendees = useSelector(
    (state) => state?.summary?.peerReview?.attendees ?? []
  );

  const setAttendees = (attendees) =>
    dispatch(summaryActions.updateSummaryPeerReviewAttendees(attendees));

  const { data: generalConfiguration } = staticDataService.useConfigQuery(
    "general_configuration"
  );

  const users = generalConfiguration?.workflowUsers ?? [];

  const translateLabels = staticDataHooks.useTranslator("uiLabels");

  return (
    <Autocomplete
      value={attendees ?? []}
      onChange={(_, value) => setAttendees(value)}
      options={users}
      getOptionLabel={(option) => option.name}
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          label={translateLabels("PEER_REVIEW_ATTENDEES", {
            default: "Attendees",
          })}
          variant={"filled"}
          InputLabelProps={{ shrink: true }}
        />
      )}
      autoHighlight
    />
  );
};

const PeerReviewNote = (props) => {
  const hasUwList = (props.underwriterList ?? []).length > 0;
  const reviewer = props.peerReviewer;
  const [emailSendRequested, setRequestEmailSend] = useState("READY");
  const [
    startSendEmail,
    { status: emailStatus },
  ] = useSendPeerReviewEmailMutation();
  if (emailSendRequested === "RUNNING" && emailStatus !== "pending")
    setRequestEmailSend("COMPLETED");
  const statusMessage = () => {
    if (emailSendRequested === "COMPLETED") {
      const style = { verticalAlign: "middle", padding_Botton: "2px" };
      if (emailStatus === "fulfilled")
        return (
          <>
            {" "}
            <Done color={"success"} style={style} />
            {" Sent OK "}
          </>
        );
      else if (emailStatus === "rejected")
        return (
          <>
            {" "}
            <Close color={"error"} style={style} />
            {" Send failed "}
          </>
        );
    }
    return "";
  };
  return (
    <Notes
      title={props.title}
      value={props?.peerReview?.commentary || ""}
      update={props.updateSummaryPeerReviewComment}
      disabled={props.peerReviewComplete}
      lg_width={props.lg_width ?? 6}
      md_width={props.md_width ?? 12}
      growVertically={props.growVertically}
    >
      <Grid container spacing={config.GRID_SPACING} alignItems={"top"}>
        <Visible
          byTag={"summary.peerReview.peerReviewer"}
          defaultVisible={true}
        >
          {hasUwList ? (
            <PeerReviewerListSelector
              {...props}
              updateSummaryPeerReviewer={(entry) => {
                props.updateSummaryPeerReviewer(entry);
                setRequestEmailSend("READY");
              }}
            />
          ) : (
            <PeerReviewerInput {...props} />
          )}
        </Visible>
        <Visible byTag={"summary.peerReview.attendees"} defaultVisible={false}>
          <Grid item xs={8}>
            <Attendees />
          </Grid>
        </Visible>
        <Grid item xs={4} lg={4}>
          <FormattedDatePicker
            label={"Date"}
            disabled={props.peerReviewComplete}
            onChangeDate={props.updateSummaryPeerReviewDate}
            value={props?.peerReview?.date || ""}
          />
        </Grid>
        {!props.peerReviewComplete &&
          reviewer?.email &&
          reviewer?.id !== props.user?.id && (
            <Visible
              byTag={"summary.notes.peerReview.email"}
              defaultVisible={false}
            >
              <Grid item xs={12}>
                <Disabled ifReadOnly>
                  <Button
                    isLoading={emailSendRequested === "RUNNING"}
                    onClick={() => {
                      setRequestEmailSend("RUNNING");
                      startSendEmail({
                        sender_name: "",
                        email_to: reviewer?.email,
                        submission_url: window.location.href,
                        submission_name: props.insured,
                      });
                    }}
                  >
                    {"Send Review Request Email"}
                  </Button>
                </Disabled>
                <span
                  sx={1}
                  style={{ marginLeft: "5px", verticalAlign: "middle" }}
                >
                  {statusMessage()}
                </span>
              </Grid>
            </Visible>
          )}
        <Visible
          byTag={"summary.notes.peerReview.complete"}
          defaultVisible={false}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    props.isUserReadOnly ||
                    (!props.peerReviewComplete && !props.canCompletePeerReview)
                  }
                  checked={props.peerReview?.complete ?? false}
                  onClick={() => {
                    const changedAt = new Date().toISOString();
                    const complete = !props.peerReviewComplete;
                    props.updateSummaryPeerReviewComplete({
                      complete,
                      changedAt,
                      date: changedAt.split("T")[0],
                      changedBy: props?.userEmail,
                    });
                    if (complete) {
                      props.saveState();
                    }
                  }}
                />
              }
              label={"Peer Review Complete"}
            />
          </Grid>
        </Visible>
      </Grid>
    </Notes>
  );
};

const mapDispatchToProps = {
  updateSummaryPeerReviewComment: summaryActions.updateSummaryPeerReviewComment,
  updateSummaryPeerReviewer: summaryActions.updateSummaryPeerReviewer,
  updateSummaryPeerReviewerFromName:
    summaryActions.updateSummaryPeerReviewerFromName,
  updateSummaryPeerReviewDate: summaryActions.updateSummaryPeerReviewDate,
  updateSummaryPeerReviewComplete:
    summaryActions.updateSummaryPeerReviewComplete,
  saveState: persistenceActions.saveState.requested,
};

const mapStateToProps = (state) => {
  const peerReview = state?.summary?.peerReview ?? {};
  const peerReviewer = getPeerReviewer(state);
  return {
    peerReview,
    peerReviewComplete: peerReview.complete ?? false,
    canCompletePeerReview: peerReview.commentary && peerReviewer?.email,
    userEmail: userSelectors.selectUser(state).attributes?.email ?? "",
    underwriterList: staticDataSelectors.selectUnderwriterList(state),
    userIsUnderwriter:
      staticDataSelectors.selectUnderwriterFromUser(state) != null,
    underwriterFromUser: staticDataSelectors.selectUnderwriterFromUser(state),
    isUserReadOnly: userSelectors.isUserReadOnly(state),
    insured: getInsured(state),
    peerReviewer,
    user: userSelectors.selectUser(state).attributes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeerReviewNote);
