import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import StatusSelectV2 from "components/common/StatusSelectV2";
import * as config from "config";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const COLUMNS = [
  {
    key: "delete",
    title: "",
    width: 0,
    align: "right",
    description: "",
    defaultVisible: true,
    layerCell: ({ hasDelete, deleteLayer, key }) => (
      <TableCell key={key} align={"left"}>
        {hasDelete ? (
          <IconButton
            icon={DeleteIcon}
            onClick={(e) => {
              deleteLayer();
              e.stopPropagation();
            }}
          />
        ) : (
          ""
        )}
      </TableCell>
    ),
  },
  {
    key: "status",
    title: "Status",
    width: 0,
    description: "",
    defaultVisible: true,
    layerCell: ({ layer, updateLayerStatus, key }) => (
      <TableCell key={key}>
        <Disabled ifReadOnly>
          <StatusSelectV2
            status={layer.status}
            onChange={(newStatus) => updateLayerStatus(newStatus)}
            variant={"compact"}
            showQuickSwitch
          />
        </Disabled>
      </TableCell>
    ),
  },
  {
    key: "statusBadge",
    title: "Status",
    width: 0,
    description: "",
    defaultVisible: false,
    layerCell: ({ layer, key }) => (
      <TableCell key={key}>
        <StatusBadgeV2
          status={layer.status}
          onChange={() => {}}
          variant={"compact"}
        />
      </TableCell>
    ),
  },
  {
    key: "isAsIf",
    title: "As if?",
    width: 0,
    align: "right",
    description:
      "Which layer should be used for the As If calculation on Tower Pricing.",
    defaultVisible: true,
    layerCell: ({ isBaseLayer, setBaseLayer, key }) => (
      <TableCell key={key} align={"right"} sx={{ padding: 0 }}>
        <Checkbox
          checked={isBaseLayer || false}
          onChange={(e) => setBaseLayer(e.target.checked)}
        />
      </TableCell>
    ),
  },
  {
    key: "copyId",
    title: "",
    width: 0,
    align: "right",
    description: "",
    defaultVisible: false,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"} sx={{ padding: 0 }}>
        <IconButton
          scale={"small"}
          tooltip={layer.id}
          icon={ContentCopyIcon}
          edge={"start"}
          onClick={(e) => {
            navigator.clipboard.writeText(layer.id);
            e.stopPropagation();
          }}
        />
      </TableCell>
    ),
  },
  {
    key: "shareOfLimit",
    title: "Our Limit",
    width: 150,
    align: "right",
    description: "Our share of the layer limit.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.lineSize}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
    summaryCell: ({ layers, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layers.map((x) => x.lineSize).reduce((a, b) => a + b, 0)}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "limit",
    title: "Part of",
    width: 150,
    align: "right",
    description: "The total layer limit.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.limit}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "attachment",
    title: "Attachment",
    width: 150,
    align: "right",
    description: "The layer attachment.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.attachment}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "grossPremium",
    title: "100% Premium",
    width: 150,
    align: "right",
    description: "The 100% bound premium.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.grossPremium}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
    summaryCell: ({ layers, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layers.map((x) => x.grossPremium).reduce((a, b) => a + b, 0)}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "grossPPM",
    title: "Gross PPM",
    width: 150,
    align: "right",
    description: "100% bound premium ÷ limit.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.grossPPM}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "shareOfNetPremium",
    title: "Our Net Premium",
    width: 150,
    align: "right",
    description:
      "Our share of the Premium less brokerage and other acquisition costs.",
    defaultVisible: true,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.uwSelectedMeasure.shareOfNetPremium}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
    summaryCell: ({ layers, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layers
            .map((x) => x.uwSelectedMeasure.shareOfNetPremium)
            .reduce((a, b) => a + b, 0)}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "targetPrice",
    title: "Target Price",
    width: 80,
    align: "right",
    defaultVisible: true,
    description: config.DEFINITION_TP,
    layerCell: ({ layer, key }) => {
      const dealQualityClassName = makeDealQualityClassName(
        (layer.uwSelectedMeasure.targetPrice ?? 100) - 100
      );
      return (
        <TableCell key={key} align={"right"}>
          <NumberFormat
            value={layer.uwSelectedMeasure.targetPrice}
            suffix={"%"}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale
            className={`monospace ${dealQualityClassName}`}
          />
        </TableCell>
      );
    },
  },
  {
    key: "gnulr",
    title: "GNULR",
    width: 80,
    align: "right",
    defaultVisible: true,
    description: config.DEFINITION_GNULR,
    layerCell: ({ layer, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layer.uwSelectedMeasure.technicalRatio}
          suffix={"%"}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale
          className={"monospace"}
        />
      </TableCell>
    ),
  },
  {
    key: "tac",
    title: "TAC",
    width: 80,
    align: "right",
    defaultVisible: true,
    description: config.DEFINITION_TAC,
    layerCell: ({ layer, key }) => {
      const getTACTooltip = () => {
        const premium =
          layer.shareOfPremium == null
            ? "unknown"
            : "$" +
              layer.shareOfPremium.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              });
        const tac =
          layer.shareOfPremium == null || layer.tac == null
            ? "unknown"
            : "$" +
              ((layer.tac * layer.shareOfPremium) / 100).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 0,
                }
              );
        return `Total acquisition costs on ${premium} of premium is ${tac}.`;
      };

      return (
        <Tooltip key={key} title={getTACTooltip()}>
          <TableCell align={"right"}>
            <NumberFormat
              value={layer.tac}
              suffix={"%"}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={1}
              fixedDecimalScale
              className={"monospace"}
            />
          </TableCell>
        </Tooltip>
      );
    },
    summaryCell: ({ layers, key }) => (
      <TableCell key={key} align={"right"}>
        <NumberFormat
          value={layers
            .map((x) => (x.shareOfPremium * x.tac) / 100)
            .reduce((a, b) => a + b, 0)}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          className={"monospace"}
        />
      </TableCell>
    ),
  },
];

const makeDealQualityClassName = (good) => {
  if (good == null) {
    return "";
  }
  if (good < 0) {
    return "table-cell-bad-deal";
  }
  if (good > 0) {
    return "table-cell-good-deal";
  }
  return "";
};

export const LayerSelector = ({
  title = "Layers",
  subtitle,
  includedColumnKeys = null,
  includeSummaryRow = true,
  includeAddLayerButton = true,
  ...props
}) => {
  const userConfig = useSelector(userSelectors.selectUserConfig);

  const columns = COLUMNS.filter((column) => {
    const isConfiguredVisible =
      userConfig.componentVisibility?.[
        `pricing.layerPricing.activeLayerSelectorTable.${column.key}`
      ];
    if (includedColumnKeys) {
      return (
        includedColumnKeys.includes(column.key) && (isConfiguredVisible ?? true)
      );
    } else {
      return isConfiguredVisible ?? column.defaultVisible ?? false;
    }
  });

  const makeTableHeaderCell = (title, width, align, index) => (
    <TableCell
      align={align}
      valign={"bottom"}
      style={{ minWidth: width, verticalAlign: "bottom" }}
      key={`ACTIVE_LAYER_SELECTOR_HEADER_COLUMN_${index}`}
    >
      {title}
    </TableCell>
  );

  const makeTableHeader = ({ title, width, align, description }, index) =>
    description === "" ? (
      makeTableHeaderCell(title, width, align, index)
    ) : (
      <Tooltip
        title={description}
        key={`ACTIVE_LAYER_SELECT_HEADER_TOOLTIP_${index}`}
      >
        {makeTableHeaderCell(title, width, align, index)}
      </Tooltip>
    );

  return (
    <Component title={title} subtitle={subtitle}>
      <Grid item>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              size={"small"}
              className={
                "condensed-table table-with-clickable-rows" +
                (includeSummaryRow ? " table-with-total-row" : "")
              }
            >
              <TableHead>
                <TableRow>{columns.map(makeTableHeader)}</TableRow>
              </TableHead>
              <TableBody>
                {props.layers.map((layer, index) => (
                  <TableRow
                    style={
                      index === props.selectedIndex
                        ? { background: config.SELECTED_ROW_BACKGROUND_COLOR }
                        : {}
                    }
                    onClick={() => props.updateActive(index)}
                    key={`ACTIVE_LAYER_SELECTOR_ROW_${index}`}
                  >
                    {columns.map((column) =>
                      column.layerCell({
                        layer,
                        hasDelete: props.layers.length !== 1,
                        isSelectedRow: props.selectedIndex === index,
                        updateSelected: () => props.updateActive(index),
                        updateLayerStatus: (status) =>
                          props.updateLayerStatus(index, status),
                        deleteLayer: () => props.deleteLayer(index),
                        setBaseLayer: () =>
                          props.setAsBaseLayerForTowerPricingScaling(index),
                        isBaseLayer: props.baseLayerForTowerPricing === index,
                        key: `ACTIVE_LAYER_SELECTOR_CELL_${index}_${column.key}`,
                      })
                    )}
                  </TableRow>
                ))}
                {includeSummaryRow && (
                  <TableRow>
                    {columns.map((column) => {
                      const key = `ACTIVE_LAYER_SELECTOR_SUMMARY_CELL_${column.key}`;
                      return column.summaryCell ? (
                        column.summaryCell({ layers: props.layers, key })
                      ) : (
                        <TableCell key={key} />
                      );
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {includeAddLayerButton && (
          <Grid item xs={12}>
            <Button
              onClick={props.addLayer}
              variant={"contained"}
              disableElevation
              color={"primary"}
              className={"button"}
              sx={{ marginTop: config.GRID_SPACING }}
            >
              {"Add Layer"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  updateActive: pricingActions.updateActive,
  updateLayerStatus: pricingActions.updateLayerStatus,
  deleteLayer: pricingActions.deleteLayer,
  addLayer: pricingActions.addLayer,
  setAsBaseLayerForTowerPricingScaling:
    pricingActions.setAsBaseLayerForTowerPricingScaling,
};

const mapStateToProps = (state) => ({
  selectedIndex: state.pricing.activeLayer,
  layers: pricingSelectors.getTower(state),
  baseLayerForTowerPricing: state.pricing.towerPricingScalingLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelector);
