import { Autocomplete, Box, TextField } from "@mui/material";

const NULL_KEY = "__NULL__";

const makeEditComponent = ({ choices }) => (props) => {
  const { id, field, api } = props;

  const onChange = (_, selectedOption, reason) => {
    if (reason === "selectOption") {
      api.setEditCellValue({ id, field, value: selectedOption?.key ?? null });
      api.stopCellEditMode({ id, field });
    }
  };

  const onClose = (_, reason) => {
    if (reason !== "toggleInput") {
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <Autocomplete
      options={choices}
      getOptionKey={(option) => option.key}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      onClose={onClose}
      renderInput={(params) => (
        <Box
          sx={{
            padding: 0.5,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            {...params}
            variant={"outlined"}
            autoFocus
            size={"small"}
          />
        </Box>
      )}
      fullWidth
      open
      autoHighlight
    />
  );
};

/**
 * @typedef {import("@mui/x-data-grid").GridColDef} GridColDef
 * @param {GridColDef} colProps
 */
export const makeChoicesColDef = ({
  field,
  choices,
  editable = true,
  sortable = false,
  ...colProps
}) => {
  const keyToNameMapping = Object.fromEntries(
    (choices ?? []).map((c) => [c.key, c.name])
  );
  return {
    field,
    type: "string",
    sortable,
    editable,
    valueGetter: (value) => value ?? NULL_KEY,
    valueSetter: (value, row) => {
      if (keyToNameMapping[value] != null) {
        return {
          ...row,
          [field]: value === NULL_KEY ? null : value,
        };
      } else {
        return row;
      }
    },
    valueFormatter: (value) =>
      ({
        [NULL_KEY]: "-- Please Select --",
        ...keyToNameMapping,
      }[value] ?? "<Unknown>"),
    renderEditCell: makeEditComponent({ choices }),
    ...colProps,
  };
};
