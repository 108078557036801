import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as analyticsResolver from "domain/blankResolver";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {},
  reducers: {
    set: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...values,
      };
    },
    update: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => ({
          ...action?.payload?.data?.state?.analytics,
        })
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => {})
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => {});
  },
});

export const selectAnalyticsInput = (state) => state?.analytics ?? null;

export const select = createSelector([selectAnalyticsInput], (analyticsInput) =>
  analyticsResolver.resolve(analyticsInput)
);

export const { set, update } = analyticsSlice.actions;

export default analyticsSlice;
