import * as towers from "../../common/towers";
import { createSelector } from "reselect";

const getExpiringProgramLayers = (state) =>
  state.input.expiringProgram?.layers || [];

export const getExpiringLayers = createSelector(
  [getExpiringProgramLayers],
  towers.fillAttachmentInLayers
);

const _layerToGrossPPM = (layer) => {
  if (layer.grossPPM == null) {
    if (layer.limit == null || layer.grossPremium == null) {
      return null;
    }
    return (layer.grossPremium / layer.limit) * 1e6;
  }
  return layer.grossPPM;
};

const _calculateLayerFields = (layer) => ({
  ...layer,
  grossPPM: _layerToGrossPPM(layer),
});

export const getExpiringProgramLayersToDisplay = createSelector(
  [getExpiringLayers],
  (layers) =>
    towers.addEmptyLayerWithAttachment(layers).map(_calculateLayerFields)
);
