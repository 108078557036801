import ClearIcon from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import { forwardRef, useImperativeHandle, useState, useRef } from "react";

const InlineSearch = forwardRef((props, ref) => {
  const {
    placeholder,
    onChange,
    sx,
    enableTrimming = true,
    noBackground = false,
    fullWidth = false,
  } = props;

  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const hasSearchText = (searchText || "").trim() !== "";
  const inputRef = useRef(null);

  const updateSearchText = (text) => {
    setSearchText(text);
    onChange(enableTrimming ? (text || "").trim() : text);
  };

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
    updateText: (text) => updateSearchText(text),
    clearText: () => updateSearchText(""),
  }));

  const className =
    "inline-search" +
    (active ? " active" : "") +
    (noBackground ? " no-background" : "") +
    (fullWidth ? " full-width" : "");

  return (
    <Stack
      className={className}
      direction={"row"}
      alignItems={"center"}
      sx={sx}
    >
      <SearchIcon className={"search-icon"} />
      <input
        value={searchText}
        ref={inputRef}
        onFocus={(_) => setActive(true)}
        onBlur={(_) => setActive(false)}
        onChange={(e) => updateSearchText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            updateSearchText("");
            e.target.blur();
          }
          e.stopPropagation();
        }}
        autoComplete={"off"}
        placeholder={placeholder}
      />
      <ClearIcon
        className={"clear-icon"}
        onClick={(e) => {
          updateSearchText("");
          inputRef.current.focus();
        }}
        sx={{
          opacity: hasSearchText ? 1 : 0,
        }}
      />
    </Stack>
  );
});

export default InlineSearch;
