import { APP_BAR_ISSUE_FEEDBACK } from "../../flags";
import { FeedbackDialog } from "../feedback/FeedbackDialog";
import AppMenu from "./AppMenu";
import Disabled from "./Disabled";
import Logo from "./Logo";
import Visible from "./Visible";
import {
  AddCircleRounded as AddIcon,
  SearchRounded as SearchIcon,
  AccountCircleRounded as AccountIcon,
  ChatBubbleOutline as FeedbackIcon,
  Logout as SignOutIcon,
} from "@mui/icons-material";
import {
  AppBar as MUIAppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { extractNameFromEmail, stringToColor } from "common/strings";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as persistenceActions from "store/actions/persistence/persistenceActions";

const UserAvatar = ({ email }) => {
  const meta = extractNameFromEmail(email);

  return (
    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
      <Avatar sx={{ bgcolor: stringToColor(meta.name) }}>
        {meta.initials}
      </Avatar>
      <Stack>
        <Typography variant={"caption"}>{"Signed in as"}</Typography>
        <Typography>{email}</Typography>
      </Stack>
    </Stack>
  );
};

const AccountMenu = ({ anchorEl, closeMenu, email, onSignOut, onFeedback }) => (
  <Menu
    anchorEl={anchorEl}
    open={anchorEl != null}
    onClose={(_, reason) => {
      if (reason === "backdropClick") closeMenu();
    }}
  >
    <MenuItem disabled sx={{ opacity: "1 !important", minWidth: "280px" }}>
      <UserAvatar email={email} />
    </MenuItem>

    <Divider />

    <Visible byTag={APP_BAR_ISSUE_FEEDBACK} defaultVisible={false}>
      <MenuItem onClick={onFeedback}>
        <ListItemText primary={"Feedback"} />
        <ListItemIcon>
          <FeedbackIcon />
        </ListItemIcon>
      </MenuItem>
    </Visible>
    <MenuItem onClick={onSignOut}>
      <ListItemText primary={"Sign Out"} />
      <ListItemIcon>
        <SignOutIcon />
      </ListItemIcon>
    </MenuItem>
  </Menu>
);

const AppBar = (props) => {
  const [title, setTitle] = useState("Submissions");

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const minHeight = "3rem";

  const navigate = useNavigate();
  const createNewSubmission = useCallback(() => {
    props.newSubmission();
    navigate("/submissions/new");
  }, [navigate, props]);

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  return (
    <MUIAppBar
      position={"static"}
      elevation={0}
      sx={{
        backgroundColor: "white",
        borderBottom: "1px solid #dce2eb",
      }}
    >
      <Toolbar
        disableGutters={true}
        sx={{ minHeight: minHeight + " !important", padding: "0 0.5rem" }}
      >
        <AppMenu onChange={(label) => setTitle(label)} />
        <Logo height={20} />
        <Typography
          variant={"subtitle1"}
          sx={{ marginLeft: "0.5rem !important" }}
        >
          {title}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Tooltip title={"New Submission"}>
            <span>
              <Disabled ifReadOnly>
                <IconButton
                  onClick={() => createNewSubmission()}
                  color={"primary"}
                >
                  <AddIcon />
                </IconButton>
              </Disabled>
            </span>
          </Tooltip>
          <Link to={"/submissions"}>
            <Tooltip title={"Search"}>
              <IconButton sx={{ padding: "0.4375rem" }}>
                <SearchIcon sx={{ fontSize: "1.625rem" }} />
              </IconButton>
            </Tooltip>
          </Link>

          <IconButton sx={{ padding: "0.25rem" }} onClick={openMenu}>
            <AccountIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Stack>
      </Toolbar>

      <AccountMenu
        anchorEl={anchorEl}
        closeMenu={closeMenu}
        email={props.attributes?.email || "Unknown"}
        onSignOut={(e) => {
          e.preventDefault();
          navigate("/logout");
        }}
        onFeedback={(_) => {
          closeMenu();
          setFeedbackDialogOpen(true);
        }}
      />

      <FeedbackDialog
        open={feedbackDialogOpen}
        close={() => setFeedbackDialogOpen(false)}
      />
    </MUIAppBar>
  );
};

const mapDispatchToProps = {
  newSubmission: persistenceActions.newSubmission,
};

const mapStateToProps = (state) => {
  return {
    attributes: state.user?.attributes || {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
