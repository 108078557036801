import * as support from "./LossCurves.support";
import { SettingsBackupRestore as ResetIcon } from "@mui/icons-material";
import { Box, Stack, IconButton } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Component from "components/Component";
import { makePrettyNumberColDef } from "components/common/dataGrid/prettyNumberColumn";
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from "react";

const PasteManager = ({ children, onPaste }) => {
  const wrapperRef = useRef(null);
  const lastClickRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const handlePasteEvent = (event) => {
        event.preventDefault();
        if (onPaste && event.clipboardData) {
          onPaste({
            lastClick: lastClickRef.current,
            pastedText: event.clipboardData.getData("text/plain"),
          });
        }
      };
      wrapper.addEventListener("paste", handlePasteEvent);
      return () => {
        wrapper.removeEventListener("paste", handlePasteEvent);
      };
    }
  }, [wrapperRef, lastClickRef, onPaste]);

  return (
    <Box ref={wrapperRef}>
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, {
              slotProps: {
                cell: {
                  onFocus: (event) => {
                    lastClickRef.current = {
                      rowId: event.currentTarget.parentElement.dataset.id,
                      columnId: event.currentTarget.dataset.field,
                    };
                  },
                },
              },
            })
          : child
      )}
    </Box>
  );
};

const ExceedenceProbabilities = ({ modelDataFacet, updateModelDataFacet }) => {
  const apiRef = useGridApiRef();

  const columns = [
    makePrettyNumberColDef({
      field: "returnPeriod",
      headerName: "Return Period",
      editable: false,
      minWidth: 120,
    }),
    makePrettyNumberColDef({
      field: "grossLossAep",
      headerName: "Gross Loss AEP",
      editable: true,
      minWidth: 120,
    }),
    makePrettyNumberColDef({
      field: "grossLossOep",
      headerName: "Gross Loss OEP",
      editable: true,
      minWidth: 120,
    }),
  ];

  const defaultEP = [
    { id: 0, returnPeriod: 10000, grossLossAep: null, grossLossOep: null },
    { id: 1, returnPeriod: 5000, grossLossAep: null, grossLossOep: null },
    { id: 2, returnPeriod: 1000, grossLossAep: null, grossLossOep: null },
    { id: 3, returnPeriod: 500, grossLossAep: null, grossLossOep: null },
    { id: 4, returnPeriod: 250, grossLossAep: null, grossLossOep: null },
    { id: 5, returnPeriod: 200, grossLossAep: null, grossLossOep: null },
    { id: 6, returnPeriod: 100, grossLossAep: null, grossLossOep: null },
    { id: 7, returnPeriod: 50, grossLossAep: null, grossLossOep: null },
    { id: 8, returnPeriod: 25, grossLossAep: null, grossLossOep: null },
    { id: 9, returnPeriod: 10, grossLossAep: null, grossLossOep: null },
    { id: 10, returnPeriod: 5, grossLossAep: null, grossLossOep: null },
    { id: 11, returnPeriod: 2, grossLossAep: null, grossLossOep: null },
  ];

  const ep = modelDataFacet?.exceedenceProbabilities ?? defaultEP;

  const processRowUpdate = (newRow) => {
    const updatedData = ep?.map((row) => (row.id === newRow.id ? newRow : row));
    updateModelDataFacet({ exceedenceProbabilities: updatedData });
    return newRow;
  };

  return (
    <PasteManager
      onPaste={({ lastClick, pastedText }) => {
        if (lastClick?.rowId == null || lastClick?.columnId == null) {
          return;
        }
        if (lastClick.columnId === "returnPeriod") {
          return;
        }

        const pastedValues = support.parseFloatTable(pastedText);
        const firstPastedRowId = parseInt(lastClick.rowId);

        const newExceedenceProbabilities = ep.map((row) => {
          const pastedRowValues = pastedValues[row.id - firstPastedRowId];
          if (pastedRowValues == null) {
            return row;
          }

          const newRow = { ...row };
          if (lastClick.columnId === "grossLossAep") {
            if (pastedRowValues?.length >= 1) {
              newRow.grossLossAep = pastedRowValues[0];
            }
            if (pastedRowValues?.length >= 2) {
              newRow.grossLossOep = pastedRowValues[1];
            }
          } else if (lastClick.columnId === "grossLossOep") {
            if (pastedRowValues?.length >= 1) {
              newRow.grossLossOep = pastedRowValues[0];
            }
          }

          return newRow;
        });
        updateModelDataFacet({
          exceedenceProbabilities: newExceedenceProbabilities,
        });
      }}
    >
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        rows={ep}
        rowHeight={25}
        processRowUpdate={processRowUpdate}
        onCellEditStart={(params, event) => {
          if (params?.reason === "deleteKeyDown") {
            updateModelDataFacet({
              exceedenceProbabilities: modelDataFacet?.exceedenceProbabilities?.map(
                (ep) =>
                  ep.id === params.id
                    ? {
                        ...ep,
                        [params.field]: null,
                      }
                    : ep
              ),
            });
            event.defaultMuiPrevented = true;
            return;
          } else if (params?.reason === "pasteKeyDown") {
            event.defaultMuiPrevented = true;
            return;
          }
        }}
        hideFooter
        disableColumnMenu
        autoHeight
      />
    </PasteManager>
  );
};

const SummaryStats = ({ modelDataFacet, updateModelDataFacet }) => {
  const apiRef = useGridApiRef();

  const columns = [
    {
      field: "label",
      headerName: "Metric",
      type: "text",
      editable: false,
      width: 220,
    },
    makePrettyNumberColDef({
      field: "metric",
      headerName: "Value",
      width: 120,
    }),
  ];

  const rows = [
    {
      id: "expectedLoss",
      label: "Expected Value",
      metric: modelDataFacet?.summaryStats?.expectedLoss ?? null,
    },
    {
      id: "standardDeviation",
      label: "Standard Deviation",
      metric: modelDataFacet?.summaryStats?.standardDeviation ?? null,
    },
    {
      id: "coefficientOfVariation",
      label: "Coefficient of Variation",
      metric: modelDataFacet?.summaryStats?.coefficientOfVariation ?? null,
    },
  ];

  return (
    <PasteManager
      onPaste={({ lastClick, pastedText }) => {
        if (lastClick?.rowId == null || lastClick?.columnId == null) {
          return;
        }
        if (lastClick.columnId !== "metric") {
          return;
        }

        const pastedValues = support.parseFloatTable(pastedText);

        const newSummaryStats = {
          ...modelDataFacet?.summaryStats,
        };
        if (lastClick.rowId === "expectedLoss") {
          if (pastedValues?.[0]?.[0] != null) {
            newSummaryStats["expectedLoss"] = pastedValues[0][0];
          }
          if (pastedValues?.[1]?.[0] != null) {
            newSummaryStats["standardDeviation"] = pastedValues[1][0];
          }
          if (pastedValues?.[2]?.[0] != null) {
            newSummaryStats["coefficientOfVariation"] = pastedValues[2][0];
          }
        } else if (lastClick.rowId === "standardDeviation") {
          if (pastedValues?.[0]?.[0] != null) {
            newSummaryStats["standardDeviation"] = pastedValues[0][0];
          }
          if (pastedValues?.[1]?.[0] != null) {
            newSummaryStats["coefficientOfVariation"] = pastedValues[1][0];
          }
        } else if (lastClick.rowId === "coefficientOfVariation") {
          if (pastedValues?.[0]?.[0] != null) {
            newSummaryStats["coefficientOfVariation"] = pastedValues[0][0];
          }
        }

        updateModelDataFacet({
          summaryStats: newSummaryStats,
        });
      }}
    >
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        rowHeight={25}
        processRowUpdate={(newRow) => {
          updateModelDataFacet({
            summaryStats: {
              ...modelDataFacet?.summaryStats,
              [newRow.id]: newRow.metric,
            },
          });
          return newRow;
        }}
        onCellEditStart={(params, event) => {
          if (params?.reason === "deleteKeyDown") {
            updateModelDataFacet({
              summaryStats: {
                ...modelDataFacet?.summaryStats,
                [params.id]: null,
              },
            });
            event.defaultMuiPrevented = true;
            return;
          } else if (params?.reason === "pasteKeyDown") {
            event.defaultMuiPrevented = true;
            return;
          }
        }}
        hideFooter
        disableColumnMenu
        autoHeight
      />
    </PasteManager>
  );
};

const LossCurves = ({ label, modelDataFacet, updateModelDataFacet }) => {
  return (
    <Component
      title={label}
      options={
        <IconButton
          color={"primary"}
          onClick={() =>
            updateModelDataFacet({
              exceedenceProbabilities: null,
              summaryStats: null,
            })
          }
          tooltip={"Clear"}
        >
          <ResetIcon />
        </IconButton>
      }
    >
      <Stack direction={"column"} spacing={2}>
        <ExceedenceProbabilities
          modelDataFacet={modelDataFacet}
          updateModelDataFacet={updateModelDataFacet}
        />
        <SummaryStats
          modelDataFacet={modelDataFacet}
          updateModelDataFacet={updateModelDataFacet}
        />
      </Stack>
    </Component>
  );
};

export default LossCurves;
