import * as searchSupport from "./searchSupport20240618";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Search from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as submissionsService from "services/submissionsService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as userSelectors from "store/selectors/user/userSelectors";
import * as utils from "utils";

const inCurrentYear = (date) =>
  new Date().getFullYear() === new Date(date).getFullYear();

const PrettyDate = ({ date }) => {
  const formatted = utils.formatDate(date);
  const year = formatted.slice(-4);
  const dayMonth = formatted.slice(0, -4);

  return (
    <div className={"inception"}>
      {dayMonth}
      <span className={inCurrentYear(date) ? "highlight" : null}>{year}</span>
    </div>
  );
};

const SubmissionList = ({
  title,
  submissions,
  onSelectSubmissionId,
  showNoMatches,
}) => {
  return (
    <Box mt={1} mb={1}>
      <span className={"label"}>{title}</span>
      {showNoMatches ? (
        <TableContainer sx={{ height: 450, width: "100%", fontSize: "1.2rem" }}>
          <div
            className={"search-result"}
            style={{
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"No matches found"}
          </div>
        </TableContainer>
      ) : (
        <TableContainer sx={{ height: 450, width: "100%" }}>
          {submissions.map((submission) => (
            <div
              className={"search-result"}
              key={submission.submissionId}
              onClick={() => onSelectSubmissionId(submission.submissionId)}
            >
              <header>
                <h5>{submission.insured || "???"}</h5>
                <PrettyDate date={submission.inception} />
              </header>
              <p>{submission.riskDescription}</p>
            </div>
          ))}
        </TableContainer>
      )}
    </Box>
  );
};

const SearchDialog20240618 = ({ isUserReadOnly, userEmail, newSubmission }) => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [justMe, setJustMe] = useState(false);

  const {
    data: submissions,
    isLoading,
    isFetching,
    refetch: refetchSubmissions,
  } = submissionsService.useListSubmissionsQuery();

  const {
    recentlyEditedSubmissions,
    renewingSubmissions,
  } = searchSupport.searchSubmissions(submissions, {
    searchQuery,
    justMe,
    userEmail,
    limit: 50,
  });

  useEffect(() => {
    refetchSubmissions();
  }, [refetchSubmissions]);

  const openSubmission = (submissionId) => {
    navigate(submissionId);
  };

  const handleNewSubmission = () => {
    newSubmission();
    navigate("/submissions/new");
  };
  const showNoMatches =
    !isLoading &&
    searchQuery?.length > 0 &&
    recentlyEditedSubmissions?.length === 0;
  return (
    <Dialog
      open={true}
      aria-labelledby={"max-width-dialog-title"}
      className={"search-dialog"}
      maxWidth={"xl"}
      fullWidth
      onClose={() => {}}
    >
      <Grid
        container
        item
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        xs={12}
        style={{ background: "#edf0f3" }}
      >
        <Grid item xs={3}>
          {window.sessionStorage.getItem("hasVisitedSubmission") && (
            <Button
              variant={"filled"}
              disableElevation
              color={"primary"}
              onClick={() => {
                window.history.back();
              }}
              style={{ marginLeft: 20, color: "#fa7b35" }}
            >
              <NavigateBeforeIcon />
              {"Back"}
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <DialogTitle sx={{ textAlign: "center" }}>
            {"Submissions"}
          </DialogTitle>
        </Grid>
        <Grid item xs={3}>
          <div></div>
        </Grid>
      </Grid>
      <DialogContent>
        <form noValidate>
          <FormControl className={"search-form"} variant={"standard"} fullWidth>
            <div className={"input-and-checkbox"}>
              <TextField
                autoFocus
                className={"search-input"}
                placeholder={"Search"}
                value={searchQuery}
                autoComplete={"off"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <Search className={"search-icon"} />
                    </InputAdornment>
                  ),
                  endAdornment: isFetching ? (
                    <InputAdornment position={"end"}>
                      <DefaultLoader color={"#65676f"} height={20} width={20} />
                    </InputAdornment>
                  ) : searchQuery.length > 0 ? (
                    <IconButton
                      icon={CloseIcon}
                      edge={"end"}
                      onClick={() => setSearchQuery("")}
                    />
                  ) : (
                    <></>
                  ),
                }}
                onChange={(event) => setSearchQuery(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    setSearchQuery("");
                  }
                }}
                variant={"standard"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={justMe}
                    onChange={(event) => setJustMe(event.target.checked)}
                    name={"just-me"}
                  />
                }
                label={"Just me"}
              />
            </div>
            <div className={"results legacy"}>
              <SubmissionList
                title={"Edited Recently"}
                submissions={recentlyEditedSubmissions}
                showNoMatches={showNoMatches}
                onSelectSubmissionId={openSubmission}
              />
              <SubmissionList
                title={"Expiring Soon"}
                submissions={renewingSubmissions}
                onSelectSubmissionId={openSubmission}
              />
            </div>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <span className={"spacer"} style={{ flex: "1 1 auto" }}></span>
        <Button
          onClick={handleNewSubmission}
          disableElevation
          variant={"contained"}
          size={"large"}
          color={"primary"}
          className={"button"}
          disabled={isUserReadOnly}
        >
          {"New"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  newSubmission: persistenceActions.newSubmission,
};

const mapStateToProps = (state) => ({
  isUserReadOnly: userSelectors.isUserReadOnly(state),
  userEmail: userSelectors.selectUserAttributes(state)?.email ?? null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDialog20240618);
