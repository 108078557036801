import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.analytics(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    submissionAnalytics: builder.query({
      query: ({ submission, engine, metric }) => ({
        url: "calculate-submission-analytics",
        payload: {
          body: JSON.stringify({
            submission,
            engine,
            metric,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    rateChangeAnalytics: builder.query({
      query: ({ submission, engine, args }) => ({
        url: "calculate-rate-change-analytics",
        payload: {
          body: JSON.stringify({
            submission,
            engine,
            args,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
  }),
});

export const {
  useSubmissionAnalyticsQuery,
  useLazySubmissionAnalyticsQuery,
  useLazyRateChangeAnalyticsQuery,
} = analyticsApi;
