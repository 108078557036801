import { calculateTickIndex, useContainerDimensions } from "../../../utils";
import PrettyNumberTextField from "../../common/PrettyNumberTextField";
import { getMarks } from "./ClaimsSliderSupport";
import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useRef, useMemo } from "react";

const ClaimsSlider = ({ value, onChangeNumber, min, max, title, disabled }) => {
  const sliderRef = useRef();
  const { width } = useContainerDimensions(sliderRef);
  const { ticks, marks } = useMemo(() => getMarks(width, max, min), [
    width,
    max,
    min,
  ]);

  return (
    <Grid container spacing={0} columnSpacing={3}>
      {title && (
        <Grid item xs={12}>
          <div className={"component-header"}>
            <h2 className={"component-title"}>{title}</h2>
          </div>
        </Grid>
      )}
      <Grid item xs={12} sm={8} md={10}>
        <Box ml={2} mr={2}>
          <Slider
            ref={sliderRef}
            marks={marks}
            min={0}
            max={marks.length - 1}
            onChange={(e) =>
              onChangeNumber(Math.max(0, min, ticks[e.target.value]))
            }
            value={calculateTickIndex(ticks, value ?? 0)}
            disabled={disabled}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <PrettyNumberTextField
          fullWidth
          onChangeNumber={(value) => onChangeNumber(Math.max(value, min))}
          inputProps={{ style: { textAlign: "right" } }}
          value={value}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default ClaimsSlider;
