export const fillAttachmentInLayers = (tower) => {
  const layers = [];
  tower.forEach((layer, index) => {
    layers.push(
      layer.attachment
        ? {
            ...layer,
            index,
          }
        : {
            ...layer,
            index,
            attachment:
              index === 0
                ? 0
                : layers[index - 1].attachment + layers[index - 1].limit,
          }
    );
  });
  return layers;
};

export const addEmptyLayerWithAttachment = (layers) => {
  const lastIndex = layers.length - 1;
  return [
    ...layers,
    {
      attachment:
        lastIndex < 0
          ? 0
          : layers[lastIndex].attachment + layers[lastIndex].limit,
    },
  ];
};
