import CatExposuresGrid from "./CatExposuresGrid";
import Component from "components/Component";
import Button from "components/common/Button";
import { usePricing } from "components/customPricing/dnfModel1/pricingHooks";
import { useDispatch, useSelector } from "react-redux";
import * as analytics from "store/analytics";
import * as catExposuresStore from "store/catExposures";

const CatExposuresSummary = () => {
  const analyticsData = useSelector(analytics.select);
  const pricing = analyticsData?.programPricing?.values?.sovCat ?? [];

  const dispatch = useDispatch();

  const catExposures = useSelector(catExposuresStore.select);

  const updateExposure = (id) => ({ field, value }) => {
    if (!pricing?.[id]) {
      return;
    }

    setTimeout(
      () =>
        dispatch(
          catExposuresStore.update({
            sovCatOverrides: {
              ...catExposures?.sovCatOverrides,
              [id]: {
                ...catExposures?.sovCatOverrides?.[id],
                [field]: value ?? undefined,
              },
            },
          })
        ),
      0
    );
  };

  const { refetch, isFetching } = usePricing();

  const exposures = Object.entries(pricing)
    .filter(([, obj]) => obj !== null)
    .map(([id, obj]) => ({
      id: id,
      ...obj,
      ...Object.fromEntries(
        Object.entries(
          catExposures?.sovCatOverrides?.[obj._REGION_PERIL] ?? {}
        ).filter(([_, value]) => value != null)
      ),
    }));

  return (
    <Component
      title={"SOV Cat Exposures"}
      isFetching={isFetching}
      options={
        <Button onClick={refetch} isLoading={isFetching}>
          {"Calculate"}
        </Button>
      }
    >
      <CatExposuresGrid
        exposures={[...exposures]}
        updateExposure={updateExposure}
        columnEditabilityOverride={{
          _REGION_PERIL: false,
        }}
        loading={isFetching}
        sortable={true}
        initialSortModel={[{ field: "_REGION_PERIL", sort: "asc" }]}
      />
    </Component>
  );
};

export default CatExposuresSummary;
