import * as numbers from "common/numbers";
import * as strings from "common/strings";
import * as pricing from "domain/pricing";

const resolveShares = (shares, { layer }) => {
  if (shares == null || shares.length === 0) {
    return [];
  }

  return shares.map((share) => {
    const sharePct = numbers.parseFloatOrNull(
      share.shareOfLimit != null
        ? !!layer.limit
          ? (100 * share.shareOfLimit) / layer.limit
          : null
        : share.share ?? null
    );

    const apportion = (value) =>
      sharePct != null && value != null ? 0.01 * sharePct * value : null;

    const shareOfLimit = numbers.parseFloatOrNull(
      share.shareOfLimit ?? apportion(layer.limit)
    );
    const shareOfGrossPremium = numbers.parseFloatOrNull(
      share.shareOfGrossPremium ?? apportion(layer.grossPremium)
    );
    const brokerage = numbers.parseFloatOrNull(share.brokerage ?? null);
    const shareOfNetPremium = numbers.parseFloatOrNull(
      shareOfGrossPremium != null && brokerage != null
        ? (1 - 0.01 * brokerage) * shareOfGrossPremium
        : null
    );

    return {
      carrier: share.carrier || null,
      share: sharePct,
      shareOfLimit,
      shareOfGrossPremium,
      brokerage,
      shareOfNetPremium,
      comments: share.comments || null,
      reference: share.reference || null,
    };
  });
};

const checkErrorCalculatingNetValues = (shares, { layer }) => {
  if (!shares?.length) return "No shares";

  const limit = shares
    .map((s) => s.shareOfLimit ?? 0)
    .reduce((a, b) => a + b, 0);
  if (!numbers.approxEqual(limit, layer.limit))
    return "Share limits don't add up to the layer limit";

  const grossPremium = shares
    .map((s) => s.shareOfGrossPremium ?? 0)
    .reduce((a, b) => a + b, 0);
  if (!numbers.approxEqual(grossPremium, layer.grossPremium))
    return "Share premiums don't add up to the layer premium";

  if (shares.some((s) => s.brokerage == null))
    return "Not all shares have a brokerage specified";

  return null;
};

export const resolveLayer = (layer, { program }) => {
  if (!layer) {
    return null;
  }

  const errors = {};
  const shares = resolveShares(layer.shares, { layer });

  const errorCalculatingNetValues = checkErrorCalculatingNetValues(shares, {
    layer,
  });
  if (errorCalculatingNetValues) {
    errors.netPremium = errorCalculatingNetValues;
  }
  const hasNet = !errorCalculatingNetValues;

  const netPremium = hasNet
    ? shares.map((s) => s.shareOfNetPremium ?? 0).reduce((a, b) => a + b, 0)
    : null;

  return {
    ...layer,
    grossRPM: pricing.convertToRPM({ price: layer.grossPremium, layer }),
    netPremium,
    netRPM: pricing.convertToRPM({ price: netPremium, layer }),
    brokerage:
      netPremium != null && layer.grossPremium
        ? (100 * (layer.grossPremium - netPremium)) / layer.grossPremium
        : null,
    shares,
    errors,
    inception: layer.inception ?? program?.inception,
    expiration: layer.expiration ?? program?.expiration,
    reference: (layer.shares ?? []).some((_) => strings.hasValue(_.reference))
      ? (layer.shares ?? []).map((_) => _.reference ?? "").join(" / ")
      : null,
  };
};

export const resolveLayers = (layers, { program }) => {
  if (layers == null) {
    return null;
  }
  return layers
    .map((layer) => resolveLayer(layer, { program }))
    .reduce((layersBelow, layer) => {
      const layerBelow = layersBelow[layersBelow.length - 1] ?? null;

      const calculateRelativeToLayerBelow = (key) =>
        layer[key] != null && layerBelow?.[key]
          ? layer[key] / layerBelow[key]
          : null;

      return [
        ...layersBelow,
        {
          ...layer,
          grossRPMRelativeToLayerBelow: calculateRelativeToLayerBelow(
            "grossRPM"
          ),
          netRPMRelativeToLayerBelow: calculateRelativeToLayerBelow("netRPM"),
        },
      ];
    }, []);
};
