import { createSelector } from "reselect";
import * as stateSelectors from "store/selectors/stateSelectors";

export const selectUser = (state) => state.user || {};

export const selectUserAttributes = createSelector(
  [selectUser],
  (user) => user.attributes || {}
);

export const selectUserConfig = createSelector(
  [selectUserAttributes],
  (userAttributes) => userAttributes.config || {}
);

export const isUserReadOnly = createSelector(
  [selectUserAttributes],
  (userAttributes) => userAttributes["custom:is_readonly"] ?? false
);

export const isSuperUser = createSelector(
  [selectUserAttributes],
  (userAttributes) => userAttributes["custom:company_id"] === "__SUPERUSER__"
);

export const selectComponentOnStateVisibility = createSelector(
  [selectUserConfig],
  (userConfig) => userConfig?.componentOnStateVisibility || {}
);

export const getComponentOnStateVisibility = createSelector(
  [selectComponentOnStateVisibility],
  (componentOnStateVisibility) => {
    const componentVisibilitySelectors = {};
    for (const componentTag in componentOnStateVisibility) {
      const showOnState =
        componentOnStateVisibility[componentTag]?.showOnState || {};
      const hideOnState =
        componentOnStateVisibility[componentTag]?.hideOnState || {};
      const selectors = [];
      const states = [];
      for (const stateKey in showOnState) {
        selectors.push(stateSelectors.dynamicStateSelector(stateKey));
        states.push({ show: showOnState[stateKey] });
      }
      for (const stateKey in hideOnState) {
        selectors.push(stateSelectors.dynamicStateSelector(stateKey));
        states.push({ hide: hideOnState[stateKey] });
      }
      componentVisibilitySelectors[componentTag] = createSelector(
        selectors,
        (...values) => {
          const show = values.some((value, index) => {
            if (states[index]?.show) {
              return states[index].show.includes(String(value));
            }
            return false;
          });
          const hide = values.some((value, index) => {
            if (states[index]?.hide) {
              return states[index].hide.includes(String(value));
            }
            return false;
          });
          if (hide) {
            return false;
          } else if (show) {
            return true;
          } else {
            return undefined;
          }
        }
      );
    }
    return componentVisibilitySelectors;
  }
);
