import * as lossCurvesSupport from "./LossCurves.support";
import * as support from "./StateSummary.support";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import * as logger from "common/logger";
import Component from "components/Component";
import { makeChoicesColDef } from "components/common/dataGrid/choicesColumn";
import { makePrettyNumberColDef } from "components/common/dataGrid/prettyNumberColumn";
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as analyticsStore from "store/analytics";
import * as modelStore from "store/modelDataStore";

const BLANK_ROW_ID = "__BLANK__";

const PasteManager = ({ children, onPaste }) => {
  const wrapperRef = useRef(null);
  const lastClickRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const handlePasteEvent = (event) => {
        event.preventDefault();
        if (onPaste && event.clipboardData) {
          onPaste({
            lastClick: lastClickRef.current,
            pastedText: event.clipboardData.getData("text/plain"),
          });
        }
      };
      wrapper.addEventListener("paste", handlePasteEvent);
      return () => {
        wrapper.removeEventListener("paste", handlePasteEvent);
      };
    }
  }, [wrapperRef, lastClickRef, onPaste]);

  return (
    <Box ref={wrapperRef}>
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, {
              slotProps: {
                cell: {
                  onFocus: (event) => {
                    lastClickRef.current = {
                      rowId: event.currentTarget.parentElement.dataset.id,
                      columnId: event.currentTarget.dataset.field,
                    };
                  },
                },
              },
            })
          : child
      )}
    </Box>
  );
};

const StateSummaryGrid = ({
  gridData,
  updateOverrides,
  updateStateOverrides,
  deleteStateOverrides,
}) => {
  const apiRef = useGridApiRef();

  const { data: geographyConfig } = staticDataService.useConfigQuery(
    "geography"
  );

  const columns = [
    makeChoicesColDef({
      field: "state",
      headerName: "State",
      choices:
        geographyConfig?.usStates?.map((item) => ({
          key: item.code,
          name: item.name,
        })) ?? [],
      minWidth: 200,
      cellClassName: (params) =>
        params?.row?.isCalculated ? "not-editable" : "",
    }),
    makePrettyNumberColDef({
      field: "tiv",
      headerName: "TIV",
      minWidth: 150,
      prefix: "$",
      cellClassName: (params) =>
        params?.row?.isCalculated && params?.row?.overriddenTiv != null
          ? "overridden"
          : "",
    }),
    makePrettyNumberColDef({
      field: "zeroToOneMile",
      headerName: "0-1 Mile",
      minWidth: 150,
      prefix: "$",
    }),
    makePrettyNumberColDef({
      field: "oneToTwoMiles",
      headerName: "1-2 Miles",
      minWidth: 150,
      prefix: "$",
    }),
    makePrettyNumberColDef({
      field: "twoToFiveMiles",
      headerName: "2-5 Miles",
      minWidth: 150,
      prefix: "$",
    }),
    makePrettyNumberColDef({
      field: "fiveToTenMiles",
      headerName: "5-10 Miles",
      minWidth: 150,
      prefix: "$",
    }),
    makePrettyNumberColDef({
      field: "tenToTwentyMiles",
      headerName: "10-20 Miles",
      minWidth: 150,
      prefix: "$",
    }),
    makePrettyNumberColDef({
      field: "twentyPlusMiles",
      headerName: "20+ Miles",
      minWidth: 150,
      prefix: "$",
    }),
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) =>
        params?.id != null &&
        params?.row?.id !== BLANK_ROW_ID &&
        !params?.row?.isCalculated
          ? [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label={"Delete"}
                onClick={() => {
                  deleteStateOverrides(params?.id);
                }}
              />,
            ]
          : [],
    },
  ];

  const onPaste = ({ lastClick, pastedText }) => {
    if (lastClick?.rowId == null || lastClick?.columnId == null) {
      return;
    }

    const pastedGrid = lossCurvesSupport.parseFloatTable(pastedText);

    const rows = gridData?.map(({ id }) => id) ?? [];
    const rowStartIndex = rows.indexOf(lastClick.rowId);

    const columns = [
      "tiv",
      "zeroToOneMile",
      "oneToTwoMiles",
      "twoToFiveMiles",
      "fiveToTenMiles",
      "tenToTwentyMiles",
      "twentyPlusMiles",
    ];
    const columnStartIndex = columns.indexOf(lastClick.columnId);

    if (rowStartIndex == null || columnStartIndex == null) {
      return;
    }

    const overrides = Object.fromEntries(
      (pastedGrid ?? [])
        .map((pastedRow, pastedRowIndex) => {
          const rowIndex = rowStartIndex + pastedRowIndex;
          const state = rows[rowIndex];
          const values = Object.fromEntries(
            (pastedRow ?? [])
              .map((value, itemColumnIndex) => [
                columns[columnStartIndex + itemColumnIndex],
                value,
              ])
              .filter(([key, _]) => key != null)
          );
          return [state, values];
        })
        .filter(([key, _]) => key != null)
    );
    updateOverrides(overrides);
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .not-editable": {
          bgcolor: "#EFEFEF",
        },
        "& .overridden": {
          color: "white",
          backgroundColor: "primary.main",
          fontWeight: "bold",
        },
      }}
    >
      <PasteManager onPaste={onPaste}>
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          isCellEditable={(params) => {
            if (params?.field === "state") {
              return !params?.row?.isCalculated;
            } else {
              return true;
            }
          }}
          rows={[...gridData, { id: BLANK_ROW_ID }]}
          rowHeight={25}
          processRowUpdate={(newRow, oldRow) => {
            const updates = Object.fromEntries(
              Object.entries(newRow ?? {}).filter(
                ([key, value]) => oldRow?.[key] !== value
              )
            );
            updateStateOverrides(newRow.state)(updates);
            return newRow;
          }}
          onProcessRowUpdateError={(error) => logger.error(error)}
          onCellEditStart={(params, event) => {
            if (params?.reason === "deleteKeyDown") {
              updateStateOverrides(params?.id)({
                [params?.field]: null,
              });
              event.defaultMuiPrevented = true;
              return;
            } else if (params?.reason === "pasteKeyDown") {
              event.defaultMuiPrevented = true;
              return;
            }
          }}
          hideFooter
          disableColumnMenu
          autoHeight
        />
      </PasteManager>
    </Box>
  );
};

const StateSummary = () => {
  const dispatch = useDispatch();
  const modelData = useSelector(modelStore.selectModelData);
  const overrides = modelData?.stateSummaryOverrides ?? {};

  const stateTiv =
    useSelector(analyticsStore.select)?.programPricing?.values?.geoSummary
      ?.stateTiv ?? {};

  const updateOverrides = (values) => {
    // Move to a separate render to make sure that the table updates correctly.
    setTimeout(
      () =>
        dispatch(
          modelStore.update({
            ...modelData,
            stateSummaryOverrides: {
              ...overrides,
              ...Object.fromEntries(
                Object.entries(values ?? {}).map(([id, stateValues]) => [
                  id,
                  {
                    ...overrides?.[id],
                    ...stateValues,
                  },
                ])
              ),
            },
          })
        ),
      0
    );
  };

  const updateStateOverrides = (state) => (values) =>
    updateOverrides({
      [state]: values,
    });

  const deleteStateOverrides = (state) => {
    dispatch(
      modelStore.update({
        ...modelData,
        stateSummaryOverrides: Object.fromEntries(
          Object.entries(overrides ?? {}).filter(
            ([thisState, _]) => thisState !== state
          )
        ),
      })
    );
  };

  const gridData = support.buildGridData({
    stateTiv,
    overrides,
  });

  return (
    <Component title={"State Summary"}>
      <Stack direction={"column"} spacing={2}>
        <StateSummaryGrid
          gridData={gridData}
          updateOverrides={updateOverrides}
          updateStateOverrides={updateStateOverrides}
          deleteStateOverrides={deleteStateOverrides}
        />
      </Stack>
    </Component>
  );
};

export default StateSummary;
