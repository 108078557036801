import { Autocomplete, Grid, TextField } from "@mui/material";
import Component from "components/Component";
import * as config from "config";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programPricingStore from "store/programPricing";

const EnumSelector = ({ label, options, value, onChange, loading }) => (
  <Grid item xs={6} lg={2}>
    <Autocomplete
      value={
        loading
          ? { key: value, name: "Loading ..." }
          : value != null
          ? options?.filter((option) => option?.key === value)?.[0] ?? {
              key: value,
              name: `Unknown (${value})`,
            }
          : null
      }
      getOptionLabel={(option) => option?.name}
      onChange={(_, value) => onChange(value)}
      options={loading ? [] : options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={"filled"}
          InputLabelProps={{ shrink: true }}
        />
      )}
      autoHighlight
    />
  </Grid>
);

const Perils = () => {
  const dispatch = useDispatch();
  const programPricing = useSelector(programPricingStore.select);
  const {
    data: catExposuresConfig,
    isLoading: isCatExposuresConfigLoading,
  } = staticDataService.useConfigQuery("cat_exposures");

  return (
    <Component title={"Perils"}>
      <Grid container spacing={config.GRID_SPACING}>
        <EnumSelector
          label={"Risk Code"}
          options={catExposuresConfig?.enums?.riskCode?.choices ?? []}
          value={programPricing?.riskCode}
          onChange={(option) =>
            dispatch(
              programPricingStore.update({
                riskCode: option?.key ?? null,
              })
            )
          }
          loading={isCatExposuresConfigLoading}
        />
        <EnumSelector
          label={"Storm Surge"}
          options={catExposuresConfig?.enums?.stormSurgeCoverage?.choices ?? []}
          value={programPricing?.stormSurgeCoverage}
          onChange={(option) =>
            dispatch(
              programPricingStore.update({
                stormSurgeCoverage: option?.key ?? null,
              })
            )
          }
          loading={isCatExposuresConfigLoading}
        />
        <EnumSelector
          label={"Tsunami"}
          options={catExposuresConfig?.enums?.tsunamiCoverage?.choices ?? []}
          value={programPricing?.tsunamiCoverage}
          onChange={(option) =>
            dispatch(
              programPricingStore.update({
                tsunamiCoverage: option?.key ?? null,
              })
            )
          }
          loading={isCatExposuresConfigLoading}
        />
        <EnumSelector
          label={"Max Exposure Region"}
          options={catExposuresConfig?.enums?.maxExposureRegion?.choices ?? []}
          value={programPricing?.maxExposureRegion}
          onChange={(option) =>
            dispatch(
              programPricingStore.update({
                maxExposureRegion: option?.key ?? null,
              })
            )
          }
          loading={isCatExposuresConfigLoading}
        />
        <EnumSelector
          label={"Regional"}
          options={catExposuresConfig?.enums?.regionalCoverage?.choices ?? []}
          value={programPricing?.regionalCoverage}
          onChange={(option) =>
            dispatch(
              programPricingStore.update({
                regionalCoverage: option?.key ?? null,
              })
            )
          }
          loading={isCatExposuresConfigLoading}
        />
      </Grid>
    </Component>
  );
};

export default Perils;
