import CoveragesGrid from "./CoveragesGrid";
import Component from "components/Component";
import { useSelector } from "react-redux";
import * as programPricingStore from "store/programPricing";

const NonCatCoverages = () => {
  const programPricing = useSelector(programPricingStore.select);

  return (
    <Component title={"Non-Cat Coverages"}>
      <CoveragesGrid
        perils={programPricing?.coverageConfig?.nonCatCoverageGrid}
      />
    </Component>
  );
};

export default NonCatCoverages;
