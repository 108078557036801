import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const getLabel = (flag, nullString = "-") => {
  if (!flag) {
    return nullString;
  } else if (flag.categoryType === "select") {
    const option = (flag.categoryOptions ?? []).filter(
      (x) => x.key === flag.category
    );
    return option.length > 0 ? option[0].display : nullString;
  } else {
    return flag.category ?? nullString;
  }
};

const FlagSelector = ({ flag, updateCategory }) => {
  const editable = typeof updateCategory === "function";
  const isBoolean = flag?.categoryType === "boolean" || flag?.isBooleanFlag;
  const handleUpdate = (value) => {
    if (editable) updateCategory(value);
  };

  if (!editable) {
    return isBoolean ? (
      <Checkbox
        sx={{ padding: 0 }}
        checked={flag?.category || false}
        disabled
      />
    ) : (
      <Typography>{getLabel(flag)}</Typography>
    );
  }

  if (isBoolean) {
    return (
      <Checkbox
        sx={{ padding: 0 }}
        checked={flag?.category || false}
        onChange={(e) => handleUpdate(e.target.checked)}
      />
    );
  }

  if (flag?.categoryType === "select" && flag?.categoryOptions?.length > 0) {
    return (
      <Select
        variant={"outlined"}
        size={"small"}
        value={flag?.category ?? null}
        onChange={(e) => handleUpdate(e.target.value)}
        style={{ width: "100%" }}
      >
        {flag?.nullCategoryOption && (
          <MenuItem value={null}>{flag?.nullCategoryOption}</MenuItem>
        )}
        {flag?.categoryOptions.map(({ key, display }) => (
          <MenuItem key={key} value={key}>
            {display}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <TextField
      value={flag?.category}
      onChange={(e) => handleUpdate(e.target.value)}
      size={"small"}
      variant={"filled"}
      autoComplete={"off"}
      hiddenLabel
      fullWidth
    />
  );
};

export default FlagSelector;
